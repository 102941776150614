import React, { useState, useEffect } from "react";
import { TimePicker } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { getValue } from "@utils/lodash";

const SheetTimePicker = React.memo(
  ({ handleInputChange, col, row, field }: any) => {
    // Convert col.time to a Dayjs object if it exists, otherwise null
    const [selectedTime, setSelectedTime] = useState<Dayjs | null>(
      getValue(row, `[${col}]`, "")
        ? dayjs(getValue(row, `[${col}]`, ""))
        : null
    );

    // Update selectedTime if col.time changes
    useEffect(() => {
      setSelectedTime(
        getValue(row, `[${col}]`, "")
          ? dayjs(getValue(row, `[${col}]`, ""))
          : null
      );
    }, [getValue(row, `[${col}]`, "")]);

    const onChange = (time: Dayjs | null) => {
      if (time) {
        setSelectedTime(time);
        handleInputChange(row.id, col, time, field);
      } else {
        setSelectedTime(null);
        handleInputChange(row.id, col, null, field);
      }
    };

    return (
      <div>
        <TimePicker
          onChange={onChange}
          minuteStep={30}
          format="hh:mm A"
          placeholder="Select Time"
          value={selectedTime}
        />
      </div>
    );
  }
);

export default SheetTimePicker;
