import HomeHeader from "@components/common/Header/HomeHeader/Header";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CompanyProfileSubHeader from "../../CompanyProfile/components/CompanyProfileSubHeader";
import CompanyProfileNavigation from "@layouts/SettingsLayout/CompanyProfileNavigation";
import { QueryRequestHelper } from "@common/query-request-helper";
import {
  getSpecificAdManager,
  subscribeFbAdManager,
} from "@services/admanager.service";
import { getValue } from "@utils/lodash";
import FacebookPageList from "./pages";
import { toast } from "sonner";
import Navbar from "../../common/navbar";
import BackButton from "@components/custom/BrowserBack";
import MainLayout from "@layouts/HomeLayout/NewLayout";

function AdsInfo(props: any) {
  const params = useParams();
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const views = [
    {
      id: "1",
      name: "Account Info",
    },
  ];

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getData();
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState([]);
  const getData = async () => {
    try {
      setIsLoading(true);
      let resp = await getSpecificAdManager(getValue(params, `id`, ""));
      if (resp) {
        setInfo(getValue(resp, `data`, []));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleSubscribePage = async (obj: any) => {
    try {
      let resp = await subscribeFbAdManager({
        page_id: getValue(obj, `page_id`, ""),
        access_token: getValue(obj, `access_token`, ""),
        ad_account_id: getValue(info, `id`, ""),
        name: getValue(obj, `name`, ""),
        category: getValue(obj, `category`, ""),
      });
      if (resp) {
        toast.success("Subscribed successfully");
        getData();
      }
    } catch (error) {}
  };

  return (
    <MainLayout {...props}>
      <CompanyProfileSubHeader />
      <div className="d-flex">
        <CompanyProfileNavigation />
        <div className="w-100">
          <div className="d-flex gap-1 align-items-center mt-3">
            <BackButton className="ms-4 " />
            <Navbar
              views={views}
              route={`/settings/crm/ads/${getValue(params, `id`, "")}`}
            />
          </div>
          <FacebookPageList
            isLoading={isLoading}
            list={getValue(info, `pages`, [])}
            handleSubscribePage={handleSubscribePage}
          />
        </div>
      </div>
    </MainLayout>
  );
}

export default AdsInfo;
