import { QueryRequestHelper } from "@common/query-request-helper";
import { getLookupAPIs } from "@components/helpers/lookup-apis";
import { getValue } from "@utils/lodash";
import { Select } from "antd";
import React, { useEffect, useState } from "react";

const SheetLookupDropdown = React.memo(
  ({
    col,
    row,
    field,
    fieldCol,
    handleDisplayItem,
    handleInputChange,
  }: any) => {
    const [dataInfo, setData] = useState([]);
    const [isLoadingInfo, setIsLoading] = useState(true);

    const lookup_api = getValue(fieldCol, `lookup.api_name`, "");

    useEffect(() => {
      if (lookup_api) {
        fetchDefaultData();
      }
    }, [lookup_api]);

    const fetchDefaultData = async () => {
      try {
        let payload = {
          page_no: 1,
          page_size: 10,
        };
        setIsLoading(true);
        let queryRequest = QueryRequestHelper(payload);
        let resp = await getLookupAPIs(lookup_api, queryRequest);
        if (resp) {
          const formattedData = resp.map((item: any) => ({
            label: item.label,
            value: item.id,
          }));
          setData(formattedData);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    };

    //   console.log("col", row);

    return (
      <Select
        showSearch
        value={handleDisplayItem(col, row) || undefined}
        placeholder={`Select ${col}`}
        options={dataInfo}
        onChange={(selectedValue) =>
          handleInputChange(getValue(row, "id", ""), col, selectedValue, field)
        }
        style={{
          minWidth: "160px",
        }}
        loading={isLoadingInfo}
      />
    );
  }
);

export default SheetLookupDropdown;
