import { Drawer, Tag } from "antd";
import React from "react";
import { DashboardKPITypeList } from "../helpers/enum";
import { getValue } from "@utils/lodash";


function ComponentKPIStyleDrawer(props: any) {
  return (
    <Drawer
      title="Choose KPI Style"
      onClose={props.onClose}
      open={props.open}
      width={1000}
      className="dashboard-slider-container"
    >
      <div className="dashboard-style-selector">
        {DashboardKPITypeList.map((item: object, index: number) => {
          return (
            <div className="height-100" key={index}>
              <div
                className="dashboard_charttype p-2"
                onClick={() =>
                  props.openComponentDrawer(getValue(item, `value`, ""))
                }
              >
                <img
                  src={getValue(item, "image", "")}
                  alt={getValue(item, `label`, "")}
                  className="w-100"
                />
              </div>
              <p className="small_text__16 text-center pt-2">
                {getValue(item, `label`, "")}
              </p>
            </div>
          );
        })}
      </div>
    </Drawer>
  );
}

export default ComponentKPIStyleDrawer;
