import BackSvgComponent from "@assets/svg/back-link";
import ButtonComponent from "@components/Form/Button/Button";
import InputRuleForm from "@components/InputRuleForm/form";
import HomeHeader from "@components/common/Header/HomeHeader/Header";
import { getValue, setValue } from "@utils/lodash";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { QueryRequestHelper } from "@common/query-request-helper";
import {
  getAllRoomType,
  getSpecificRoomType,
} from "@services/properties/room-type.service";
import { getTimeSlotSettings } from "@services/properties/settings/time-slot.service";
import { getAllRatePlan } from "@services/properties/settings/rate-plan.service";
import {
  createPropertyBooking,
  getPropertyBookingprice,
} from "@services/properties/property-booking.service";
import moment from "moment";
import { convertDateTime } from "@common/date-helpers";
import {
  handleSelectArrayChange,
  handleSelectChange,
} from "@common/handleChange";
import { mediaUpload } from "@services/upload.service";
import { config } from "../../../../../../env";
import { toast } from "sonner";
import BookingInfoForm from "./forms/booking-info-form";
import CheckinOutForm from "./forms/check-in-out-form";
import CompanyInfoForm from "./forms/company-info-form";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import { allParams } from "@common/url-params-helper";
import CounterButtons from "./components/count-buttons";
import CarouselNavViews from "@pages/Private/Broadcast/components/template-messages/components/carousel-nav-views";
import { cleanObject } from "@common/object-helper";
import { Switch, Tabs } from "antd";
const { TabPane } = Tabs;

const CreateWalkIN = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [activeCarouselIndex, setActiveCarouselIndex] = useState(0);
  const [activeChildCarouselIndex, setActiveChildCarouselIndex] = useState(0);
  const [activeRoomIndex, setActiveRoomIndex] = useState(0);

  const bookinginfo_obj = {
    full_name: "",
    mobile: "",
    email: "",
    pincode: 0,
    address: "",
    city: "",
    state: "",
    country: "",
    identity_type: "passport",
    identity_value: "",
    identity_upload_type: "device_upload",
    identity_front_img: "",
    identity_back_img: "",
    is_primary: true,
    dob: "",
    gender: "male",
    nationality: "",
    occupation: "",
    emergency_contact: "",
    emergency_contact_name: "",
    type: "adult",
    relationship: "self",
  };

  const initialState: any = {
    checkin: "",
    checkout: "",
    room_data: [
      {
        adults: 1,
        children: 0,
        room_type_id: 0,
        room_id: 0,
        rate_plan_id: "",
        user_booking_info: [bookinginfo_obj],
        child_user_booking_info: [],
        is_same_as_primary_user: true,
        payment_method: "cash",
        booking_amount: 0,
        payment_remarks: "",
      },
    ],
    primary_user_info: [bookinginfo_obj],
    company_booking_info: {
      company_name: "",
      company_phone: "",
      company_email: "",
      gstin_no: "",
      address: "",
    },
    segment: "direct",
    sub_segment: "walkin",
    is_enquiry: false,
    property_id: "",
    // is_group_booking: false,
  };

  const [ratePlanRequest, setRatePlanRequest] = useState({
    rate_plan_price: "",
    sub_total: "",
    total: "",
    total_after_tax: "",
    total_before_tax: "",
    total_tax: "",
    taxes: "",
  });
  // console.log("rate", ratePlanRequest);

  /* -------------------------------------------------------------------------- */
  /*                               Hooks Section                                */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getAllRoomList();
    getSlotList();
    getRatePlanList();
    const newDate = moment(getValue(params, `date`, "")).add(1, "day").toDate();

    setRequest((object: any) => {
      return {
        ...object,
        property_id: getValue(params, `id`, ""),
        checkin_date: new Date(getValue(params, `date`, "")),
        checkout_date: newDate,
        ["room_data"]: object["room_data"].map((x: any, index: number) => {
          return {
            ...x,
          };
        }),
      };
    });
  }, []);
  const [request, setRequest] = useState<any>(initialState);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (
      (getValue(request, `room_data[0].children`, 0) ||
        getValue(request, "checkout_date", "") ||
        getValue(request, "checkout_time", "")) &&
      getValue(params, `typeId`, "") &&
      getValue(params, `id`, "") &&
      getValue(request, "checkin_date", "") &&
      getValue(request, "checkin_time", "") &&
      getValue(request, `room_data[0].adults`, 0) &&
      getValue(request, `room_data[0].rate_plan_id`, 0)
    ) {
      getPropertyPriceInfo();
    }
    getData();
  }, [
    getValue(params, `typeId`, ""),
    getValue(params, `id`, ""),
    getValue(request, "checkin_date", ""),
    getValue(request, "checkout_date", ""),
    getValue(request, "checkin_time", ""),
    getValue(request, "checkout_time", ""),
    getValue(request, `room_data[${activeRoomIndex}].adults`, 0),
    getValue(request, `room_data[${activeRoomIndex}].children`, 0),
    getValue(request, `room_data[${activeRoomIndex}].rate_plan_id`, 0),
  ]);
  const getPropertyPriceInfo = async () => {
    let payload = {
      checkin: convertDateString(
        getValue(request, "checkin_date", ""),
        getValue(request, "checkin_time", "")
      ),
      checkout: convertDateString(
        getValue(request, "checkout_date", ""),
        getValue(request, "checkout_time", "")
      ),
      room_type_id: parseInt(getValue(params, `typeId`, "")),
      rate_plan_id: getValue(
        request,
        `room_data[${activeRoomIndex}].rate_plan_id`,
        0
      ),
      property_id: getValue(params, `id`, ""),
      num_of_adult: parseInt(
        getValue(request, `room_data[${activeRoomIndex}].adults`, 0)
      ),
      num_of_children: parseInt(
        getValue(request, `room_data[${activeRoomIndex}].children`, 0)
      ),
    };
    try {
      let resp = await getPropertyBookingprice(payload);
      if (resp) {
        setRatePlanRequest((prevState: any) => ({
          ...prevState,
          rate_plan_price: getValue(resp, `data.rate_plan_price`, ""),
          sub_total: getValue(resp, `data.sub_total`, ""),
          total: getValue(resp, `data.total`, ""),
          total_after_tax: getValue(resp, `data.total_after_tax`, []),
          total_before_tax: getValue(resp, `data.total_before_tax`, ""),
          total_tax: getValue(resp, `data.total_tax`, ""),
          taxes: getValue(resp, `data.taxes`, []),
        }));
      }
    } catch (error) {}
  };

  const [specificRoomDetails, setSpecificRoomDetails] = useState([]);
  const [subRooms, setSubRooms] = useState<any>([]);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const getData = async () => {
    try {
      setDetailsLoading(true);
      let resp = await getSpecificRoomType(getValue(params, `typeId`, ""));
      if (resp) {
        setDetailsLoading(false);
        setSpecificRoomDetails(getValue(resp, `data`, []));
        setSubRooms(
          getValue(resp, `data.room`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `id`, ""),
            label: `Room - ${getValue(item, `room_id`, "")}`,
          }))
        );
      } else {
        setDetailsLoading(false);
      }
    } catch (error) {
      setDetailsLoading(false);
      console.error(error);
    }
  };

  const convertDateString = (date: any, time: any) => {
    const dateStr = date;
    const timeStr = time;
    // Parse the date string into a moment object
    const dateMoment = moment(dateStr);
    // Parse the time string and set it to the date object
    const timeMoment = moment(timeStr, "h a");
    // Combine the date and time
    const combinedMoment = dateMoment.set({
      hour: timeMoment.hour(),
      minute: timeMoment.minute(),
      second: timeMoment.second(),
    });
    return convertDateTime(combinedMoment);
  };

  /* -----------------------------  Rooms Section ---------------------------- */

  const [isLoading, setIsLoading] = useState(false);
  const [rooms, setRooms] = useState([]);
  const getAllRoomList = async () => {
    try {
      setIsLoading(true);
      let payload = {
        property_id: getValue(params, `id`, ""),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllRoomType(queryRequest);
      if (resp) {
        setIsLoading(false);
        setRooms(
          getValue(resp, `data.properties`, []).map((item: object) => ({
            ...item,
            label: getValue(item, `name`, ""),
            value: getValue(item, `id`, ""),
          }))
        );
        // if (!getValue(params, "roomName", "")) {
        //   if (getValue(resp, "data.properties[0].room[0].id", "")) {
        //     navigate(
        //       `/${getValue(params, `orgId`, "")}/property/details/${getValue(
        //         params,
        //         `id`,
        //         ""
        //       )}/create/walk-in/${getValue(params, `typeId`, "")}/${getValue(
        //         params,
        //         `roomId`,
        //         ""
        //       )}/${getValue(params, `date`, "")}/${getValue(
        //         resp,
        //         "data.properties[0].room[0].id",
        //         ""
        //       )}`
        //     );
        //   }
        // }
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* -----------------------------  Slot Section ---------------------------- */
  const [isLoading1, setIsLoading1] = useState(false);
  const [timeSlotsInfo, setTimeSlotsInfo] = useState([]);
  const [checkinSlots, setCheckinSlots] = useState<any>([]);
  const [checkoutSlots, setCheckoutSlots] = useState<any>([]);
  const getSlotList = async () => {
    try {
      setIsLoading1(true);
      let payload = {
        property_id: getValue(params, `id`, ""),
        slot_type: "24_hrs",
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getTimeSlotSettings(queryRequest);
      if (resp) {
        setTimeSlotsInfo(getValue(resp, `data.timeSlots`, []));
        setCheckinSlots(
          getValue(resp, `data.timeSlots`, []).map((item: object) => ({
            value: getValue(item, `checkin`, ""),
            label: getValue(item, `checkin`, ""),
            name: getValue(item, `checkin`, ""),
          }))
        );
        setCheckoutSlots(
          getValue(resp, `data.timeSlots`, []).map((item: object) => ({
            value: getValue(item, `checkout`, ""),
            name: getValue(item, `checkout`, ""),
            label: getValue(item, `checkout`, ""),
          }))
        );

        setRequest((prevState: any) => ({
          ...prevState,
          checkin_time: getValue(resp, `data.timeSlots[${0}].checkin`, ""),
          checkout_time: getValue(resp, `data.timeSlots[${0}].checkout`, ""),
        }));
        setIsLoading1(false);
      } else {
        setIsLoading1(false);
      }
    } catch (error) {
      setIsLoading1(false);
    }
  };

  /* -----------------------------  Rate Plan Section ------------------------- */

  const [isLoading2, setIsLoading2] = useState(false);
  const [rateplanList, setRatePlanList] = useState([]);
  const getRatePlanList = async () => {
    try {
      setIsLoading2(true);
      let payload = {
        property_id: getValue(params, `id`, ""),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllRatePlan(queryRequest);
      if (resp) {
        setRatePlanList(
          getValue(resp, `data.ratePlans`, []).map((item: object) => ({
            ...item,
            label: getValue(item, `rate_name`, ""),
            value: getValue(item, `id`, ""),
          }))
        );
        setRequest((prevState: any) => ({
          ...prevState,
          room_data: prevState.room_data.map((room: any, index: any) =>
            index === 0
              ? {
                  ...room,
                  rate_plan_id: getValue(resp, `data.ratePlans[${0}].id`, ""),
                }
              : room
          ),
        }));

        setIsLoading2(false);
      } else {
        setIsLoading2(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    try {
      setSubmitLoading(true);
      const {
        room_data = [],
        company_booking_info = {},
        checkin_date,
        checkin_time,
        checkout_date,
        checkout_time,
        ...rest
      } = request;

      const roomData = room_data[activeRoomIndex] || {};
      const combinedUserBookingInfo = [
        ...(roomData.user_booking_info || []),
        ...(roomData.child_user_booking_info || []),
      ];
      const updatedPayload = {
        ...rest,
        checkin: convertDateString(checkin_date, checkin_time),
        checkout: convertDateString(checkout_date, checkout_time),
        is_group_booking: false,
        primary_user_info: combinedUserBookingInfo.find((item) =>
          getValue(item, `is_primary`, "")
        ),
        is_enquiry: getValue(request, "is_enquiry", false),
        company_booking_info: {
          company_name: company_booking_info.company_name,
          company_phone: company_booking_info.company_phone,
          company_email: company_booking_info.company_email,
          gstin_no: company_booking_info.gstin_no,
          address: company_booking_info.address,
        },
        room_data: [
          {
            adults: roomData.adults,
            children: 0,
            room_id: parseInt(getValue(params, `roomName`, "")),
            room_type_id: parseInt(getValue(params, `typeId`, "")),
            rate_plan_id: getValue(roomData, "rate_plan_id", ""),
            payment_method: getValue(request, "payment_method", ""),
            is_same_as_primary_user: roomData.is_same_as_primary_user,
            user_booking_info: combinedUserBookingInfo.filter(
              (item) => !getValue(item, `is_primary`, false)
            ),
          },
        ],
      };
      const cleanedPayload = cleanObject(updatedPayload);

      const resp = await createPropertyBooking(cleanedPayload);
      if (resp) {
        toast.success("Booking created successfully");
        navigate(
          `/${getValue(params, "orgId", "")}/property/details/${getValue(
            params,
            "id",
            ""
          )}/reservations`
        );
      }
    } catch (error) {
      // Handle any error that occurs during submission
      console.error("Error creating booking:", error);
    } finally {
      // Stop the loading spinner
      setSubmitLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const [openDayLevelAmount, setOpenDayLevelAmount] = useState(false);
  const toggleDayLevelAmount = () => {
    setOpenDayLevelAmount(!openDayLevelAmount);
  };

  const [uploadLoading, setUploadLoading] = useState<{
    [key: string]: boolean;
  }>({
    identity_front_img: false,
    identity_back_img: false,
  });

  const handleUploadDocuments = async (
    index: number,
    childIndex: number,
    e: React.ChangeEvent<HTMLInputElement>,
    key: string,
    name: string,
    subName: string,
    setObject: any
  ) => {
    if (!e.target.files) return;

    const file = e.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("file", file);
    formData.append("filename", file.name);
    formData.append("is_public", "true");
    formData.append("collection", "public-images");

    try {
      setUploadLoading((prev) => ({
        ...prev,
        [`${name}-${subName}`]: true,
      }));

      const resp = await mediaUpload(formData);

      if (resp) {
        const imageUrl = `https://${config.ASSETS_CDN_ENDPOINT}/${getValue(
          resp,
          `data.key`,
          ""
        )}`;

        setObject((object: any) => {
          return {
            ...object,
            [key]: object[key].map((x: any, i: number) => {
              if (i !== index) return x;
              return {
                ...x,
                [name]: x[name].map((y: any, cIndex: number) => {
                  if (cIndex !== childIndex) return y;
                  return {
                    ...y,
                    [subName]: imageUrl,
                  };
                }),
              };
            }),
          };
        });
      }
    } catch (error) {
      console.error("Upload failed:", error);
    } finally {
      setUploadLoading((prev) => ({
        ...prev,
        [`${name}-${subName}`]: false,
      }));
    }
  };

  const maxAdult = getValue(specificRoomDetails, "max_adult", "0");
  const maxChildren = getValue(specificRoomDetails, "max_children", "0");

  const handleAddBookingInfo = (type: string, cat: string) => {
    if (type === "adult") {
      if (cat === "add") {
        if (
          getValue(
            request,
            `room_data[${activeRoomIndex}].user_booking_info.length`,
            0
          ) < maxAdult
        ) {
          getValue(
            request,
            `room_data[${activeRoomIndex}].user_booking_info`,
            []
          ).push({
            ...bookinginfo_obj,
            is_primary: false,
          });
          setValue(
            request,
            `room_data[${activeRoomIndex}].adults`,
            getValue(
              request,
              `room_data[${activeRoomIndex}].user_booking_info.length`,
              0
            )
          );
          setRequest({ ...request });
        } else {
          toast.error(`Maximum ${maxAdult} adults allowed`);
        }
      } else {
        if (
          getValue(
            request,
            `room_data[${activeRoomIndex}].user_booking_info.length`,
            0
          ) > 1
        ) {
          let filtered = getValue(
            request,
            `room_data[${activeRoomIndex}].user_booking_info`,
            []
          ).slice(0, -1);
          setValue(
            request,
            `room_data[${activeRoomIndex}].adults`,
            getValue(filtered, `length`, 0)
          );
          setRequest((object: any) => {
            return {
              ...object,
              ["room_data"]: object["room_data"].map(
                (x: any, index: number) => {
                  return {
                    ...x,
                    user_booking_info: filtered,
                  };
                }
              ),
            };
          });
        } else {
          toast.error("At least 1 adult is required");
        }
      }
    } else if (type === "children") {
      if (cat === "add") {
        if (
          getValue(
            request,
            `room_data[${activeRoomIndex}].child_user_booking_info.length`,
            0
          ) < maxChildren
        ) {
          getValue(
            request,
            `room_data[${activeRoomIndex}].child_user_booking_info`,
            []
          ).push({
            ...bookinginfo_obj,
            is_primary: false,
          });
          setValue(
            request,
            `room_data[${activeRoomIndex}.children`,
            getValue(
              request,
              `room_data[${activeRoomIndex}].child_user_booking_info.length`,
              0
            )
          );
          setRequest({ ...request });
        } else {
          toast.error(`Maximum ${maxAdult} child allowed`);
        }
      } else {
        if (
          getValue(
            request,
            `room_data[${activeRoomIndex}].child_user_booking_info.length`,
            0
          ) > 0
        ) {
          let filtered = getValue(
            request,
            `room_data[${activeRoomIndex}].child_user_booking_info`,
            []
          ).slice(0, -1);
          setValue(
            request,
            `room_data[${activeRoomIndex}.children`,
            filtered.length
          );
          setRequest((object: any) => {
            return {
              ...object,
              ["room_data"]: object["room_data"].map(
                (x: any, index: number) => {
                  return {
                    ...x,
                    child_user_booking_info: filtered,
                  };
                }
              ),
            };
          });
        }
      }
    }
  };

  const handleTypeIdChange = (selectedRoom: any) => {
    navigate(
      `/${getValue(params, `orgId`, "")}/property/details/${getValue(
        params,
        `id`,
        ""
      )}/create/walk-in/${getValue(selectedRoom, "id", "")}/${getValue(
        params,
        `id`,
        ""
      )}/${getValue(params, `date`, "")}/${getValue(subRooms, "[0].id", "")}`
    );
  };

  const handleSubRoomIdChange = (selectedSubRoom: any) => {
    navigate(
      `/${getValue(params, `orgId`, "")}/property/details/${getValue(
        params,
        `id`,
        ""
      )}/create/walk-in/${getValue(params, "typeId", "")}/${getValue(
        params,
        `id`,
        ""
      )}/${getValue(params, `date`, "")}/${getValue(
        selectedSubRoom,
        `value`,
        ""
      )}`
    );
  };

  const handleCarouselTab = (tab: number) => {
    setActiveCarouselIndex(tab);
  };

  const handleChildCarouselTab = (tab: number) => {
    setActiveChildCarouselIndex(tab);
  };

  const views = [
    { id: "1", name: "Adult details" },
    { id: "2", name: "Child details" },
    { id: "3", name: "Company Booking Info" },
  ];

  const handleChangeTab = (key: string) => {
    // let payload = {
    //   ...UrlParams,
    //   page_no: 1,
    //   activeTab: key,
    // };
    // let queryRequest = QueryRequestHelper(payload);
    // navigate(`${window.location.pathname}?${queryRequest}`);
    setActiveTab(key);
  };

  const [activeTab, setActiveTab] = useState("1");

  return (
    <div>
      <HomeHeader />
      <div className={"company-profile-subheader-wrapper"}>
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="d-flex align-items-center">
            <div
              onClick={() =>
                navigate(
                  `/${getValue(
                    params,
                    "orgId",
                    ""
                  )}/property/details/${getValue(
                    params,
                    "id",
                    ""
                  )}/reservations?${allParams()}`
                )
              }
            >
              <BackSvgComponent />
            </div>
            <div className="small_text__16 ms-3 ">
              Create walk in
              {/* ( Room - {getValue(params, `roomName`, "")} ) */}
            </div>
          </div>
          <ButtonComponent
            buttonType="primary"
            buttonText={submitLoading ? "Please wait..." : "Create Reservation"}
            disabled={submitLoading}
            onClickHandler={() => handleSubmit()}
          />
        </div>
      </div>
      <div className="manageFieldRight w-100 p-5 overflow-y-auto">
        <div className="add-box-shadow">
          <h3 className="header_text__18">Check in - check out</h3>
          <CheckinOutForm
            request={request}
            setRequest={setRequest}
            checkinSlots={checkinSlots}
            checkoutSlots={checkoutSlots}
          />
        </div>
        <div className="add-box-shadow">
          <div className="d-flex justify-content-between">
            <h3 className="header_text__18">Room info</h3>

            <div className="d-flex gap-2 align-items-center">
              <p className="small_text__14">Enquiry</p>
              <Switch
                onChange={(checked) => {
                  setRequest({ ...request, is_enquiry: checked });
                }}
              />
            </div>
          </div>

          <div className=" mt-1 mb-2 d-flex gap-2">
            <SearchToolTip
              label="label"
              data={rooms}
              selectKey={"value"}
              value={getValue(params, `typeId`, "")}
              onChange={handleTypeIdChange}
              name="Room type"
            />
            <SearchToolTip
              label="label"
              data={subRooms}
              selectKey={"value"}
              value={getValue(params, `roomName`, "")}
              onChange={handleSubRoomIdChange}
              name="Room id"
            />
          </div>
          <div className="d-flex align-items-center">
            <CounterButtons
              label="Adults"
              value={getValue(
                request,
                `room_data[${activeRoomIndex}].adults`,
                ""
              )}
              maxValue={getValue(specificRoomDetails, "max_adult", "")}
              onAdd={() => handleAddBookingInfo("adult", "add")}
              onRemove={() => handleAddBookingInfo("adult", "remove")}
              disableRemove={
                getValue(
                  request,
                  `room_data[${activeRoomIndex}].adults`,
                  ""
                ) === 1
              }
              disableAdd={
                getValue(
                  request,
                  `room_data[${activeRoomIndex}].adults`,
                  ""
                ) === maxAdult
              }
            />
            <CounterButtons
              label="Children"
              value={getValue(
                request,
                `room_data[${activeRoomIndex}].children`,
                ""
              )}
              maxValue={getValue(specificRoomDetails, "max_children", "")}
              onAdd={() => handleAddBookingInfo("children", "add")}
              onRemove={() => handleAddBookingInfo("children", "remove")}
              disableRemove={
                getValue(
                  request,
                  `room_data[${activeRoomIndex}].children`,
                  ""
                ) === 0
              }
              disableAdd={
                getValue(
                  request,
                  `room_data[${activeRoomIndex}].children`,
                  ""
                ) === maxChildren
              }
            />
          </div>
        </div>

        <div className="grid-containe d-flex gap-3 w-100">
          <div className=" w-70">
            {/* <Nav tabs>
              {views.map((item, index) => (
                <NavItem key={index}>
                  <NavLink
                    className={`d-flex justify-content-between align-items-center ${
                      activeTab == item.id ? "active" : ""
                    }`}
                    onClick={() => setActiveTab(item.id)}
                  >
                    {item.name}
                  </NavLink>
                </NavItem>
              ))}
            </Nav> */}

            <Tabs activeKey={activeTab} onChange={handleChangeTab} type="card">
              {views.map((item) => (
                <TabPane tab={item.name} key={item.id}>
                  {item.id === "1" && (
                    <>
                      <div className="mt-3 ">
                        <CarouselNavViews
                          length={getValue(
                            request,
                            `room_data[${activeRoomIndex}].adults`,
                            ""
                          )}
                          activeTab={activeCarouselIndex}
                          handleTab={handleCarouselTab}
                          showName
                          addHeading="Guest"
                        />
                      </div>
                      <div className="add-box-shadow ">
                        <BookingInfoForm
                          index={activeRoomIndex}
                          childIndex={activeCarouselIndex}
                          item={getValue(
                            request,
                            `room_data[${activeRoomIndex}].user_booking_info[${activeCarouselIndex}]`,
                            {}
                          )}
                          name="user_booking_info"
                          key_name="room_data"
                          setRequest={setRequest}
                          handleUploadDocuments={handleUploadDocuments}
                          uploadLoading={uploadLoading}
                        />
                      </div>
                    </>
                  )}
                  {item.id === "2" && (
                    <>
                      {getValue(
                        request,
                        `room_data[${activeRoomIndex}].children`,
                        ""
                      ) <= 0 ? (
                        <p className="small_text__14 my-3 text-center py-4">
                          Please add a child before providing additional details
                        </p>
                      ) : (
                        <>
                          <div className="mt-3 ">
                            <CarouselNavViews
                              length={getValue(
                                request,
                                `room_data[${activeRoomIndex}].children`,
                                0
                              )}
                              activeTab={activeChildCarouselIndex}
                              handleTab={handleChildCarouselTab}
                              showName
                              addHeading="Child"
                            />
                          </div>
                          <div className="add-box-shadow ">
                            <BookingInfoForm
                              index={activeRoomIndex}
                              childIndex={activeChildCarouselIndex}
                              item={getValue(
                                request,
                                `room_data[${activeRoomIndex}].child_user_booking_info[${activeChildCarouselIndex}]`,
                                {}
                              )}
                              name="child_user_booking_info"
                              key_name="room_data"
                              setRequest={setRequest}
                              handleUploadDocuments={handleUploadDocuments}
                              uploadLoading={uploadLoading}
                            />
                          </div>
                        </>
                      )}
                    </>
                  )}
                  {item.id === "3" && (
                    <CompanyInfoForm
                      request={request}
                      setRequest={setRequest}
                    />
                  )}
                </TabPane>
              ))}
            </Tabs>
          </div>
          <div className="add-box-shadow w-30">
            <h3 className="header_text__18">Price details</h3>
            <div className="d-flex flex-column gap-3 my-3">
              <div>
                <SearchToolTip
                  label="label"
                  data={rateplanList}
                  selectKey={"value"}
                  value={getValue(
                    request,
                    `room_data[${activeRoomIndex}].rate_plan_id`,
                    ""
                  )}
                  onChange={(e: any) =>
                    handleSelectArrayChange(
                      activeRoomIndex,
                      e,
                      "rate_plan_id",
                      "room_data",
                      setRequest
                    )
                  }
                  name="Rate Plan"
                />

                <div className="mx-1 mt-2">
                  <InputRuleForm
                    inputType="TEXT"
                    name="rate_plan_price"
                    value={getValue(ratePlanRequest, `rate_plan_price`, "")}
                    placeholder="Enter Rate Plan Price"
                    label="Rate Plan Price"
                    disabled
                  />
                </div>
                <div className="mx-1">
                  <InputRuleForm
                    inputType="TEXT"
                    name="total_tax"
                    value={getValue(ratePlanRequest, `total_tax`, "")}
                    placeholder="Enter Total Tax"
                    label="Total Tax"
                    disabled
                  />
                </div>
                <div className="mx-1">
                  <InputRuleForm
                    inputType="TEXT"
                    name="total_after_tax"
                    value={getValue(ratePlanRequest, `total_after_tax`, "")}
                    placeholder="Enter Total After tax"
                    label="Total After tax"
                    disabled
                  />
                </div>
                <div className="mx-1">
                  <InputRuleForm
                    inputType="TEXT"
                    name="total_tax"
                    value={getValue(ratePlanRequest, `total_tax`, "")}
                    placeholder="Enter Total tax"
                    label="Total tax"
                    disabled
                  />
                </div>
                <div className="mx-1">
                  <InputRuleForm
                    inputType="TEXT"
                    name="sub_total"
                    value={getValue(ratePlanRequest, `sub_total`, "")}
                    placeholder="Enter Sub Total"
                    label="Sub Total"
                    disabled
                  />
                </div>
                <div className="mx-1">
                  <InputRuleForm
                    inputType="TEXT"
                    name="total"
                    value={getValue(ratePlanRequest, `total`, "")}
                    placeholder="Enter Total"
                    label="Total"
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <CommonRightPopup
        isPopupVisible={openDayLevelAmount}
        toggle={toggleDayLevelAmount}
        width="800px"
        title="Day level amount"
        hideNew
        second_title="Save"
      >
        <DayLevelAmount />
      </CommonRightPopup> */}
    </div>
  );
};

export default CreateWalkIN;
