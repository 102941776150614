import { capitalizeFirstLetter } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import { Table } from "antd";
import React from "react";

const GroupsAgents = (props: any) => {
  const { isLoading2, groupDetails, setSelectedAgentIds } = props;
  const columns = [
    {
      title: "NAME",
      dataIndex: "first_name",
      key: "first_name",
      render: (first_name: any, text: any) => (
        <>
          <h6
            className="header_text__14"
            // onClick={() =>
            //   navigate(
            //     `/${getValue(
            //       params,
            //       "orgId",
            //       ""
            //     )}/settings/groups/edit/${getValue(text, "id", "")}`
            //   )
            // }
          >
            {capitalizeFirstLetter(first_name) +
              " " +
              capitalizeFirstLetter(getValue(text, "last_name", ""))}
          </h6>
          {getValue(text, "email", "") && (
            <p className="small_text__14">{getValue(text, "email", "")}</p>
          )}
        </>
      ),
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      key: "email",
    },
    // {
    //   title: "ROLES",
    //   dataIndex: "is_admin",
    //   key: "is_admin",
    //   // render: (is_admin: any, text: any) => <div>{is_admin}</div>,
    // },
    // {
    //   title: "CHANNELS",
    //   dataIndex: "business_hour",
    //   key: "business_hour",
    //   render: (business_hour: any, text: any) => (
    //     <div>{getValue(business_hour, "name", "")}</div>
    //   ),
    // },
  ];

  const handleRowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      const updatedAgents = selectedRowKeys.map((key) => ({
        id: key as string,
      }));
      setSelectedAgentIds(updatedAgents);
    },
  };

  return (
    <Table
      size="middle"
      bordered
      columns={columns}
      dataSource={getValue(groupDetails, "agents", "")}
      rowKey="id"
      loading={isLoading2}
      className="mt-3"
      rowSelection={{
        type: "checkbox",
        ...handleRowSelection,
      }}
      pagination={{
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} of ${total} items`,
      }}
    />
  );
};

export default GroupsAgents;
