import { Tabs } from "antd";
import { useRef } from "react";
import ChatEditor from "./chat-editor";

function ChatFooter(props) {
  const {
    showEmojiPicker,
    handleEmojiClick,
    message,
    handleSendMessages,
    handleUploadDocument,
    setMessage,
    handleChangeEditor,
    handleChangePrivateEditor,
    toggleAiModal,
    isRephraseLoading,
    contentLength,
    privateContentLength,
    handleSendPrivateMessages,
    privateMessage,
    setPrivateMessage,
    handlePrivateEmojiClick,
    openCannedResponse,
    setOpenCannedResponse,
    handleCannedOpen,
    cannedPopupPosition,
    cannedList,
    personalCannedList,
    nonPersonalcannedList,
    isCannedLoading,
    handleCannedResponse,
    handleCannedScroll,
    cannedLoadMore,
  } = props;

  const editorRef = useRef(null);
  const privateEditorRef = useRef(null);
  const handleSubmit = () => {
    setMessage(`<p></p>`);
    handleSendMessages();
  };
  const handlePrivateSubmit = () => {
    setPrivateMessage(`<p></p>`);
    handleSendPrivateMessages();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSubmit();
    }
  };
  const handlePrivateKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handlePrivateSubmit();
    }
  };

  return (
    <div>
      <Tabs
        defaultActiveKey="1"
        className="ant-inbox-tab"
        items={[
          {
            label: "Reply",
            key: "1",
            children: (
              <ChatEditor
                contentLength={contentLength}
                showEmojiPicker={showEmojiPicker}
                handleEmojiClick={handleEmojiClick}
                handleUploadDocument={handleUploadDocument}
                message={message}
                toggleAiModal={toggleAiModal}
                isRephraseLoading={isRephraseLoading}
                handleKeyDown={handleKeyDown}
                editorRef={editorRef}
                handleChangeEditor={handleChangeEditor}
                handleSubmit={handleSubmit}
                openCannedResponse={openCannedResponse}
                setOpenCannedResponse={setOpenCannedResponse}
                handleCannedOpen={handleCannedOpen}
                cannedPopupPosition={cannedPopupPosition}
                cannedList={cannedList}
                personalCannedList={personalCannedList}
                nonPersonalcannedList={nonPersonalcannedList}
                isCannedLoading={isCannedLoading}
                handleCannedResponse={handleCannedResponse}
                handleCannedScroll={handleCannedScroll}
                cannedLoadMore={cannedLoadMore}
              />
            ),
          },
          {
            label: "Private Note",
            key: "2",
            children: (
              <ChatEditor
                contentLength={privateContentLength}
                showEmojiPicker={showEmojiPicker}
                handleEmojiClick={handlePrivateEmojiClick}
                handleUploadDocument={handleUploadDocument}
                message={privateMessage}
                toggleAiModal={toggleAiModal}
                isRephraseLoading={isRephraseLoading}
                handleKeyDown={handlePrivateKeyDown}
                editorRef={privateEditorRef}
                handleChangeEditor={handleChangePrivateEditor}
                handleSubmit={handlePrivateSubmit}
                placeHolder="Use '@' to mention people and '#' to mention Slack channels"
              />
            ),
          },
        ]}
      />
    </div>
  );
}

export default ChatFooter;
