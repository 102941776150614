import SettingUsers from "@pages/Private/Settings/Users";
import LoginHistory from "@pages/Private/Settings/Users/LoginHistory";

export const SettingsUsersRoutes = [
  {
    path: "/:orgId/settings/general/users",
    name: "settings_team_users",
    component: SettingUsers,
  },
  {
    path: "/:orgId/settings/general/users/login/:id",
    name: "settings_team_users",
    component: LoginHistory,
  },
];
