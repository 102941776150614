import { getValue } from "@utils/lodash";
import React, { useEffect, useState } from "react";
// import Chart from "react-apexcharts";
import DashboardMoreOptions from "../../components/dashboard-more-options";
import { findLabelFromKeyItem } from "../../helpers/enum";

function DynamicHeatMap(props: any) {
  const [chartData, setChartData] = useState({
    series: [],
    options: {},
  });

  useEffect(() => {
    const data = props.graph || [];
    const sortedData: any = [...data].sort((a, b) => b.count - a.count);
    // Transform and sort the data
    const series = sortedData
      .map((item: any) => ({
        name: findLabelFromKeyItem(item),
        count: getValue(item, `count`, 0),
        data: [
          {
            x: "Count",
            y: getValue(item, `count`, 0),
          },
        ],
      }))
      // Remove the temporary count property used for sorting
      .map(({ name, data }: any) => ({ name, data }));

    setChartData({
      series: series,
      options: {
        chart: {
          type: "heatmap",
          height: 350,
        },
        dataLabels: {
          enabled: true,
        },
        colors: ["#008FFB"],
        xaxis: {
          labels: {
            show: true,
          },
        },
        yaxis: {
          labels: {
            show: true,
          },
        },
      },
    });
  }, [props.graph]);
  return (
    <div
      className={`${
        getValue(props, "isMaximized", "") ? "" : "dashboard_border"
      }`}
    >
      <p className="header_text__16 pb-3">{props.name || "Heat Map Chart"}</p>
      <div
        className={`${
          getValue(props, "isMaximized", "")
            ? "maximized-chart-container"
            : "chart-holder"
        }`}
      >
        {/* {chartData.series.length > 0 && (
          <Chart
            options={chartData.options}
            series={chartData.series}
            type="heatmap"
          />
        )} */}
      </div>
      {!props.hideOptions && (
        <DashboardMoreOptions
          props={props}
          id={getValue(props, "id", "")}
          confirmDeleteAnalyticsComponent={
            props.confirmDeleteAnalyticsComponent
          }
          handleEditChart={props.handleEditChart}
        />
      )}
    </div>
  );
}

export default DynamicHeatMap;
