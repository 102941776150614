import { convertCurrentDateWithTime } from "@common/date-helpers";
import { getValue } from "@utils/lodash";
import { Button, Modal, Table, Tooltip } from "antd";
import { Plus, Trash2 } from "lucide-react";

const BusinessHoursHolidayTab = ({
  request,
  handleDeleteRow,
  handleOpenHolidayModal,
}: any) => {
  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "DATE",
      dataIndex: "date",
      key: "date",
      render: (date: any) => convertCurrentDateWithTime(date),
    },
    {
      title: "MORE",
      dataIndex: "address",
      key: "address",
      render: (_: any, record: any, index: number) => (
        <div className="d-flex justify-content-center">
          <Tooltip title="Delete">
            <Trash2
              size={16}
              className="cursor-pointer"
              onClick={() => handleDeleteRow(index)}
            />
          </Tooltip>
        </div>
      ),
      width: 70,
    },
  ];

  return (
    <div>
      <div className="d-flex gap-5 align-items-center mb-3">
        Holidays will be ignored when calculating SLA for a ticket.
        <Button
          icon={<Plus size={16} />}
          onClick={() => handleOpenHolidayModal()}
        >
          Add Holidays
        </Button>
      </div>
      <Table
        dataSource={getValue(request, "holidays", [])}
        columns={columns}
        className="w-50"
        pagination={false}
        rowKey={(record) => record.date}
      />
    </div>
  );
};

export default BusinessHoursHolidayTab;
