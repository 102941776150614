import "./nodata.scss";
import ButtonComponent from "@components/Form/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";

function InboxNoDataPage(props: any) {
  const params = useParams();
  const navigate = useNavigate();
  return (
    <div className="n_container">
      <div className="nodata_container">
        <div className="d-flex justify-content-center align-items-center">
          <div className="nodata_container_main">
            <h6 className="nodata_container_header">
              Configure Whatsapp messages in Appzo.
            </h6>
            <p className="nodata_container_desc mt-2 mb-3">
              Whatsapp messages will appear here if you have configured through
              Appzo.
            </p>
            <ButtonComponent
              buttonText="Configure Whatsapp"
              buttonType="primary"
              onClickHandler={() =>
                navigate(
                  `/${getValue(params, "orgId", "")}/settings/chat/messages`
                )
              }
            />
          </div>
          <div className="w-10"></div>
          <img
            src={"/nodata/calls.svg"}
            alt="SVG Image"
            className="nodata_container_image"
          />
        </div>
      </div>
    </div>
  );
}

export default InboxNoDataPage;
