import { handleAntSelectChange, handleTextChange } from "@common/handleChange";
import FormDescription from "@components/custom/FormDescrption/FormDescription";
import InputRuleForm from "@components/InputRuleForm/form";
import { getValue } from "@utils/lodash";
import { Select } from "antd";

const GroupsGeneralSettings = ({ request, setRequest, businessHour }: any) => {
  return (
    <div className="w-50">
      <InputRuleForm
        inputType="TEXT"
        name="name"
        value={getValue(request, "name", "")}
        placeholder="Enter Name"
        required
        label="Name (For Own Reference)"
        onChange={(e: any) => handleTextChange(e, request, setRequest)}
      />
      <FormDescription
        request={request}
        textClassname="mb-3 mt-3 w-50"
        inputClassname="mt-2 mb-4"
        input={
          <InputRuleForm
            inputType="TEXTAREA"
            name="description"
            value={getValue(request, `description`, "")}
            placeholder="Enter Description"
            label="Description"
            onChange={(e: any) => handleTextChange(e, request, setRequest)}
          />
        }
      />
      <div className="mb-4">
        <label className="form-label">Business Hour</label>
        <Select
          showSearch
          placeholder="Select a Business Hour"
          className="w-100"
          size="large"
          options={businessHour}
          value={request.business_hour}
          onChange={(value) =>
            handleAntSelectChange(value, "business_hour", request, setRequest)
          }
        />
      </div>
    </div>
  );
};

export default GroupsGeneralSettings;
