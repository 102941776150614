import MainLayout from "@layouts/HomeLayout/NewLayout";
import { useEffect, useState } from "react";
import CompanyProfileSubHeader from "../../CompanyProfile/components/CompanyProfileSubHeader";
import CompanyProfileDetailsLayout from "@layouts/SettingsLayout/CompanyProfile";
import { Button, Modal, Tabs, TabsProps } from "antd";
import { getValue } from "@utils/lodash";
import { useNavigate, useParams } from "react-router-dom";
import InputRuleForm from "@components/InputRuleForm/form";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import timezones from "@common/json/standardized-timezone-data.json";
import AddHolidayModal from "./components/AddHolidayModal";
import { handleSelectChange, handleTextChange } from "@common/handleChange";
import BusinessHoursTab from "./components/BusinessHoursTab";
import BusinessHoursHolidayTab from "./components/BusinessHoursHolidayTab";
import {
  createBusinessHour,
  getSpecificBusinessHour,
  updateBusinessHour,
} from "@services/business-hours.service";
import { toast } from "sonner";
import BackButton from "@components/custom/BrowserBack";
import FormDescription from "@components/custom/FormDescrption/FormDescription";

interface Hour {
  day: string;
  start_time: string;
  end_time: string;
}

interface Request {
  name: string;
  description: string;
  time_zone: string;
  business_hours_type: string;
  hours: Hour[];
  holidays: any[];
}

const days = [
  { value: "sunday", label: "Sunday", shortened: "Sun" },
  { value: "monday", label: "Monday", shortened: "Mon" },
  { value: "tuesday", label: "Tuesday", shortened: "Tue" },
  { value: "wednesday", label: "Wednesday", shortened: "Wed" },
  { value: "thursday", label: "Thursday", shortened: "Thu" },
  { value: "friday", label: "Friday", shortened: "Fri" },
  { value: "saturday", label: "Saturday", shortened: "Sat" },
];

const CreateBusinessHour = ({ props }: any) => {
  const params = useParams();
  const navigate = useNavigate();
  const [isHolidaysOpen, setHolidaysOpen] = useState(false);
  const [request, setRequest] = useState<Request>({
    name: "",
    description: "",
    time_zone: "",
    business_hours_type: "24_7_availability",
    hours: [],
    holidays: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [currentModal, setCurrentModal] = useState(0);
  const [addAnother, setAddAnother] = useState(false);
  const [editId, seteditId] = useState("");

  /* -------------------------------------------------------------------------- */
  /*                              UseEffect Section                             */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (getValue(params, "id", "")) {
      seteditId(getValue(params, "id", ""));
    }
  }, [params]);

  useEffect(() => {
    if (editId) {
      getBusinessHour(true);
    }
  }, [editId]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      let payload = JSON.parse(JSON.stringify(request));
      const resp = editId
        ? await updateBusinessHour(editId, payload)
        : await createBusinessHour(payload);

      if (resp) {
        toast.success("Created Successfully");

        setRequest({
          name: "",
          description: "",
          time_zone: "",
          business_hours_type: "24_7_availability",
          hours: [],
          holidays: [],
        });
        navigate(`/${getValue(params, "orgId", "")}/settings/business-hours`);
      } else {
        toast.error(
          `Failed to ${editId ? "editing" : "creating"} Business Hour`
        );
      }
    } catch (error) {
      toast.error(`Error ${editId ? "editing" : "creating"} Business Hour`);
    } finally {
      setIsLoading(false);
    }
  };

  const getBusinessHour = async (status: boolean) => {
    try {
      setIsLoading1(status);
      const resp = await getSpecificBusinessHour(editId);
      if (resp) {
        setRequest({
          ...request,
          name: getValue(resp, "data.name", ""),
          description: getValue(resp, "data.description", ""),
          time_zone: getValue(resp, "data.time_zone", ""),
          business_hours_type: getValue(resp, "data.business_hours_type", ""),
          hours: getValue(resp, "data.hours", []),
          holidays: getValue(resp, "data.holidays", []),
        });
        setCurrentModal(getValue(resp, "data.holidays.length", 0));
      }
    } catch (error) {
      toast.error("Failed to load response.");
    } finally {
      setIsLoading1(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                Section                                  */
  /* -------------------------------------------------------------------------- */
  const handleAddAnotherChange = (e: any) => {
    setAddAnother(e.target.checked);
  };

  const handleOpenHolidayModal = () => {
    setHolidaysOpen(true);
    setCurrentModal(currentModal + 1);
    setRequest({
      ...request,
      holidays: [...request.holidays, { name: "", date: "" }],
    });
  };

  const handleOkHolidayModal = () => {
    if (addAnother) {
      setCurrentModal(currentModal + 1);
      setRequest({
        ...request,
        holidays: [...request.holidays, { name: "", date: "" }],
      });
    } else {
      setHolidaysOpen(false);
    }
  };

  const handleCloseHolidayModal = () => {
    setCurrentModal(currentModal - 1);

    setRequest({
      ...request,
      holidays: request.holidays.filter(
        (_, index) => index !== currentModal - 1
      ),
    });

    setHolidaysOpen(false);
  };

  const handleDeleteRow = (index: string) => {
    Modal.confirm({
      title: `Are you sure you want to delete this holiday?`,
      content: "Once deleted, this holiday will be removed from the calender.",
      okText: "Delete",
      cancelText: "Cancel",
      onOk: async () => {
        setCurrentModal(currentModal - 1);

        setRequest({
          ...request,
          holidays: request.holidays.filter((_: any, i: any) => i !== index),
        });
      },
    });
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Business Hours",
      children: (
        <BusinessHoursTab
          request={request}
          setRequest={setRequest}
          days={days}
        />
      ),
    },
    {
      key: "2",
      label: "Holidays",
      children: (
        <BusinessHoursHolidayTab
          request={request}
          handleDeleteRow={handleDeleteRow}
          handleOpenHolidayModal={handleOpenHolidayModal}
        />
      ),
    },
  ];

  return (
    <MainLayout {...props}>
      <CompanyProfileSubHeader
        addChildren={
          <Button
            type="primary"
            size="large"
            onClick={() => handleSubmit()}
            loading={isLoading}
            disabled={
              !getValue(request, "name", "") ||
              !getValue(request, `time_zone`, "")
            }
          >
            {editId ? "Update" : "Create"} Business Hour
          </Button>
        }
      />
      <CompanyProfileDetailsLayout classname="my-0 p-4">
        <h6 className="header_text__18 d-flex align-items-center gap-3 mb-4">
          <BackButton className="" />
          {editId ? "Edit" : "New"} Business Hour
        </h6>
        <div className="w-50">
          <InputRuleForm
            inputType="TEXT"
            name="name"
            value={getValue(request, "name", "")}
            placeholder="e.g. Chicago Business Hours"
            required
            label="Name (For Own Reference)"
            onChange={(e: any) => handleTextChange(e, request, setRequest)}
          />
        </div>
        <FormDescription
          request={request}
          textClassname="mb-2 mt-3"
          inputClassname="w-50"
          input={
            <InputRuleForm
              inputType="TEXTAREA"
              name="description"
              value={getValue(request, `description`, "")}
              placeholder="e.g. This Business Calender belongs to Chicago timezone"
              label="Description"
              onChange={(e: any) => handleTextChange(e, request, setRequest)}
            />
          }
        />
        <div className="w-50">
          <SearchToolTip
            placeholder={`Enter Time Zone`}
            onChange={(e: object) =>
              handleSelectChange(e, "time_zone", request, setRequest)
            }
            label={`label`}
            //   width={"365px"}
            data={timezones}
            value={getValue(request, `time_zone`, "")}
            selectKey={"value"}
            name={"Time Zone"}
            concatLength={100}
            required
          />
        </div>
        <Tabs defaultActiveKey="1" items={items} className="mt-3" />
      </CompanyProfileDetailsLayout>
      <AddHolidayModal
        open={isHolidaysOpen}
        handleOk={handleOkHolidayModal}
        handleCancel={handleCloseHolidayModal}
        request={request}
        setRequest={setRequest}
        currentModal={currentModal}
        addAnother={addAnother}
        handleAddAnotherChange={handleAddAnotherChange}
      />
      {/* ))} */}
    </MainLayout>
  );
};

export default CreateBusinessHour;
