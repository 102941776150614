import MainLayout from "@layouts/HomeLayout/NewLayout";
import CompanyProfileSubHeader from "../CompanyProfile/components/CompanyProfileSubHeader";
import { Button, Checkbox, Modal, Select, Table } from "antd";
import { Plus, Trash2 } from "lucide-react";
import { getValue } from "@utils/lodash";
import CompanyProfileDetailsLayout from "@layouts/SettingsLayout/CompanyProfile";
import ListLoader from "@components/common/Loader";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import SettingsPageDetails from "@components/Pages/PageDescription/SettingsPageDetails";
import { groupsDetails } from "@common/Data/page-details-data";
import InputRuleForm from "@components/InputRuleForm/form";
import FormDescription from "@components/custom/FormDescrption/FormDescription";
import { handleAntSelectChange, handleTextChange } from "@common/handleChange";
import { QueryRequestHelper } from "@common/query-request-helper";
import { getAllBusinessHours } from "@services/business-hours.service";
import { toast } from "sonner";
import {
  createGroups,
  deleteGroups,
  getAllGroups,
} from "@services/groups.service";
import { capitalizeFirstLetter } from "@common/text-helpers";

const SettingsGroups = ({ props }: any) => {
  const navigate = useNavigate();
  const params = useParams();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [request, setRequest] = useState({
    name: "",
    description: "",
    business_hour: "",
  });
  const [businessHour, setBusinessHour] = useState([]);
  const [addAgents, setAddAgents] = useState(false);
  const [list, setList] = useState([]);

  /* -------------------------------------------------------------------------- */
  /*                              UseEffect Section                             */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (Object.keys(UrlParams).length === 0) {
      getBusinessHoursList(true);
      getGroups(true);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(UrlParams).length > 0) {
      if (UrlParams.page_no) {
        setPage_no(parseInt(UrlParams.page_no));
      }
      if (UrlParams.limit) {
        setLimit(parseInt(UrlParams.limit));
      }
      getGroups(true);
    }
  }, [window.location.href]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      let payload = JSON.parse(JSON.stringify(request));
      const resp = await createGroups(payload);

      if (resp) {
        toast.success("Created Successfully");
        setRequest({
          name: "",
          description: "",
          business_hour: "",
        });
        setIsModalOpen(false);
        navigate(
          `/${getValue(params, "orgId", "")}/settings/groups/edit/${getValue(
            resp,
            "data.id",
            ""
          )}${addAgents ? "?addAgent=true" : ""}`
        );
      } else {
        toast.error(`Failed to create Group`);
      }
    } catch (error) {
      toast.error(`Error creating Group`);
    } finally {
      setIsLoading(false);
    }
  };

  const getBusinessHoursList = async (status: boolean) => {
    try {
      setIsLoading(status);
      const payload = {
        page_size: 10,
        page_no: 1,
      };
      const queryRequest = QueryRequestHelper(payload);
      const resp = await getAllBusinessHours(queryRequest);
      if (resp) {
        const modifiedResp = getValue(resp, "data.business_hours", []).map(
          (item: any) => ({
            value: getValue(item, "id", ""),
            label: getValue(item, "name", ""),
          })
        );
        setBusinessHour(modifiedResp);
      }
    } catch (error) {
      toast.error("Failed to load business hours.");
    } finally {
      setIsLoading(false);
    }
  };

  const getGroups = async (status: boolean) => {
    try {
      setIsLoading1(status);
      const payload = {
        page_size: getValue(UrlParams, `page_size`, "")
          ? getValue(UrlParams, `page_size`, "")
          : limit,
        page_no: getValue(UrlParams, `page_no`, "")
          ? getValue(UrlParams, `page_no`, "")
          : page_no,
      };
      const queryRequest = QueryRequestHelper(payload);
      const resp = await getAllGroups(queryRequest);
      if (resp) {
        setList(getValue(resp, "data.groups", []));
        // setLimit(getValue(resp, "data.pagination.page_size", 0));
        setPage_no(getValue(resp, "data.pagination.page", 0));
        setTotalCount(getValue(resp, "data.pagination.total", 0));
        setIsLoading1(false);
      }
    } catch (error) {
      toast.error("Failed to load groups");
      setIsLoading1(false);
    } finally {
      setIsLoading1(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);

  const handleChangePagination = (page_no: any, limit: any) => {
    setPage_no(page_no);
    setLimit(limit);

    let payload = {
      ...UrlParams,
      page_no: page_no,
      page_size: limit,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
    setPage_no(page_no);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleAddAgent = () => {
    setAddAgents(!addAgents);
  };

  const handleDeleteModal = (id: string) => {
    Modal.confirm({
      title: `Are you sure you want to delete this group?`,
      content: "Once deleted, this group cannot be recovered.",
      okText: "Delete",
      cancelText: "Cancel",
      onOk: async () => {
        try {
          await deleteGroups(id);
          toast.success("Group deleted successfully");
          getGroups(true);
        } catch (error) {
          toast.error("Error deleting group");
        }
      },
    });
  };

  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      render: (name: any, text: any) => (
        <>
          <h6
            className="header_blue_text__14 cursor-pointer"
            onClick={() =>
              navigate(
                `/${getValue(
                  params,
                  "orgId",
                  ""
                )}/settings/groups/edit/${getValue(text, "id", "")}`
              )
            }
          >
            {capitalizeFirstLetter(name)}
          </h6>
          {getValue(text, "description", "") && (
            <p className="small_text__14">
              {getValue(text, "description", "")}
            </p>
          )}
        </>
      ),
    },
    {
      title: "AGENTS",
      dataIndex: "agents",
      key: "agents",
      render: (agents: any, text: any) => <div>{agents.length}</div>,
    },
    {
      title: "BUSINESS HOURS",
      dataIndex: "business_hour",
      key: "business_hour",
      render: (business_hour: any, text: any) => (
        <div>{getValue(business_hour, "name", "")}</div>
      ),
    },
    {
      title: "ACTION",
      dataIndex: "id",
      key: "id",
      width: 90,
      render: (id: any, text: any) => (
        <div className="d-flex justify-content-center">
          <Trash2
            size={18}
            onClick={() => handleDeleteModal(id)}
            className="cursor-pointer "
          />
        </div>
      ),
    },
  ];

  return (
    <MainLayout {...props}>
      <CompanyProfileSubHeader
        addChildren={
          <Button
            type="primary"
            size="large"
            icon={<Plus size={20} />}
            onClick={() => showModal()}
          >
            New Group
          </Button>
        }
      />
      <CompanyProfileDetailsLayout classname="my-0">
        {isLoading ? (
          <ListLoader />
        ) : (
          <div className="sla-policy-wrapper">
            <div className="w-70 p-4 sla-policy-card-container  overflow-hidden">
              <h6 className="header_text__18 mb-2">Groups</h6>
              <Table
                size="middle"
                bordered
                columns={columns}
                dataSource={list}
                rowKey="id"
                loading={isLoading1}
                className="mt-4 groups-ant-table"
                pagination={{
                  current: page_no,
                  pageSize: limit,
                  total: totalCount,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "20", "50", "100"],
                  onChange: (page_no, pageSize) => {
                    handleChangePagination(page_no, pageSize);
                  },
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                }}
                scroll={{
                  y: `calc(100vh - 300px`,
                }}
              />
            </div>
            <div className="w-30 p-4 sla-policy-right-details">
              <SettingsPageDetails data={groupsDetails} />
            </div>
          </div>
        )}
        <Modal
          title="New group"
          okText="Create"
          open={isModalOpen}
          onOk={handleSubmit}
          onCancel={handleCancel}
          okButtonProps={{
            disabled: !getValue(request, "name", ""),
          }}
          footer={(_, { OkBtn, CancelBtn }) => (
            <div className="d-flex justify-content-between align-items-center">
              <Checkbox checked={addAgents} onChange={handleAddAgent}>
                Add Agents in the next step
              </Checkbox>
              <div className="d-flex gap-2 align-items-center">
                <CancelBtn />
                <OkBtn />
              </div>
            </div>
          )}
        >
          <InputRuleForm
            inputType="TEXT"
            name="name"
            value={getValue(request, "name", "")}
            placeholder="Enter Name"
            required
            label="Name (For Own Reference)"
            onChange={(e: any) => handleTextChange(e, request, setRequest)}
          />

          <FormDescription
            request={request}
            textClassname="mb-2 mt-3 w-50"
            inputClassname="mt-2 mb-2"
            input={
              <InputRuleForm
                inputType="TEXTAREA"
                name="description"
                value={getValue(request, `description`, "")}
                placeholder="Enter Description"
                label="Description"
                onChange={(e: any) => handleTextChange(e, request, setRequest)}
              />
            }
          />
          <div className="mb-4">
            <label className="form-label">Business Hour</label>
            <Select
              showSearch
              placeholder="Select a Business Hour"
              // optionFilterProp="label"
              className="w-100"
              size="large"
              options={businessHour}
              value={request.business_hour}
              onChange={(value) =>
                handleAntSelectChange(
                  value,
                  "business_hour",
                  request,
                  setRequest
                )
              }
            />
          </div>
        </Modal>
      </CompanyProfileDetailsLayout>
    </MainLayout>
  );
};

export default SettingsGroups;
