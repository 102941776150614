import { QueryRequestHelper } from "@common/query-request-helper";
import * as React from "react";
import DashboardHeader from "./components/header";
import { getValue } from "@utils/lodash";
import { allPipelines, getSpecificPipeline } from "@services/pipeline.service";
import { listAllModules } from "@services/modules.service";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getStartOfMonthDate } from "@common/date-helpers";
import "./dashboard.scss";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Colors,
  PointElement,
  LineElement,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  convertNumbersToSquareBrackets,
  removeDuplicates,
  removeNullOrUndefinedProperties,
  removeNullValuesFromObjectArray,
  sortJSONObjectArray,
} from "@common/text-helpers";
import useDynamicTitle from "@context/useDynamicTitle";
import { usePermissionContext } from "@context/permissionContext";
import { toast } from "sonner";
import ListLoader from "@components/common/Loader";
import {
  createAnalytics,
  createAnalyticsComponent,
  deleteAnalytics,
  deleteAnalyticsComponent,
  getAllAnalytics,
  getAnalyticsDashboardGraphData,
  getAnalyticsGraph,
  updateAnalytics,
  updateAnalyticsComponent,
} from "@services/analytics.service";
import ComponentKPIStyleDrawer from "./Drawers/component-kpi-style-drawer";
import ComponentKPIDrawer from "./Drawers/component-kpi-drawer";
import ComponentChartStyleDrawer from "./Drawers/component-chart-style-drawer";
import ComponentChartDrawer from "./Drawers/component-chart-drawer";
import DashboardComponentGraphs from "./Graphs";
import { getAllWorkflowConditionFields } from "@services/workflow.service";
import SimpleReactValidator from "simple-react-validator";
import { Modal } from "antd";
import ChartDetailsDrawer from "./Drawers/chart-details-drawer";
import { getAllModuleFields } from "@services/module-fields.service";
import { sortTaskFieldList } from "@components/Pages/Pipeline/helpers/create-pipeline-helper";
import MainLayout from "@layouts/HomeLayout/NewLayout";
import { DashboardDateEnums, DashboardLookupEnums } from "./helpers/enum";
import { useStateContext } from "@context/profileProvider";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
  Colors
);

const NewDashboard = (props: any) => {
  useDynamicTitle("Dashboard");
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const {
    subTabPermissionList,
    permissionAPITriggered,
  } = usePermissionContext();
  let date_range = getStartOfMonthDate();
  const simpleValidator = React.useRef(new SimpleReactValidator());
  const [, forceUpdate] = React.useState(0);
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  React.useEffect(() => {
    if (Object.keys(UrlParams).length === 0 && permissionAPITriggered) {
      // getModules();
      getAllDashboardList();
    }
  }, [permissionAPITriggered]);

  React.useEffect(() => {
    if (Object.keys(UrlParams).length > 0 && permissionAPITriggered) {
      // getModules();
      getAllDashboardList();
      if (getValue(UrlParams, `analyticsId`, "")) {
        setSelectedDashboard(getValue(UrlParams, `analyticsId`, ""));
      }
      if (getValue(UrlParams, `module`, "")) {
        setSelectedModule(getValue(UrlParams, `module`, ""));
      }
      if (getValue(UrlParams, `pipeline`, "")) {
        setSelectedPipeline(getValue(UrlParams, `pipeline`, ""));
      }
    }
  }, [
    window.location.href,
    getValue(location, `key`, ""),
    permissionAPITriggered,
  ]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /*                            Dashboard Section                               */
  /* -------------------------------------------------------------------------- */
  const [selectedComponentList, setSelectedComponentList] = React.useState<any>(
    []
  );
  const getDashboardInfo = async (components: any) => {
    setDashboardLoading(true);
    if (getValue(components, `length`, 0) > 0) {
      try {
        const results = await Promise.all(
          components.map(async (item: object) => {
            let payload = {};
            let queryRequest = QueryRequestHelper(payload);
            let resp = await getAnalyticsGraph(
              getValue(item, `id`, ""),
              queryRequest
            );
            return resp ? getValue(resp, `data`, {}) : null;
          })
        );
        // Filter out null responses and remove duplicates using Set
        const uniqueResults = Array.from(
          new Set(
            results
              .filter((resp) => resp !== null)
              .map((item) => {
                // Assuming each item has a unique identifier like 'id'
                // If not, you might need to create a unique key based on relevant properties
                return JSON.stringify(item);
              })
          )
        ).map((item) => JSON.parse(item));
        setSelectedComponentList([...uniqueResults]);
        setDashboardLoading(false);
      } catch (error) {
        setDashboardLoading(false);
        console.error(error);
      }
    } else {
      setDashboardLoading(false);
      setSelectedComponentList([]);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                        Module & Pipepline Section                          */
  /* -------------------------------------------------------------------------- */
  const [selectedModule, setSelectedModule] = React.useState("");
  const [allModules, setAllModules] = React.useState([]);
  const getModules = async () => {
    try {
      let payload = {};
      let queryRequest = QueryRequestHelper(payload);
      let resp = await listAllModules(queryRequest);
      if (resp) {
        let list =
          getValue(resp, `data.length`, 0) > 0
            ? getValue(resp, `data`, []).map((item: object) => ({
                ...item,
                value: getValue(item, `api_name`, ""),
                name: getValue(item, `api_name`, ""),
                label: getValue(item, `label`, ""),
                permission_key:
                  getValue(item, `api_name`, "") === "companies"
                    ? `crm_company`
                    : `crm_${getValue(item, `api_name`, "")}`,
              }))
            : [];
        let allNew = list.filter(
          (item: object) =>
            getValue(item, `name`, "") !== "calls" &&
            getValue(item, `name`, "") !== "meetings"
        );
        let all = allNew.filter((item: object) =>
          subTabPermissionList.includes(getValue(item, `permission_key`, ""))
        );
        setAllModules(sortJSONObjectArray(all, "seq_num"));
        setSelectedModule(
          getValue(UrlParams, `module`, "")
            ? getValue(UrlParams, `module`, "")
            : getValue(all, `[${0}].api_name`, "")
        );
        if (
          getValue(UrlParams, `module`, "") !== "tasks" ||
          getValue(all, `[${0}].api_name`, "") !== "tasks"
        ) {
          setNewModule(getValue(all, `[${0}].api_name`, ""));
          getAllPipelines(
            getValue(UrlParams, `module`, "")
              ? getValue(UrlParams, `module`, "")
              : getValue(all, `[${0}].api_name`, ""),
            list,
            getValue(all, `[${0}].id`, "")
          );
        }
      }
    } catch (error) {}
  };

  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedPipeline, setSelectedPipeline] = React.useState("");
  const [pipelines, setAllPipelines] = React.useState([]);
  const getAllPipelines = async (
    code: string,
    allModules: any,
    module_id: string
  ) => {
    setNewModule(code);
    try {
      setIsLoading(true);
      let payload = {
        // module_name: code,
      };
      let moduleInfo: any =
        getValue(allModules, `length`, 0) > 0 &&
        allModules.find(
          (item: object) => getValue(item, `api_name`, "") === code
        );
      let queryRequest = QueryRequestHelper(payload);
      let resp = await allPipelines(
        module_id ? module_id : getValue(moduleInfo, `id`, ""),
        queryRequest
      );
      if (getValue(resp, `data.length`, 0) > 0) {
        let list = getValue(resp, `data`, []).map((item: object) => ({
          ...item,
          value: getValue(item, `api_name`, ""),
          label: getValue(item, `label`, ""),
        }));
        setAllPipelines(list);
        setModuleFields(removeDuplicates(list));
        setSelectedPipeline(
          getValue(UrlParams, `pipeline`, "")
            ? getValue(UrlParams, `pipeline`, "")
            : getValue(list, `[${0}].id`, "")
        );
        setIsLoading(false);

        // ----------------- Pipeline Section -----------------//
        setComponentRequest((prevState: any) => ({
          ...prevState,
          module: getValue(resp, `data[${0}].module_id`, ""),
          pipeline: getValue(resp, `data[${0}].id`, ""),
        }));
        getSpecificPipelineInfo(
          getValue(resp, `data[${0}].id`, ""),
          getValue(resp, `data[${0}].module_id`, "")
        );
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getAllAssociationFields = async (id: string) => {
    try {
      let fields = await getAllModuleFields(id);
      if (fields) {
        let list = sortTaskFieldList(getValue(fields, `data`, []));
        const aDict = list.reduce((dict: any, item: any) => {
          dict[item.name] = item;
          return dict;
        }, {});
        const ReorderedList: any = [];
        // Add elements from b in the order they appear in a
        for (const name of getValue(fields, `data`, [])) {
          if (aDict[name]) {
            ReorderedList.push(aDict[name]);
          }
        }
        // Add elements from a that are not in b to the end
        for (const item of list) {
          if (!getValue(fields, `data`, []).includes(item.name)) {
            ReorderedList.push(item);
          }
        }
        setModuleFields(removeDuplicates(ReorderedList));
      } else {
        setIsLoading(false);
      }
    } catch (error) {}
  };

  const removeDuplicatesByModule = (data: any) => {
    if (getValue(data, `length`, 0) > 0) {
      const uniqueData = data.reduce((acc: any, current: any) => {
        if (
          !acc.find(
            (item: any) =>
              getValue(item, `module_field.id`, "") ===
              getValue(current, `module_field.id`, "")
          )
        ) {
          acc.push(current);
        }
        return acc;
      }, []);
      return uniqueData;
    } else {
      return [];
    }
  };

  const [formLoading, setFormLoading] = React.useState(false);
  const [dateColumnFields, setDateColumnFields] = React.useState<any>([]);
  const [formColumnFields, setFormColumnFields] = React.useState<any>([]);
  const [currencyColumnFields, setCurrencyColumnFields] = React.useState<any>(
    []
  );
  const [pipelineStageList, setPipelineStageList] = React.useState([]);
  const getSpecificPipelineInfo = async (id: string, module: string) => {
    if (id) {
      try {
        let resp = await getSpecificPipeline(
          getValue(componentRequest, `module`, ""),
          id
        );
        if (resp) {
          const formFieldsData = removeDuplicatesByModule(
            getValue(resp, `data.form_fields`, [])
          );
          let stages = getValue(resp, `data.stages`, []).map(
            (item: object) => ({
              ...item,
              value: getValue(item, `id`, ""),
            })
          );
          setPipelineStageList(stages);
          setModuleFields(formFieldsData);
          setFormLoading(false);
        } else {
          setFormLoading(false);
        }
      } catch (error) {}
    }
  };
  const { modules } = useStateContext();

  const setModuleFields = async (formFieldsData: any) => {
    const hasFormFields = formFieldsData.length > 0;
    const findModuleName = modules.find(
      (item: object) =>
        getValue(formFieldsData, `[${0}].module_id`, "") ===
        getValue(item, `id`, "")
    );
    const module = getValue(findModuleName, `api_name`, "");
    const mapFields = (fields: object[]) =>
      fields.map((item: object) => ({
        ...getValue(item, `module_field`, {}),
        name: getValue(item, `api_name`, ""),
        quick_add: getValue(item, `quick_add`, ""),
        required: getValue(item, `required`, ""),
        seq_num: getValue(item, `seq_num`, ""),
      }));
    const fields = hasFormFields ? mapFields(formFieldsData) : [];

    const numberFields = hasFormFields
      ? formFieldsData.filter((item: object) =>
          ["currency"].includes(getValue(item, `module_field.input_type`, ""))
        )
      : [];

    const deteFields = hasFormFields
      ? formFieldsData.filter((item: object) =>
          ["date", "datetime"].includes(
            getValue(item, `module_field.input_type`, "")
          )
        )
      : [];
    const formFields = hasFormFields
      ? formFieldsData.filter(
          (item: object) =>
            !["date", "datetime"].includes(
              getValue(item, `module_field.input_type`, "")
            )
        )
      : [];

    // Add unique objects to date and form fields
    const uniqueDateFields = [
      ...mapFields(deteFields),
      ...[
        {
          id: "created_at",
          value: "created_at",
          label: "Created At",
          name: "created_at",
        },
        {
          id: "updated_at",
          value: "updated_at",
          label: "Updated At",
          name: "updated_at",
        },
      ],
    ].filter(
      (field, index, self) => index === self.findIndex((t) => t.id === field.id)
    );

    const pipelineStageField =
      module !== "tasks"
        ? [
            {
              id: "pipeline_stage",
              value: "pipeline_stage",
              label: "Status",
              name: "pipeline_stage",
            },
          ]
        : [];

    const uniqueFormFields = [
      ...mapFields(formFields),
      ...[
        {
          id: "created_by",
          value: "created_by",
          label: "Created By",
          name: "created_by",
        },
        {
          id: "updated_by",
          value: "updated_by",
          label: "Updated By",
          name: "updated_by",
        },
        ...pipelineStageField, // Conditionally add pipeline_stage field
      ],
    ].filter(
      (field, index, self) => index === self.findIndex((t) => t.id === field.id)
    );
    const uniqueCurrencyFields = [...mapFields(numberFields)].filter(
      (field, index, self) => index === self.findIndex((t) => t.id === field.id)
    );

    setDateColumnFields(uniqueDateFields);
    setFormColumnFields(uniqueFormFields);
    setCurrencyColumnFields(uniqueCurrencyFields);
  };
  const [dashboardLoading, setDashboardLoading] = React.useState(false);

  /* -------------------------------------------------------------------------- */
  /*                   Create Analytics Dashboard Section                       */
  /* -------------------------------------------------------------------------- */

  const [newModule, setNewModule] = React.useState("");
  const [allDashboardList, setAllDashboardList] = React.useState([]);
  const [listLoading, setListLoading] = React.useState(true);
  const [selectedDashboard, setSelectedDashboard] = React.useState("");
  const getAllDashboardList = async () => {
    try {
      setListLoading(true);
      let payload = {
        page_size: 100,
        page_no: 1,
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllAnalytics(queryRequest);
      if (resp) {
        setAllDashboardList(
          getValue(resp, `data.analytics.length`, 0) > 0
            ? getValue(resp, `data.analytics`, []).map((item: object) => ({
                ...item,
                label: getValue(item, `name`, ""),
                value: getValue(item, `id`, ""),
              }))
            : []
        );
        if (getValue(UrlParams, `analyticsId`, "")) {
          setSelectedDashboard(getValue(UrlParams, `analyticsId`, ""));
          const analyticsData = getValue(resp, "data.analytics", []);
          const selectedDashboard = analyticsData.find(
            (item: object) =>
              getValue(item, "id", "") ===
              getValue(UrlParams, `analyticsId`, "")
          );
          getDashboardInfo(getValue(selectedDashboard, "components", []));
          let info = getValue(resp, "data.analytics", []).find(
            (item: object) =>
              getValue(item, `id`, "") ===
              getValue(UrlParams, `analyticsId`, "")
          );
          if (
            !getValue(info, `module_id`, "") &&
            !getValue(info, `module_specific`, false)
          ) {
            getModules();
          } else {
            if (getValue(info, `name`, "") === "Tasks") {
              getAllAssociationFields(getValue(info, `module_id`, ""));
            } else {
              getAllPipelines("", [], getValue(info, `module_id`, ""));
            }
            setAllModules([]);
          }
        } else {
          setSelectedDashboard(getValue(resp, `data.analytics[${0}].id`, ""));
          getDashboardInfo(
            getValue(resp, `data.analytics[${0}].components`, [])
          );
          if (
            !getValue(resp, `data.analytics[${0}].module_specific`, false) &&
            !getValue(resp, `data.analytics[${0}].module_id`, "")
          ) {
            getModules();
          } else {
            if (getValue(resp, `data.analytics[${0}].name`, "") === "Tasks") {
              getAllAssociationFields(
                getValue(resp, `data.analytics[${0}].module_id`, "")
              );
            } else {
              getAllPipelines(
                "",
                [],
                getValue(resp, `data.analytics[${0}].module_id`, "")
              );
            }
            setAllModules([]);
          }
        }
        setTimeout(() => {
          setListLoading(false);
        }, 500);
      } else {
        setListLoading(false);
      }
    } catch (error) {
      setListLoading(false);
    }
  };

  const refreshDashboardList = () => {
    if (getValue(UrlParams, `analyticsId`, "")) {
      setSelectedDashboard(getValue(UrlParams, `analyticsId`, ""));
      const selectedDashboard: any = allDashboardList.find(
        (item: object) =>
          getValue(item, "id", "") === getValue(UrlParams, `analyticsId`, "")
      );
      getDashboardInfo(getValue(selectedDashboard, "components", []));
    } else {
      getDashboardInfo(getValue(allDashboardList, `[${0}].components`, []));
    }
  };

  const [dashboardName, setDashboardName] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const CreateAnalyticsDashboard = async (name: string) => {
    try {
      setLoading(true);
      let resp = await createAnalytics({
        name: name,
      });
      if (resp) {
        setLoading(false);
        setSelectedComponentList([]);
        toast.success("Create successfully");
        let payload = {
          ...UrlParams,
          analyticsId: getValue(resp, `data.id`, ""),
        };
        let queryRequest = QueryRequestHelper(payload);
        navigate(`${window.location.pathname}?${queryRequest}`);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const hideValidation = () => {
    simpleValidator.current.hideMessages();
    simpleValidator.current.fields = {};
    simpleValidator.current.errorMessages = {};
    forceUpdate(0);
  };

  const [dashboardId, setDashboardId] = React.useState("");
  const UpdateAnalyticsDashboard = async () => {
    try {
      setLoading(true);
      let resp = await updateAnalytics(dashboardId, {
        name: dashboardName,
      });
      if (resp) {
        setLoading(false);
        toast.success("Create successfully");
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);
  const [deleteIdLoading, setDeleteLoading] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState("");
  const DeleteAnalyticsDashboard = async () => {
    try {
      setDeleteLoading(true);
      let resp = await deleteAnalytics(deleteId);
      if (resp) {
        setDeleteLoading(false);
        toast.success("Create successfully");
      } else {
        setDeleteLoading(false);
      }
    } catch (error) {
      setDeleteLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                   Create Analytics Dashboard Component Section             */
  /* -------------------------------------------------------------------------- */
  const intialState = {
    name: "",
    analytics: "",
    component_type: "chart",
    graph_type: "standard",
    module: "",
    pipeline: "",
    module_field: "",
    child_module_field: "",
    date_granularity: "",
    child_date_granularity: "",
    is_child: false,
    duration_type: "today",
    show_rank: "",
    custom_from_date: null,
    custom_to_date: null,
    custom_last_days: 1,
    compare_to: "previous_period",
    compare_from_date: null,
    compare_to_date: null,
    compare_objective: "positive",
    benchmark: 0,
    maximum_grouping: "25",
    pattern: "",
    is_benchmark: false,
    metric: "COUNT",
    criteria_filters: [
      // {
      //   comparator: "",
      //   field: "",
      //   column: "created_by",
      //   condition: "and",
      //   value: "",
      // },
    ],
  };

  const [componentRequest, setComponentRequest] = React.useState<any>(
    intialState
  );
  const resetAnalyticRequest = () => {
    setComponentRequest(intialState);
  };

  const updateIsChild = (obj: any) => {
    if (obj.child_module_field) {
      obj.is_child = true;
    } else {
      obj.is_child = false;
    }
    return obj;
  };

  const [cLoading, setCLoading] = React.useState(false);
  const CreateAnalyticsComponent = async () => {
    let conditions: any = getValue(
      componentRequest,
      `criteria_filters`,
      []
    ).map((item: object, index: number) => ({
      fieldId: getValue(item, `fieldId`, ""),
      id: getValue(item, `id`, ""),
      operator: getValue(item, `operator`, ""),
      value: getValue(item, `value`, "").map((ite: object) =>
        getValue(ite, `key`, "")
      ),
      index: getValue(item, `index`, null)
        ? getValue(item, `index`, null)
        : index + 1,
      action: getValue(item, `action`, ""),
    }));
    let removedConidtions = conditions.filter(
      (item: object, _index: number) =>
        getValue(item, `action`, "") === "remove"
    );

    let mainConidtions = conditions.filter(
      (item: object, _index: number) =>
        getValue(item, `action`, "") !== "remove"
    );

    let allConidtions = mainConidtions.map((item: object, index: number) => ({
      ...item,
      index: index + 1,
    }));
    let modifiedConditions = removedConidtions.concat(allConidtions);
    let removeConditions = getValue(
      componentRequest,
      `conditions`,
      []
    ).filter((item: object) => getValue(item, `id`, ""));
    let removeConditionsMain =
      getValue(removeConditions, `length`, 0) > 0
        ? removeConditions.map((item: object, _index: number) => ({
            ...item,
            action: "remove",
          }))
        : [];
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        let obj = {};
        let payload = JSON.parse(JSON.stringify(componentRequest));
        obj = {
          name: getValue(payload, `name`, ""),
          analytics: getValue(UrlParams, `analyticsId`, "")
            ? getValue(UrlParams, `analyticsId`, "")
            : getValue(payload, `analytics`, "")
            ? getValue(payload, `analytics`, "")
            : selectedDashboard,
          component_type: getValue(payload, `component_type`, ""),
          graph_type: getValue(payload, `graph_type`, ""),
          module: getValue(payload, `module`, ""),
          pipeline: getValue(payload, `pipeline`, ""),
          module_field: getValue(payload, `module_field`, ""),
          duration_type: getValue(payload, `duration_type`, ""),
          compare_to: getValue(payload, `compare_to`, ""),
          compare_objective: getValue(payload, `compare_objective`, ""),
          child_module_field: getValue(payload, `child_module_field`, ""),
          date_granularity: getValue(payload, `date_granularity`, ""),
          child_date_granularity: getValue(
            payload,
            `child_date_granularity`,
            ""
          ),
          is_child: getValue(payload, `is_child`, false),
          show_rank: getValue(payload, `show_rank`, ""),
          custom_from_date: getValue(payload, `custom_from_date`, null),
          custom_to_date: getValue(payload, `custom_to_date`, null),
          compare_from_date: getValue(payload, `compare_from_date`, null),
          compare_to_date: getValue(payload, `compare_to_date`, null),
          benchmark: getValue(payload, `benchmark`, 0)
            ? parseInt(getValue(payload, `benchmark`, 0))
            : 0,
          maximum_grouping: getValue(payload, `maximum_grouping`, 0)
            ? parseInt(getValue(payload, `maximum_grouping`, 0))
            : 0,
          metric: getValue(payload, `metric`, "")
            ? getValue(payload, `metric`, "")
            : "COUNT",
          pattern: convertNumbersToSquareBrackets(
            getValue(payload, `pattern`, "")
          ),
          criteria_filters: removeNullValuesFromObjectArray(modifiedConditions),
          // getValue(payload, `criteria_filters.length`, 0) > 0
          //   ? getValue(
          //       payload,
          //       `criteria_filters`,
          //       []
          //     ).map((item: object, _index: number) =>
          //       getValue(item, `value`, "")
          //     )
          //   : [],
        };
        setCLoading(true);
        let resp;
        if (analyticsEditId) {
          resp = await updateAnalyticsComponent(
            analyticsEditId,
            removeNullOrUndefinedProperties(updateIsChild(obj))
          );
        } else {
          resp = await createAnalyticsComponent(
            removeNullOrUndefinedProperties(updateIsChild(obj))
            // removeNullOrUndefinedPropertiesForProperty(obj)
          );
        }
        if (resp) {
          setCLoading(false);
          hideValidation();
          toast.success(
            `${analyticsEditId ? "Updated" : "Created"} successfully`
          );
          resetAnalyticRequest();
          getAllDashboardList();
          onCloseKPIComponentDrawer();
          onCloseChartComponentDrawer();
          setAnalyticsEditId("");
        } else {
          setCLoading(false);
          hideValidation();
        }
      } catch (error) {
        setCLoading(false);
        hideValidation();
      }
    }
  };

  function extractOperators(input: string) {
    const operators = [];
    operators.push("WHEN"); // Add WHEN as the first element
    const keywords = input.match(/[A-Z]+/g); // Extract uppercase words
    if (keywords) {
      keywords.forEach((word) => {
        operators.push(word);
      });
    }
    return operators;
  }

  function revertCriteriaFilters(pattern: string, transformedFilters: any) {
    let conditionsTypes: any = extractOperators(pattern);
    return transformedFilters.map((filter: any, index: number) => {
      // If value is an array of strings, wrap each in an object with 'key'
      if (Array.isArray(filter.value)) {
        filter.value = getValue(filter, `value`, []).map((item: any) => ({
          key: item,
        }));
      } else if (typeof filter?.value === "string") {
        filter.value = [{ key: filter?.value }];
      } else {
        filter.value = [];
      }
      // Add the new fieldId property
      filter.fieldId = getValue(filter, `column`, "")
        ? getValue(filter, `column`, "")
        : getValue(filter, `module_field.id`, "")
        ? getValue(filter, `module_field.id`, "")
        : "";
      filter.action = "update";
      filter.pattern =
        getValue(conditionsTypes, `length`, 0) > 0
          ? conditionsTypes[index]
          : "";
      return filter;
    });
  }

  const [editComponentId, setEditComponentId] = React.useState("");
  const UpdateAnalyticsComponent = async () => {
    try {
      setCLoading(true);
      let resp = await updateAnalyticsComponent(
        editComponentId,
        componentRequest
      );
      if (resp) {
        setCLoading(false);
        toast.success("Create successfully");
      } else {
        setCLoading(false);
      }
    } catch (error) {
      setCLoading(false);
    }
  };

  const [isDeleteOpen1, setIsDeleteOpen1] = React.useState(false);
  const [deleteId1, setDeleteId1] = React.useState("");
  const [componentDeleteLoading, setComponentDeleteLoading] = React.useState(
    false
  );
  const DeleteAnalyticsComponent = async () => {
    try {
      setComponentDeleteLoading(true);
      let resp = await deleteAnalyticsComponent(deleteId1);
      if (resp) {
        setComponentDeleteLoading(false);
        setDeleteId1("");
        setIsDeleteOpen1(false);
        toast.success("Delete successfully");
        getAllDashboardList();
      } else {
        setComponentDeleteLoading(false);
      }
    } catch (error) {
      setComponentDeleteLoading(false);
    }
  };
  const confirmDeleteAnalyticsComponent = (id: any) => {
    setIsDeleteOpen1(true);
    setDeleteId1(id);
  };

  /* ---------------------------   Drawer Section -------------------------------- */

  const [selectedComponentOption, setSelectedComponentOption] = React.useState(
    ""
  );
  /* ---------------------------   KPI Style Section -------------------------------- */

  const [openKPIStyleComponent, setOpenKPIStyleComponent] = React.useState(
    false
  );
  const showKPIStyleComponentDrawer = () => {
    setOpenKPIStyleComponent(true);
  };
  const onCloseKPIStyleComponentDrawer = () => {
    setOpenKPIStyleComponent(false);
  };

  /* ---------------------------   Chart Style Section -------------------------------- */

  const [openChartStyleComponent, setOpenChartStyleComponent] = React.useState(
    false
  );
  const showChartStyleComponentDrawer = () => {
    setOpenChartStyleComponent(true);
  };
  const onCloseChartStyleComponentDrawer = () => {
    setOpenChartStyleComponent(false);
  };

  /* ---------------------------   KPI Section -------------------------------- */

  const [openKPIComponent, setOpenKPIComponent] = React.useState(false);
  const showKPIComponentDrawer = () => {
    setOpenKPIComponent(true);
  };
  const onCloseKPIComponentDrawer = () => {
    setOpenKPIComponent(false);
  };

  const openKPIDrawer = (name: string) => {
    resetAnalyticRequest();
    setComponentRequest({
      ...componentRequest,
      component_type: "kpi",
      graph_type: name,
    });
    setSelectedComponentOption(name);
    onCloseKPIStyleComponentDrawer();
    showKPIComponentDrawer();
  };

  /* ---------------------------   Chart Section -------------------------------- */

  const [openChartComponent, setOpenChartComponent] = React.useState(false);
  const showChartComponentDrawer = () => {
    setOpenChartComponent(true);
  };
  const onCloseChartComponentDrawer = () => {
    setOpenChartComponent(false);
  };

  const openChartDrawer = (name: string) => {
    resetAnalyticRequest();
    setComponentRequest({
      ...componentRequest,
      component_type: "chart",
      graph_type: name,
    });
    setSelectedComponentOption(name);
    onCloseChartStyleComponentDrawer();
    showChartComponentDrawer();
  };

  /* -------------------------------------------------------------------------- */
  /*                                Condition Section                           */
  /* -------------------------------------------------------------------------- */
  React.useEffect(() => {
    if (
      (newModule && getValue(componentRequest, `pipeline`, "")) ||
      newModule === "tasks"
    ) {
      getConditions(newModule, getValue(componentRequest, `pipeline`, ""));
    }
  }, [newModule, getValue(componentRequest, `pipeline`, "")]);
  const [workflowConditions, setWorkflowCondtions] = React.useState<any>([]);
  const [workflowOptions, setWorkflowOptions] = React.useState<any>([]);
  const getConditions = async (module: string, pipelineId: string) => {
    setWorkflowOptions([]);
    let payload = {
      module: module,
      pipelineId: pipelineId,
    };
    let queryRequest = QueryRequestHelper(payload);
    try {
      let resp = await getAllWorkflowConditionFields(queryRequest);
      if (resp) {
        // Additional static fields to be added
        const staticFields = [
          {
            id: "created_at",
            value: "created_at",
            label: "Created At",
            name: "created_at",
            inputType: "datetime_dashboard",
            fieldType: "datetime_dashboard",
            fieldId: "created_at",
            conditions: DashboardDateEnums,
          },
          {
            id: "updated_at",
            value: "updated_at",
            label: "Updated At",
            name: "updated_at",
            inputType: "datetime_dashboard",
            fieldType: "datetime_dashboard",
            fieldId: "updated_at",
            conditions: DashboardDateEnums,
          },
          {
            id: "created_by",
            value: "created_by",
            label: "Created By",
            name: "created_by",
            inputType: "lookup",
            fieldId: "created_by",
            fieldType: "lookup",
            lookupModule: { api_name: "org_user" },
            conditions: DashboardLookupEnums,
          },
          {
            id: "updated_by",
            value: "updated_by",
            label: "Updated By",
            name: "updated_by",
            inputType: "lookup",
            fieldType: "lookup",
            fieldId: "updated_by",
            lookupModule: { api_name: "org_user" },
            conditions: DashboardLookupEnums,
          },
        ];
        // Function to remove duplicates
        const removeDuplicates = (
          existingArray: any,
          newArray: any,
          uniqueKey = "id"
        ) => {
          const existingIds = new Set(
            existingArray.map((item: any) => item[uniqueKey])
          );
          const filteredNewArray = newArray.filter(
            (newItem: any) => !existingIds.has(newItem[uniqueKey])
          );
          return [...existingArray, ...filteredNewArray];
        };

        // Process workflow options with deduplication
        const processedWorkflowOptions = getValue(resp, `data`, []).map(
          (item: object) => ({
            id: getValue(item, `fieldId`, ""),
            label: getValue(item, `fieldLabel`, ""),
            inputType: getValue(item, `fieldType`, ""),
            value: getValue(item, `fieldId`, ""),
          })
        );

        const WorkflowOptions = getValue(resp, `data`, []).map(
          (item: object) => ({
            ...item,
            inputType:
              getValue(item, `fieldId`, "") === "pipeline_stage_id"
                ? "select"
                : getValue(item, `fieldType`, ""),
            fieldType:
              getValue(item, `fieldId`, "") === "pipeline_stage_id"
                ? "select"
                : getValue(item, `fieldType`, ""),
          })
        );

        // Update workflow options with deduplication
        setWorkflowOptions((prevOptions: any) =>
          removeDuplicates(
            prevOptions,
            [...processedWorkflowOptions, ...staticFields],
            "id"
          )
        );

        // Update workflow conditions with deduplication
        setWorkflowCondtions((prevConditions: any) =>
          removeDuplicates(
            prevConditions,
            [...WorkflowOptions, ...staticFields],
            "id"
          )
        );
      }
    } catch (error) {
      console.error("Error fetching workflow conditions:", error);
    }
  };

  const [openChartDetails, setOpenChartDetails] = React.useState(false);
  const [isGraphDetailsLoading, setGraphDetailsLoading] = React.useState(false);
  const [isGraphDrawerLoading, setGraphDrawerLoading] = React.useState(false);

  const [graphInfo, setGraphInfo] = React.useState({});
  const toggleChartDetails = (obj: object) => {
    setOpenChartDetails(!openChartDetails);
    if (openChartDetails === false) {
      setGraphInfo(obj);
      getGraphDrawerDetails(getValue(obj, `id`, ""), 1, 10);
    } else {
    }
  };

  const closeChartDetails = () => {
    setOpenChartDetails(!openChartDetails);
  };

  const [chartInfo, setChartInfo] = React.useState([]);
  const [chartPageNo, setChartPageNo] = React.useState(1);
  const [chartPageSize, setChartPageSize] = React.useState(10);
  const [chartTotalCount, setChartTotalCount] = React.useState(10);

  const getGraphDrawerDetails = async (
    id: any,
    page_no: any,
    page_size: any
  ) => {
    setChartPageSize(page_size);
    setChartPageNo(page_no);
    try {
      let payload = {
        page_no: page_no ? page_no : chartPageNo,
        page_size: page_size ? page_size : chartPageSize,
      };
      let queryRequest = QueryRequestHelper(payload);
      setGraphDrawerLoading(true);
      let resp = await getAnalyticsDashboardGraphData(id, queryRequest);
      if (resp) {
        setChartInfo(getValue(resp, `data.data`, []));
        setChartTotalCount(getValue(resp, `data.pagination.total`, 0));
        setGraphDrawerLoading(false);
      } else {
        setGraphDrawerLoading(false);
      }
    } catch (error) {
      setGraphDrawerLoading(false);
    }
  };

  const handleChangePagination = (page_no: any) => {
    getGraphDrawerDetails(
      getValue(graphInfo, `id`, ""),
      page_no,
      chartPageSize
    );
  };
  const handleChangeLimit = (page_size: any) => {
    getGraphDrawerDetails(
      getValue(graphInfo, `id`, ""),
      chartPageNo,
      page_size
    );
  };

  const [isMaximized, setMaximized] = React.useState(false);
  const [selectedForMaximize, setSelectedForMaximize] = React.useState([]);
  /* -------------------------------------------------------------------------- */
  /*                         Dashboard Chart Edit Section                       */
  /* -------------------------------------------------------------------------- */
  const [analyticsEditId, setAnalyticsEditId] = React.useState("");
  const [stackedChart, setStackedChart] = React.useState(false);
  const handleEditChart = (obj: any) => {
    setNewModule(getValue(obj, `module.api_name`, ""));
    setAnalyticsEditId(getValue(obj, `id`, ""));
    setSelectedComponentOption(getValue(obj, `graph_type`, ""));
    setStackedChart(
      (
        getValue(obj, `child_module_column`, "")
          ? getValue(obj, `child_module_column`, "")
          : getValue(obj, `child_module_field.id`, "")
      )
        ? true
        : false
    );
    setComponentRequest((prevState: any) => ({
      ...prevState,
      name: getValue(obj, `name`, ""),
      analytics: getValue(obj, `analytics.id`, ""),
      component_type: getValue(obj, `component_type`, ""),
      graph_type: getValue(obj, `graph_type`, ""),
      module: getValue(obj, `module.id`, ""),
      pipeline: getValue(obj, `pipeline.id`, ""),
      module_field: getValue(obj, `module_column`, "")
        ? getValue(obj, `module_column`, "")
        : getValue(obj, `module_field.id`, ""),
      child_module_field: getValue(obj, `child_module_column`, "")
        ? getValue(obj, `child_module_column`, "")
        : getValue(obj, `child_module_field.id`, ""),
      date_granularity: getValue(obj, `date_granularity`, ""),
      child_date_granularity: getValue(obj, `child_date_granularity`, ""),
      is_child: getValue(obj, `is_child`, false),
      duration_type: getValue(obj, `duration_type`, ""),
      show_rank: getValue(obj, `show_rank`, ""),
      custom_from_date: getValue(obj, `custom_from_date`, ""),
      custom_to_date: getValue(obj, `custom_to_date`, ""),
      custom_last_days: getValue(obj, `custom_last_days`, ""),
      compare_to: getValue(obj, `compare_to`, ""),
      compare_from_date: getValue(obj, `compare_from_date`, ""),
      compare_to_date: getValue(obj, `compare_to_date`, ""),
      compare_objective: getValue(obj, `compare_objective`, ""),
      benchmark: getValue(obj, `benchmark`, ""),
      maximum_grouping: getValue(obj, `maximum_grouping`, ""),
      pattern: getValue(obj, `pattern`, ""),
      is_benchmark: getValue(obj, `is_benchmark`, false),
      metric: getValue(obj, `metric`, ""),
      metric_module_field: getValue(obj, `metric_module_field_id`, "")
        ? getValue(obj, `metric_module_field_id`, "")
        : getValue(obj, `metric`, ""),
      criteria_filters: revertCriteriaFilters(
        getValue(obj, `pattern`, ""),
        getValue(obj, `criteria_filters`, [])
      ),
    }));
    openRelativeDrawer(getValue(obj, `graph_type`, ""));
  };

  const openRelativeDrawer = (type: string) => {
    if (
      type === "standard" ||
      type === "growth_index" ||
      type === "scorecard" ||
      type === "rankings" ||
      type === "basic"
    ) {
      showKPIComponentDrawer();
    } else {
      showChartComponentDrawer();
    }
  };
  return (
    <>
      <MainLayout {...props} isLoading={dashboardLoading}>
        <DashboardHeader
          //module
          allModules={allModules}
          selectedModule={selectedModule}
          //pipelines
          pipelines={pipelines}
          selectedPipeline={selectedPipeline}
          isLoading={isLoading}
          //date
          setDashboardLoading={setDashboardLoading}
          //----------- New Dashboard  ----------//
          dashboardName={dashboardName}
          setDashboardName={setDashboardName}
          CreateAnalyticsDashboard={CreateAnalyticsDashboard}
          allDashboardList={allDashboardList}
          loading={loading}
          openKPI={showKPIStyleComponentDrawer}
          openGraph={showChartStyleComponentDrawer}
          getSpecificPipelineInfo={getSpecificPipelineInfo}
          request={componentRequest}
          setRequest={setComponentRequest}
          selectedDashboard={selectedDashboard}
          setSelectedComponentList={setSelectedComponentList}
          refreshDashboardList={refreshDashboardList}
        />
        {dashboardLoading || listLoading ? (
          <ListLoader />
        ) : getValue(selectedComponentList, `length`, 0) > 0 ? (
          <>
            {isMaximized ? (
              <div className="db_maximized_view">
                <DashboardComponentGraphs
                  props={selectedForMaximize}
                  key={0}
                  confirmDeleteAnalyticsComponent={
                    confirmDeleteAnalyticsComponent
                  }
                  toggleChartDetails={toggleChartDetails}
                  isMaximized={isMaximized}
                  setMaximized={setMaximized}
                  setSelectedForMaximize={setSelectedForMaximize}
                />
              </div>
            ) : (
              <div className="dashboard__container">
                {selectedComponentList.map((item: object, index: number) => {
                  return (
                    <div key={index}>
                      <DashboardComponentGraphs
                        props={item}
                        key={index}
                        confirmDeleteAnalyticsComponent={
                          confirmDeleteAnalyticsComponent
                        }
                        toggleChartDetails={toggleChartDetails}
                        setMaximized={setMaximized}
                        setSelectedForMaximize={setSelectedForMaximize}
                        handleEditChart={handleEditChart}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </>
        ) : (
          <div
            className="nodata_container"
            style={{ flex: "1", marginTop: "10%" }}
          >
            <div className="d-flex justify-content-between align-items-center">
              <div className="nodata_container_main">
                <h6 className="nodata_container_header">No Data Found.</h6>
                <p className="nodata_container_desc mt-2">
                  Expecting to see new {getValue(props, `module`, "")}? Try
                  again in a few seconds as the system catches up.
                </p>
              </div>
              <div className="w-10"></div>
              <img
                src={"/nodata/filter.svg"}
                alt="SVG "
                className="nodata_container_image"
              />
            </div>
          </div>
        )}

        {/*  -----------------------   Drawer Section -----------------------  */}

        <ComponentKPIStyleDrawer
          open={openKPIStyleComponent}
          onClose={onCloseKPIStyleComponentDrawer}
          openComponentDrawer={openKPIDrawer}
        />
        <ComponentKPIDrawer
          open={openKPIComponent}
          onClose={onCloseKPIComponentDrawer}
          selectedComponentOption={selectedComponentOption}
          allModules={allModules}
          request={componentRequest}
          setRequest={setComponentRequest}
          getAllPipelines={getAllPipelines}
          allPipelines={pipelines}
          setNewModule={setNewModule}
          newModule={newModule}
          getSpecificPipelineInfo={getSpecificPipelineInfo}
          dateColumnFields={dateColumnFields}
          formColumnFields={formColumnFields}
          isLoading={cLoading}
          handleSubmit={CreateAnalyticsComponent}
          showKPIStyleComponentDrawer={showKPIStyleComponentDrawer}
          workflowConditions={workflowConditions}
          workflowOptions={workflowOptions}
          simpleValidator={simpleValidator}
          hideValidation={hideValidation}
          resetAnalyticRequest={resetAnalyticRequest}
          allDashboardList={allDashboardList}
          setAnalyticsEditId={setAnalyticsEditId}
          getAllAssociationFields={getAllAssociationFields}
          pipelineStageList={pipelineStageList}
          currencyColumnFields={currencyColumnFields}
        />
        <ComponentChartStyleDrawer
          open={openChartStyleComponent}
          onClose={onCloseChartStyleComponentDrawer}
          openComponentDrawer={openChartDrawer}
        />
        <ComponentChartDrawer
          open={openChartComponent}
          onClose={onCloseChartComponentDrawer}
          selectedComponentOption={selectedComponentOption}
          allModules={allModules}
          request={componentRequest}
          setRequest={setComponentRequest}
          getAllPipelines={getAllPipelines}
          allPipelines={pipelines}
          setNewModule={setNewModule}
          newModule={newModule}
          getSpecificPipelineInfo={getSpecificPipelineInfo}
          dateColumnFields={dateColumnFields}
          formColumnFields={formColumnFields}
          isLoading={cLoading}
          handleSubmit={CreateAnalyticsComponent}
          showChartStyleComponentDrawer={showChartStyleComponentDrawer}
          workflowConditions={workflowConditions}
          workflowOptions={workflowOptions}
          simpleValidator={simpleValidator}
          hideValidation={hideValidation}
          resetAnalyticRequest={resetAnalyticRequest}
          allDashboardList={allDashboardList}
          setAnalyticsEditId={setAnalyticsEditId}
          stackedChart={stackedChart}
          setStackedChart={setStackedChart}
          getAllAssociationFields={getAllAssociationFields}
          pipelineStageList={pipelineStageList}
          currencyColumnFields={currencyColumnFields}
        />
        <Modal
          title="Confirm Deletion"
          open={isDeleteOpen1}
          onOk={DeleteAnalyticsComponent}
          onCancel={() => {
            setIsDeleteOpen1(false);
            setDeleteId1("");
          }}
          confirmLoading={componentDeleteLoading}
        >
          <p>Are you sure you want to delete this analytics component?</p>
        </Modal>
        <ChartDetailsDrawer
          open={openChartDetails}
          onClose={closeChartDetails}
          graphInfo={graphInfo}
          chartInfo={chartInfo}
          page_size={chartPageSize}
          page_no={chartPageNo}
          total_count={chartTotalCount}
          handleChangePagination={handleChangePagination}
          handleChangeLimit={handleChangeLimit}
        />
      </MainLayout>
    </>
  );
};

export default NewDashboard;
