import { getValue } from "@utils/lodash";
import { DatePicker, DatePickerProps } from "antd";
import dayjs, { Dayjs } from "dayjs";
import React from "react";

const SheetDateTimeEditor = React.memo(
  ({ col, row, field, handleDisplayItem, handleInputChange }: any) => {
    // Get the initial date and time value from the row
    const initialDate = handleDisplayItem(col, row);

    // Ensure the initial date is parsed correctly with dayjs
    const defaultValue = initialDate ? dayjs(initialDate) : null;

    // Handle changes in the DatePicker
    const onChange: DatePickerProps["onChange"] = (date: Dayjs | null) => {
      if (date) {
        // Pass the Dayjs date object to the handler
        handleInputChange(
          getValue(row, "id", ""),
          col,
          date.toISOString(),
          field
        );
      } else {
        // Pass null if cleared
        handleInputChange(getValue(row, "id", ""), col, null, field);
      }
    };

    return (
      <DatePicker
        showTime={{ format: "hh:mm A" }} // Show time in 12-hour format
        defaultValue={defaultValue} // Set the initial value
        onChange={onChange} // Handle change events
        format="DD-MMM-YYYY hh:mm A" // Format for display
        style={{ minWidth: "160px" }} // Style for the DatePicker
      />
    );
  }
);

export default SheetDateTimeEditor;
