import React, { useEffect, useState } from "react";
// import "./App.css";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useParams,
} from "react-router-dom";
import { PrivateRoutes } from "routes/private.routes";
import { PublicRoutes } from "routes/public.routes";
import NotFoundPage from "@pages/Public/404";
import ComfirmOrganization from "@pages/Public/UserInvitation/confirm-org";
import SettingsPreviewForm from "@pages/Private/Settings/FormCreator/preview-form";
import SettingsPreviewFormThankyou from "@pages/Private/Settings/FormCreator/preview-form-thankyou";
import GenerateWhatsappLink from "@pages/Private/Temp/Whatsapp/generate-whatsapp-link";
import DuplicateImports from "@pages/Public/DuplicateImports";
import { getValue } from "@utils/lodash";
import _ from "lodash";
import { usePermissionContext } from "@context/permissionContext";
import SharedImportData from "@pages/Public/ImportData/shared-data";
function App() {
  const {
    tabPermissionList,
    subTabPermissionList,
    permissionAPITriggered,
    mainPermissions,
  } = usePermissionContext();
  return (
    <div>
      <BrowserRouter>
        <Routes>
          {PublicRoutes.map((item, key) => {
            // eslint-disable-next-line
            const Element: any = item.component;
            return (
              <Route
                key={key}
                path={item.path}
                element={
                  !localStorage.getItem("accessToken") ? (
                    <Element />
                  ) : (
                    <Navigate to="/organisations" />
                  )
                }
              />
            );
          })}
          {PrivateRoutes.map((item: any, key) => {
            // eslint-disable-next-line
            const Element: any = item.component;
            let permissions = mainPermissions.filter(
              (items: object) => getValue(items, `tabKey`, "") == item.name
            );
            return (
              <Route
                key={key}
                path={item.path}
                element={
                  localStorage.getItem("accessToken") ? (
                    <Element
                      permissions={
                        getValue(permissions, `length`, 0) > 0
                          ? getValue(permissions, `[${0}].permissions`, [])
                          : []
                      }
                      name={getValue(item, `name`, "")}
                      tabPermissionList={tabPermissionList}
                      subTabPermissionList={subTabPermissionList}
                      permissionAPITriggered={permissionAPITriggered}
                    />
                  ) : (
                    <>
                      {window.location.pathname === "/organisations/join" ? (
                        <Navigate to={`/signin${window.location.search}`} />
                      ) : (
                        <Navigate to="/signin" />
                      )}
                    </>
                  )
                }
              />
            );
          })}
          <Route path={"/BTS-2024-exhibitor-directory"} element={<SharedImportData />} />
          <Route path={"/duplicate-imports"} element={<DuplicateImports />} />
          <Route
            path={"/generate-whatsapp-button"}
            element={<GenerateWhatsappLink />}
          />
          <Route
            path={"/organisations/join"}
            element={<ComfirmOrganization />}
          />
          <Route
            path={"/:orgId/settings/web-forms/:slug"}
            element={<SettingsPreviewForm />}
          />
          <Route
            path={"/:orgId/settings/web-forms/:slug/thankyou"}
            element={<SettingsPreviewFormThankyou />}
          />
          <Route path={"*"} element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
