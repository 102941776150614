import { Drawer, Button } from "antd";
import React, { useEffect, useState } from "react";
import {
  DashboardCompareObjectList,
  DashboardCompareToList,
  DashboardDateGranularityList,
  DashboardDurationTypeList,
  DashboardKPITypeList,
  DashboardMetricList,
  DashboardShowRankList,
} from "../helpers/enum";
import { getValue } from "@utils/lodash";
import InputRuleForm from "@components/InputRuleForm/form";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import CriteriaFilters from "../components/criteria-filters";
import ChartCustomSubmenuDropdown from "../components/chart-custom-submenu-dropdown";

function ComponentKPIDrawer(props: any) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const {
    request,
    setRequest,
    getAllPipelines,
    allPipelines,
    setNewModule,
    newModule,
    allModules,
    getSpecificPipelineInfo,
    dateColumnFields,
    isLoading,
    handleSubmit,
    onClose,
    showKPIStyleComponentDrawer,
    formColumnFields,
    workflowConditions,
    workflowOptions,
    simpleValidator,
    resetAnalyticRequest,
    setAnalyticsEditId,
    pipelineStageList,
    currencyColumnFields,
  } = props;

  const findName = (name: string) => {
    let info: any = DashboardKPITypeList.find(
      (item: object) => getValue(item, `value`, "") === name
    );
    return getValue(info, `label`, "");
  };

  const handleChangeStyle = () => {
    resetAnalyticRequest();
    onClose();
    props.hideValidation();
    showKPIStyleComponentDrawer();
    setAnalyticsEditId("");
  };
  const [isModuleOpen, setIsModuleOpen] = useState(false);

  const handleModuleOpenChange = (open: boolean) => {
    setIsModuleOpen(open);
  };
  const [selectedModuleField, setSelectModuleField] = useState("");

  const handleClearModule = () => {
    setRequest((prev: any) => {
      return {
        ...prev,
        module_field: "",
        duration_type: "",
      };
    });
    setSelectModuleField("");
  };

  useEffect(() => {
    if (getValue(request, `duration_type`, "") !== "custom") {
      setRequest({
        ...request,
        custom_from_date: null,
        custom_to_date: null,
      });
    }
  }, [request.duration_type]);

  const [isYAxisOpen, setIsYAxisOpen] = useState(false);
  const handleYAxisOpenChange = (open: boolean) => {
    setIsYAxisOpen(open);
  };
  const [selectedYAxisField, setSelectYAxisField] = useState("");

  const handleClearYAxis = () => {
    setRequest((prev: any) => {
      return {
        ...prev,
        child_module_field: "",
        child_date_granularity: "",
      };
    });
    setSelectYAxisField("");
  };

  const getSelectedDashboard = () => {
    const dashboards = getValue(props, `allDashboardList`, []);
    const analyticsId = getValue(UrlParams, `analyticsId`, "");

    if (dashboards.length > 0) {
      return analyticsId
        ? dashboards.find(
            (item: any) => getValue(item, `id`, "") === analyticsId
          ) || {}
        : dashboards[0];
    }

    return {}; // Return an empty object if no dashboards exist.
  };

  const findMeasureLabel = () => {
    switch (getValue(request, `graph_type`, "")) {
      case "standard":
      case "growth_index":
      case "basic":
      case "scorecard":
      case "rankings":
        return "Duration";
      default:
        return "";
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                                 Metric Section                             */
  /* -------------------------------------------------------------------------- */
  const [isMetricOpen, setIsMetricOpen] = useState(false);
  const handleMetricOpenChange = (open: boolean) => {
    setIsMetricOpen(open);
  };
  const [selectedMetricField, setSelectMetricField] = useState("");
  const handleClearMetric = () => {
    setRequest((prev: any) => {
      return {
        ...prev,
        module_field: "",
        date_granularity: "",
      };
    });
    setSelectMetricField("");
  };
  return (
    <Drawer
      title={findName(props.selectedComponentOption)}
      onClose={() => {
        props.onClose();
        props.hideValidation();
        setAnalyticsEditId("");
        setNewModule("");
      }}
      open={props.open}
      width={1000}
      className="dashboard-slider-container-footer"
    >
      <div className="d-flex justify-content-between gap-3">
        {DashboardKPITypeList.map(
          (item: object, index: number) =>
            getValue(item, "value", "") === props.selectedComponentOption && (
              <div style={{ maxWidth: "290px" }}>
                <div className="dashboard_charttype p-2">
                  <img
                    src={getValue(item, "image", "")}
                    alt={getValue(item, `label`, "")}
                    className="w-100"
                  />
                </div>
                <p
                  className="cursor-pointer header_blue_text__16 pt-2 text-center"
                  onClick={() => handleChangeStyle()}
                >
                  Change Style
                </p>
              </div>
            )
        )}

        <div className="d-flex align-items-start flex-column w-100">
          <div className="d-grid gap-3 w-100">
            <div className="db-charttype-creator">
              <h6 className="header_text__20">
                Component ({getValue(getSelectedDashboard(), `label`, "")})
              </h6>
              <div className="mb-3">
                <InputRuleForm
                  inputType="TEXT"
                  label="Component Name"
                  placeholder="Enter Component Name"
                  name="name"
                  value={getValue(request, `name`, "")}
                  onChange={(e: any) =>
                    setRequest({
                      ...request,
                      name: e.target.value,
                    })
                  }
                  validator={simpleValidator}
                  validLeft
                  required
                />
              </div>
              <div className="d-flex align-items-center gap-3">
                {getValue(getSelectedDashboard(), `name`, "") !== "Leads" &&
                  getValue(getSelectedDashboard(), `name`, "") !== "Contacts" &&
                  getValue(getSelectedDashboard(), `name`, "") !==
                    "Companies" &&
                  getValue(getSelectedDashboard(), `name`, "") !== "Tasks" &&
                  getValue(getSelectedDashboard(), `name`, "") !== "Deals" && (
                    <div className="mb-3">
                      <SearchToolTip
                        name="Module"
                        data={getValue(props, `allModules`, [])}
                        label="label"
                        selectKey="id"
                        value={getValue(request, `module`, "")}
                        // onChange={(e: any) => {
                        //   getAllPipelines(
                        //     getValue(e, `api_name`, ""),
                        //     allModules
                        //   );
                        //   setNewModule(getValue(e, `api_name`, ""));
                        //   setRequest({
                        //     ...request,
                        //     module: getValue(e, `id`, ""),
                        //     pipeline: "",
                        //   });
                        //   props.hideValidation();
                        // }}
                        onChange={(e: { id: string; api_name: string }) => {
                          const moduleApiName = getValue(e, "api_name", "");
                          const moduleId = getValue(e, "id", "");

                          // Fetch association fields for tasks
                          if (moduleApiName === "tasks") {
                            props.getAllAssociationFields(moduleId);
                          }
                          // Fetch pipelines for other modules
                          else {
                            getAllPipelines(moduleApiName, allModules);
                          }
                          // Update state
                          setNewModule(moduleApiName);
                          setRequest({
                            ...request,
                            module: moduleId,
                            pipeline: "",
                            criteria_filters: [],
                          });
                          // Hide validation
                          props.hideValidation();
                        }}
                        validator={simpleValidator}
                        required
                      />
                    </div>
                  )}
                {getValue(getSelectedDashboard(), `name`, "") !== "Tasks" &&
                  ["deals", "leads"].includes(
                    getValue(props, "newModule", "")
                  ) && (
                    <div className="mb-3">
                      <SearchToolTip
                        name="Pipeline"
                        label="label"
                        selectKey="id"
                        value={getValue(request, `pipeline`, "")}
                        data={allPipelines}
                        onChange={(e: any) => {
                          getSpecificPipelineInfo(getValue(e, `id`, ""));
                          setRequest({
                            ...request,
                            pipeline: getValue(e, `id`, ""),
                          });
                          props.hideValidation();
                        }}
                        validator={simpleValidator}
                        required
                      />
                    </div>
                  )}
              </div>
              <div className="w-100">
                {/* <SearchToolTip
                  name="KPI Metric"
                  label="label"
                  selectKey="value"
                  value={getValue(request, `metric`, "")}
                  data={
                    newModule === "deals" ||
                    newModule === "leads" ||
                    newModule === "calls"
                      ? DashboardKPIMetricCondition
                      : DashboardKPIMetricCountCondition
                  }
                  onChange={(e: any) => {
                    setRequest({
                      ...request,
                      metric: getValue(e, `value`, ""),
                    });
                  }}
                  validator={simpleValidator}
                  required
                /> */}
                <ChartCustomSubmenuDropdown
                  label={`KPI Metric`}
                  placeholder={`Enter KPI Metric`}
                  selectedSubMenu={getValue(request, "metric", "")}
                  request={request}
                  setRequest={setRequest}
                  isAntOpen={isMetricOpen}
                  mainMenu={currencyColumnFields}
                  formFields={[
                    {
                      id: "COUNT",
                      value: "COUNT",
                      label: "Count",
                      name: "COUNT",
                    },
                  ]}
                  subMenu={DashboardMetricList}
                  selectedField={selectedMetricField}
                  setSelectedField={setSelectMetricField}
                  handleAntOpenChange={handleMetricOpenChange}
                  handleClear={handleClearMetric}
                  dynamicModuleField="metric_module_field"
                  dynamicDateGranularity="metric"
                  validator={simpleValidator}
                  required
                  fromMetric
                />
              </div>

              <div className="w-50">
                <ChartCustomSubmenuDropdown
                  label={findMeasureLabel()}
                  placeholder={`Select ${findMeasureLabel()}`}
                  selectedSubMenu={getValue(request, "duration_type", "")}
                  request={request}
                  setRequest={setRequest}
                  isAntOpen={isModuleOpen}
                  mainMenu={dateColumnFields}
                  subMenu={DashboardDurationTypeList}
                  selectedField={selectedModuleField}
                  setSelectedField={setSelectModuleField}
                  handleAntOpenChange={handleModuleOpenChange}
                  handleClear={handleClearModule}
                  dynamicModuleField="module_field"
                  dynamicDateGranularity="duration_type"
                  validator={simpleValidator}
                  hideHeader
                  required
                  kpi
                />
              </div>

              {getValue(request, `duration_type`, "") === "custom" && (
                <div className="d-flex gap-3">
                  <InputRuleForm
                    inputType="DATE"
                    placeholder="Enter From Date"
                    label="Custom From Date"
                    value={getValue(request, `custom_from_date`, null)}
                    onChange={(e: any) =>
                      setRequest({
                        ...request,
                        custom_from_date: e,
                        custom_to_date: null,
                      })
                    }
                  />
                  <InputRuleForm
                    inputType="DATE"
                    placeholder="Enter To Date"
                    label="Custom To Date"
                    value={getValue(request, `custom_to_date`, null)}
                    onChange={(e: any) =>
                      setRequest({
                        ...request,
                        custom_to_date: e,
                      })
                    }
                    minDate={getValue(request, `custom_from_date`, null)}
                  />
                </div>
              )}
            </div>
            <div className="db-charttype-creator">
              <CriteriaFilters
                request={request}
                setRequest={setRequest}
                workflowConditions={workflowConditions}
                workflowOptions={workflowOptions}
                pipelineStageList={pipelineStageList}
              />
            </div>
            {(getValue(request, `graph_type`, "") === "standard" ||
              getValue(request, `graph_type`, "") === "growth_index" ||
              getValue(request, `graph_type`, "") === "scorecard") && (
              <div className="db-charttype-creator">
                <h6 className="header_text__20 ">Comparison Indicator</h6>
                <div className="mb-3 w-50 pe-2">
                  <SearchToolTip
                    name="Compare To"
                    data={DashboardCompareToList}
                    label="label"
                    selectKey="value"
                    value={getValue(request, `compare_to`, "")}
                    onChange={(e: any) =>
                      setRequest({
                        ...request,
                        compare_to: getValue(e, `value`, ""),
                        compare_from_date: null,
                        compare_to_date: null,
                      })
                    }
                  />
                </div>
                {getValue(request, `compare_to`, "") === "custom" && (
                  <div className="d-flex gap-3">
                    <InputRuleForm
                      inputType="DATE"
                      placeholder="Enter From Date"
                      label="Custom From Date"
                      value={getValue(request, `compare_from_date`, null)}
                      onChange={(e: any) =>
                        setRequest({
                          ...request,
                          compare_from_date: e,
                          compare_to_date: null,
                        })
                      }
                    />
                    <InputRuleForm
                      inputType="DATE"
                      placeholder="Enter To Date"
                      label="Custom To Date"
                      value={getValue(request, `compare_to_date`, null)}
                      onChange={(e: any) =>
                        setRequest({
                          ...request,
                          compare_to_date: e,
                        })
                      }
                      minDate={getValue(request, `compare_from_date`, null)}
                    />
                  </div>
                )}
                <div className="mb-3 w-50 pe-2">
                  <SearchToolTip
                    name="Objective"
                    data={DashboardCompareObjectList}
                    label="label"
                    selectKey="value"
                    value={getValue(request, `compare_objective`, "")}
                    onChange={(e: any) =>
                      setRequest({
                        ...request,
                        compare_objective: getValue(e, `value`, ""),
                      })
                    }
                  />
                </div>
              </div>
            )}
            {(getValue(request, `graph_type`, "") === "rankings" ||
              getValue(request, `graph_type`, "") === "scorecard") && (
              <div className="d-flex w-100 align-items-center db-charttype-creator gap-2">
                <div className="w-50">
                  <ChartCustomSubmenuDropdown
                    label={`Rank`}
                    placeholder={`Enter Rank`}
                    selectedSubMenu={getValue(
                      request,
                      "child_date_granularity",
                      ""
                    )}
                    request={request}
                    setRequest={setRequest}
                    isAntOpen={isYAxisOpen}
                    mainMenu={dateColumnFields}
                    formFields={formColumnFields}
                    subMenu={DashboardDateGranularityList}
                    selectedField={selectedYAxisField}
                    setSelectedField={setSelectYAxisField}
                    handleAntOpenChange={handleYAxisOpenChange}
                    handleClear={handleClearYAxis}
                    dynamicModuleField="child_module_field"
                    dynamicDateGranularity="child_date_granularity"
                    validator={simpleValidator}
                    required
                  />
                </div>

                <div className="mb-3 w-50">
                  <SearchToolTip
                    name="Show"
                    data={DashboardShowRankList}
                    label="label"
                    selectKey="value"
                    value={getValue(request, `show_rank`, "")}
                    onChange={(e: any) =>
                      setRequest({
                        ...request,
                        show_rank: getValue(e, `value`, ""),
                      })
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="text-right mt-2 ant-slider-custom-footer gap-2">
        <Button
          size="large"
          onClick={() => {
            props.onClose();
            props.hideValidation();
            resetAnalyticRequest();
            setAnalyticsEditId("");
            setNewModule("");
          }}
        >
          Cancel
        </Button>
        <Button
          size="large"
          type="primary"
          onClick={handleSubmit}
          loading={isLoading}
          disabled={isLoading}
        >
          Submit
        </Button>
      </div>
    </Drawer>
  );
}

export default ComponentKPIDrawer;
