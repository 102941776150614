import React from "react";
import { renderToString } from "react-dom/server";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw"; // To support raw HTML like <br />

// Convert markdown to HTML, ensuring line breaks are preserved
const convertMarkdownToHtml = (markdownContent: any) => {
  if (markdownContent) {
    // Replace all single newlines with explicit <br /> tags
    const formattedContent = markdownContent.replace(/\n/g, "\n<br/>\n"); // Add <br /> on each newline

    return renderToString(
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw]} // This allows rendering raw HTML like <br/>
        children={formattedContent}
      />
    );
  } else {
    return markdownContent;
  }
};

export default convertMarkdownToHtml;
