import MainLayout from "@layouts/HomeLayout/NewLayout";
import React, { useEffect, useState } from "react";
import CompanyProfileSubHeader from "../CompanyProfile/components/CompanyProfileSubHeader";
import CompanyProfileDetailsLayout from "@layouts/SettingsLayout/CompanyProfile";
import {
  Alert,
  Button,
  Dropdown,
  MenuProps,
  Modal,
  Pagination,
  Switch,
  Tooltip,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import { LockKeyhole, MoreVertical, Plus } from "lucide-react";
import ListLoader from "@components/common/Loader";
import SettingsPageDetails from "@components/Pages/PageDescription/SettingsPageDetails";
import "./sla-policy.scss";
import { slaPolicyDetails } from "@common/Data/page-details-data";
import { QueryRequestHelper } from "@common/query-request-helper";
import { toast } from "sonner";
import {
  deleteSlaPolicy,
  getAllSlaPolicy,
  updateSlaPolicySeqNum,
} from "@services/sla-policy.services";
import { capitalizeFirstLetter } from "@common/text-helpers";
import DragIndicatorSvgComponent from "@assets/svg/drag-indicator";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const SLAPolicy = ({ props }: any) => {
  const navigate = useNavigate();
  const params = useParams();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [list, setList] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    if (Object.keys(UrlParams).length === 0) {
      getSlaPolicyList(true);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(UrlParams).length > 0) {
      if (UrlParams.page_no) {
        setPage_no(parseInt(UrlParams.page_no));
      }
      if (UrlParams.limit) {
        setLimit(parseInt(UrlParams.limit));
      }
      getSlaPolicyList(true);
    }
  }, [window.location.href]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const getSlaPolicyList = async (status: boolean) => {
    try {
      setIsLoading(status);
      const payload = {
        page_size: getValue(UrlParams, `page_size`, "")
          ? getValue(UrlParams, `page_size`, "")
          : limit,
        page_no: getValue(UrlParams, `page_no`, "")
          ? getValue(UrlParams, `page_no`, "")
          : page_no,
      };
      const queryRequest = QueryRequestHelper(payload);
      const resp = await getAllSlaPolicy(queryRequest);
      if (resp) {
        setList(getValue(resp, "data.sla_policies", []));
        // setLimit(getValue(resp, "data.pagination.page_size", 0));
        setPage_no(getValue(resp, "data.pagination.page", 0));
        setTotalCount(getValue(resp, "data.pagination.total", 0));
      }
    } catch (error) {
      toast.error("Failed to load SLA Policies");
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdatePosition = async (id: string, seq_num: number) => {
    setIsLoading1(true);
    try {
      const combinedData = {
        swap_fields: [{ id, seq_num }],
      };
      const payload = JSON.parse(JSON.stringify(combinedData));
      const resp = await updateSlaPolicySeqNum(payload);

      if (resp) {
        toast.success("Position updated successfully");
        getSlaPolicyList(true);
      } else {
        toast.error(`Failed to update position`);
      }
    } catch (error) {
      toast.error(`Error updating position`);
    } finally {
      setIsLoading1(false);
    }
  };

  const handleDeleteModal = (id: string, name: string) => {
    Modal.confirm({
      title: `Are you sure you want to delete SLA Policy - ${name}?`,
      content: "Once deleted, this policy cannot be recovered.",
      okText: "Delete",
      cancelText: "Cancel",
      onOk: async () => {
        try {
          await deleteSlaPolicy(id);
          toast.success("SLA Policy deleted successfully");
          getSlaPolicyList(true);
        } catch (error) {
          toast.error("Error deleting Policy");
        }
      },
    });
  };

  const handleChangePagination = (page_no: any, limit: any) => {
    setPage_no(page_no);
    setLimit(limit);

    let payload = {
      ...UrlParams,
      page_no: page_no,
      page_size: limit,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
    setPage_no(page_no);
  };

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;

    const reorderedList = Array.from(list);
    const [removed] = reorderedList.splice(result.source.index, 1);
    reorderedList.splice(result.destination.index, 0, removed);

    setList(reorderedList);
    handleUpdatePosition(
      getValue(removed, "id", ""),
      result.destination.index + 1
    );
  };

  return (
    <MainLayout {...props}>
      <CompanyProfileSubHeader
        addChildren={
          <Button
            type="primary"
            size="large"
            icon={<Plus size={20} />}
            onClick={() =>
              navigate(
                `/${getValue(params, "orgId", "")}/settings/sla-policy/create`
              )
            }
          >
            New SLA Policy
          </Button>
        }
      />
      <CompanyProfileDetailsLayout classname="my-0">
        {isLoading ? (
          <ListLoader />
        ) : (
          <div className="sla-policy-wrapper">
            <div className="w-70 p-4 sla-policy-card-container">
              <h6 className="header_text__18 mb-2">SLA Policies</h6>
              <p className="w-70 small_text__14 pb-4">
                Service Level Agreement(SLA) policies help you setup and
                maintain targets for the duration within which your teams
                respond and resolve tickets.
              </p>
              <Alert
                type="info"
                showIcon
                message="
                The first matching SLA policy will be applied to tickets with
                matching conditions"
                className="w-fit-content"
              />
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="slaPolicies">
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className="d-grid gap-3 my-4"
                    >
                      {list.map((listItem, index) => {
                        const items: MenuProps["items"] = [
                          {
                            key: "1",
                            label: "Delete",
                            onClick: () =>
                              handleDeleteModal(
                                getValue(listItem, "id", ""),
                                getValue(listItem, "name", "")
                              ),
                            disabled: getValue(listItem, "is_default", ""),
                          },
                        ];

                        return (
                          <Draggable
                            key={getValue(listItem, "id", "")}
                            draggableId={getValue(listItem, "id", "")}
                            index={index}
                          >
                            {(provided: any) => (
                              <div
                                key={index}
                                className="border p-4 rounded-3 sla-policy-card bg-white"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <div className="d-flex justidy-content-between align-items-center gap-3">
                                  {list.length > 1 && (
                                    <div
                                      className="d-flex justify-content-center cursor-grabber"
                                      {...provided.dragHandleProps}
                                    >
                                      <DragIndicatorSvgComponent
                                        color={"#aeaeae"}
                                      />
                                    </div>
                                  )}
                                  <div
                                    className="cursor-pointer w-100"
                                    onClick={() =>
                                      navigate(
                                        `/${getValue(
                                          params,
                                          "orgId",
                                          ""
                                        )}/settings/sla-policy/edit/${getValue(
                                          listItem,
                                          "id",
                                          ""
                                        )}`
                                      )
                                    }
                                  >
                                    <h6 className="header_text__16 d-flex align-items-center gap-2  ">
                                      {index + 1}.{" "}
                                      {capitalizeFirstLetter(
                                        getValue(listItem, "name", "")
                                      )}
                                      {getValue(listItem, "is_default", "") && (
                                        <Tooltip title="Default SLA Policy">
                                          <LockKeyhole size={14} />
                                        </Tooltip>
                                      )}
                                    </h6>
                                    {getValue(listItem, "description", "") && (
                                      <p className="small_text__14 pb-1 pt-2">
                                        {getValue(listItem, "description", "")}
                                      </p>
                                    )}
                                    {/* <div className="d-flex align-items-center gap-3">
                          <p className="small_text__13">
                            {getValue(listItem, "time_zone", "")}
                          </p>
                          <Badge status="default" />
                          <p className="small_text__13">
                            {getValue(listItem, "groups.length", "")} groups
                            associated
                          </p>
                        </div> */}
                                  </div>
                                  <div className="d-flex align-items-center gap-3">
                                    <Switch
                                      size="small"
                                      value={getValue(
                                        listItem,
                                        "is_active",
                                        ""
                                      )}
                                      disabled={getValue(
                                        listItem,
                                        "is_default",
                                        ""
                                      )}
                                    />
                                    <Dropdown menu={{ items }}>
                                      <MoreVertical size={18} />
                                    </Dropdown>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              {totalCount > 10 && (
                <Pagination
                  align="center"
                  className="mt-4"
                  total={totalCount}
                  showTotal={(total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`
                  }
                  defaultPageSize={limit}
                  defaultCurrent={page_no}
                  onChange={handleChangePagination}
                />
              )}{" "}
            </div>
            <div className="w-30 p-4 sla-policy-right-details">
              <SettingsPageDetails data={slaPolicyDetails} />
            </div>
          </div>
        )}
      </CompanyProfileDetailsLayout>
      ;
    </MainLayout>
  );
};

export default SLAPolicy;
