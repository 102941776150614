import { getValue } from "@utils/lodash";
import React from "react";

function GraphHeaderPage(props: any) {
  return (
    <div className="border-bottom">
      <h6 className="header_text__16">
        {getValue(props, `graphInfo.name`, "")}
      </h6>
    </div>
  );
}

export default GraphHeaderPage;
