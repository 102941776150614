import "./SubHeader.scss";
import { Link } from "react-router-dom";
import BackSvgComponent from "@assets/svg/back-link";
import { useState } from "react";
import { ArrowDownWideNarrow } from "lucide-react";
import { Segmented, Tooltip } from "antd";
import { AppstoreOutlined, BarsOutlined } from "@ant-design/icons";
import SelectDropdown from "@components/custom/Dropdown/SelectDropdown";
import { getValue } from "@utils/lodash";
import {
  generatePipelinePayload,
  sortPipelineFieldList,
} from "@components/Pages/Pipeline/helpers/create-pipeline-helper";
import {
  createPipeline,
  getSpecificPipeline,
} from "@services/pipeline.service";
import { removeDuplicatesById } from "@components/helpers/request-helper";
import { snakeCase } from "lodash";
import { plural, singular } from "pluralize";

export default function TicketSubHeader({
  subHeaderListTitle,
  isRightArrowVisible,
  subMenuArray,
  children,
  options,
  handleOpenPopup,
  selectedPipeline,
  handleClickPipeline,
  isLoading,
  showBack,
  handleNavigateBack,
  moduleInfo,
  tourRef2,
  addButton,
  toggleSidebar,
  currentView,
  setCurrentView,
  hideView,
}: any) {
  const [pipelineLoading, setPipelineLoading] = useState(false);
  const handleAddPipeline = async (name: string) => {
    getPipeline(name);
  };

  const getPipeline = async (name: string) => {
    try {
      let resp = await getSpecificPipeline(
        getValue(moduleInfo, `id`, ""),
        getValue(options, `[${0}].id`, "")
      );
      if (resp) {
        let list = sortPipelineFieldList(
          getValue(resp, `data.form_fields`, [])
        );
        let formList =
          getValue(list, `length`, 0) > 0
            ? list.filter((item: object) =>
                getValue(item, `form_default`, false)
              )
            : [];

        const openStages = getValue(resp, `data.stages`, []).filter(
          (item: object) => getValue(item, `type`, "") === "OPEN"
        );
        const closedWonStages = getValue(resp, `data.stages`, []).filter(
          (item: object) => getValue(item, `type`, "") === "CLOSED_WON"
        );
        const closedLostStages = getValue(resp, `data.stages`, []).filter(
          (item: object) => getValue(item, `type`, "") === "CLOSED_LOST"
        );
        let obj = {
          name: name,
          singular_label: singular(snakeCase(name)),
          plural_label: plural(snakeCase(name)),
          openStages: openStages.map((item: object) => ({
            name: getValue(item, `api_name`, ""),
            label: getValue(item, `label`, ""),
            type: getValue(item, `type`, ""),
            color_code: getValue(item, `color_code`, ""),
          })),
          closedWonStages: closedWonStages.map((item: object) => ({
            name: getValue(item, `api_name`, ""),
            label: getValue(item, `label`, ""),
            type: getValue(item, `type`, ""),
            color_code: getValue(item, `color_code`, ""),
          })),
          closedLostStages: closedLostStages.map((item: object) => ({
            name: getValue(item, `api_name`, ""),
            label: getValue(item, `label`, ""),
            type: getValue(item, `type`, ""),
            color_code: getValue(item, `color_code`, ""),
          })),
          fields: removeDuplicatesById(formList),
        };
        handleCreatePipeline(obj);
      }
    } catch (error) {}
  };

  const handleCreatePipeline = async (obj: object) => {
    try {
      setPipelineLoading(false);
      const payload = generatePipelinePayload(obj, "");
      let resp = await createPipeline(getValue(moduleInfo, `id`, ""), payload);
      if (resp) {
        setPipelineLoading(false);
        handleClickPipeline(getValue(resp, `data`, {}));
      } else {
        setPipelineLoading(false);
      }
    } catch (error) {
      setPipelineLoading(false);
    }
  };

  return (
    <div className={`sub-header-wrapper`}>
      <div className="d-flex align-items-center justify-content-between w-100">
        <div className="d-flex align-items-center">
          {showBack && (
            <ul onClick={handleNavigateBack}>
              <li className={`gap d-flex align-items-center cursor-pointer`}>
                <BackSvgComponent />
              </li>
            </ul>
          )}
          <ul ref={tourRef2}>
            <li className={`gap d-flex align-items-center `}>
              <Tooltip title="Ticket View" placement="bottom">
                <ArrowDownWideNarrow
                  size={20}
                  color="#1a73e8"
                  className="cursor-pointer"
                  onClick={() => toggleSidebar()}
                />
              </Tooltip>
              <h6 className={`submenu-name ${showBack ? "ms-3" : ""}`}>
                {subHeaderListTitle}
              </h6>

              {isRightArrowVisible && (
                <img
                  src="/images/header/right-arrow.svg"
                  className="img-fluid"
                  alt="right-arrow"
                />
              )}
            </li>
          </ul>

          {subMenuArray?.length >= 0 && (
            <ul className={"header-wrapper__navigation-list"}>
              {subMenuArray.map((subMenuObj: any, index: any) => {
                return (
                  <li
                    className={"sub-header-wrapper__navigation-list-items"}
                    key={`subMenu-${index}`}
                  >
                    <Link
                      to={subMenuObj.linkUrl}
                      className={`sub-header-wrapper__sub-navigation-title ${
                        window.location.pathname === subMenuObj.linkUrl
                          ? `active`
                          : ""
                      }`}
                    >
                      {subMenuObj.linkText}
                    </Link>
                  </li>
                );
              })}
            </ul>
          )}

          <SelectDropdown
            placeholder={"Select Pipeline"}
            data={options}
            value={getValue(selectedPipeline, `id`, "")}
            selectKey={"id"}
            nameKey={"Pipeline"}
            onAdd={handleAddPipeline}
            isLoading={pipelineLoading}
            onSelect={handleClickPipeline}
          />

          {/* <Select
            style={{ width: 300 }}
            placeholder="custom dropdown render"
            dropdownRender={(options) => (
              <>
                {options}
                <Divider style={{ margin: "8px 0" }} />
                <Space style={{ padding: "0 8px 4px" }}>
                  <Button type="text" onClick={addItem} className="w-100">
                    Create pipeline
                  </Button>
                </Space>
              </>
            )}
            options={items.map((item) => ({ label: item, value: item }))}
          /> */}
        </div>
        {children}
        {!hideView && (
          <Segmented
            value={currentView}
            options={[
              {
                value: "Card",
                icon: (
                  <Tooltip title="Card View" placement="bottom">
                    <BarsOutlined />
                  </Tooltip>
                ),
              },
              {
                value: "Table",
                icon: (
                  <Tooltip title="Table View" placement="bottom">
                    <AppstoreOutlined />
                  </Tooltip>
                ),
              },
            ]}
            onChange={(value: any) => setCurrentView(value)}
          />
        )}
      </div>
      {addButton && addButton}
      <Tooltip id={`pipeline`} className="tooltip" />
    </div>
  );
}
