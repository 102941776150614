import { Table, Checkbox, Switch, Badge } from "antd";
import ListLoader from "@components/common/Loader";
import FiltersNoDataPage from "@components/common/NoData/filter-nodata";
import { getValue } from "@utils/lodash";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import HomeHeader from "@components/common/Header/HomeHeader/Header";
import CompanyProfileSubHeader from "../../CompanyProfile/components/CompanyProfileSubHeader";
import { capitalizeFirstLetter } from "@common/text-helpers";
import { LockFilled, LockOutlined } from "@ant-design/icons";

function RolesList(props: any) {
  const {
    loading,
    selectedIds,
    rolesList,
    handleChangeAllCheckbox,
    handleChangeCheckbox,
    updateUserStatus,
  } = props;
  const navigate = useNavigate();
  const params = useParams();

  const handleNavigate = (id: string) => {
    navigate(`/${getValue(params, `orgId`, "")}/settings/general/roles/${id}`);
  };

  const columns = [
    // {
    //   title: (
    //     <Checkbox
    //       indeterminate={
    //         getValue(selectedIds, `length`, 0) > 0 &&
    //         getValue(selectedIds, `length`, 0) !==
    //           getValue(rolesList, `length`, 0)
    //       }
    //       checked={
    //         getValue(selectedIds, `length`, 0) ===
    //         getValue(rolesList, `length`, 0)
    //       }
    //       onChange={() =>
    //         handleChangeAllCheckbox(
    //           getValue(selectedIds, `length`, 0) ===
    //             getValue(rolesList, `length`, 0)
    //             ? "all_checked"
    //             : "all_not_checked"
    //         )
    //       }
    //     />
    //   ),
    //   dataIndex: "checkbox",
    //   render: (_: any, record: any) => (
    //     <Checkbox
    //       checked={
    //         getValue(selectedIds, `length`, 0) > 0 &&
    //         selectedIds.includes(getValue(record, `id`, ""))
    //       }
    //       onChange={() => handleChangeCheckbox(getValue(record, `id`, ""))}
    //     />
    //   ),
    //   width: 70,
    // },
    {
      title: "Name",
      dataIndex: "title",
      render: (text: string, record: any) => (
        <span
          onClick={() => handleNavigate(getValue(record, `id`, ""))}
          className="header_blue_text__14 cursor-pointer"
        >
          {capitalizeFirstLetter(text)}
          {getValue(record, `is_sys_role`, false) && (
            <span className="ms-2">
              <LockOutlined style={{ color: "black", fontSize: "12px" }} />
            </span>
          )}
        </span>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (text: string, record: any) => (
        <span onClick={() => handleNavigate(getValue(record, `id`, ""))}>
          {text}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "is_active",
      // render: (is_active: any) => (
      //   <div className="d-flex align-items-center gap-2">
      //     <Badge status={is_active === true ? "success" : "error"} />
      //     {is_active === true ? "Active" : "Inactive"}
      //   </div>
      // ),
      render: (_: any, record: any) => (
        <Switch
          size="small"
          checked={getValue(record, `is_active`, false)}
          onChange={() =>
            updateUserStatus(
              getValue(record, `id`, ""),
              !getValue(record, `is_active`, false)
            )
          }
        />
      ),
    },
  ];

  return (
    <>
      <div className="mt-3 px-3">
        {loading ? (
          <ListLoader />
        ) : getValue(rolesList, `length`, 0) > 0 ? (
          <Table
            rowKey="id"
            columns={columns}
            dataSource={rolesList}
            pagination={false}
          />
        ) : (
          <FiltersNoDataPage module={"roles"} />
        )}
      </div>
    </>
  );
}

export default RolesList;
