import {
  capitalizeFirstLetter,
  formatString,
  formatType,
} from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import { Button } from "antd";
import { ArrowDownToLine } from "lucide-react";

const handleOpenFile = (fileUrl) => {
  window.open(fileUrl, "_blank");
};

const DocumentThumbnail = ({ fileUrl, item }) => {
  const mimeType = getValue(item, "mime_type", "");

  if (mimeType === "application/pdf") {
    return (
      // <iframe
      //   src={`https://docs.google.com/viewer?url=${fileUrl}&embedded=true`}
      //   title="PDF Viewer"
      //   width="100%"
      //   height="200px"
      //   style={{
      //     border: "none",
      //     overflow: "hidden",
      //   }}
      // />
      <div>
        <div className="d-flex align-items-center gap-2 p-3 bg-light rounded-3 justify-content-between">
          <div className="d-flex align-items-center gap-2">
            <img
              src={`/images/icons/pdf_file.png`}
              alt="File"
              width={26}
              height={26}
            />
            <div>
              <p>{capitalizeFirstLetter(getValue(item, "filename", ""))}</p>
              <p className="small_text__12 pt-1">
                {formatType(getValue(item, "extension", ""))}
              </p>
            </div>
          </div>
          <ArrowDownToLine
            onClick={() => handleOpenFile(fileUrl)}
            size={26}
            className="cursor-pointer ms-3 border p-1"
            color="gray"
            style={{
              borderRadius: "50%",
            }}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div className="d-flex align-items-center gap-2 p-3 bg-light rounded-3 justify-content-between">
          <div className="d-flex align-items-center gap-2">
            <img
              src={`/images/icons/${
                mimeType === "image/jpeg"
                  ? "image_file.png"
                  : mimeType === "video/mp4"
                  ? "video-file.png"
                  : "file.png"
              }`}
              alt="File"
              width={26}
              height={26}
            />
            <div>
              <p>{capitalizeFirstLetter(getValue(item, "filename", ""))}</p>
              <p className="small_text__12 pt-1">
                {formatType(getValue(item, "extension", ""))}
              </p>
            </div>
          </div>
          <ArrowDownToLine
            onClick={() => handleOpenFile(fileUrl)}
            size={26}
            className="cursor-pointer ms-3 border p-1"
            color="gray"
            style={{
              borderRadius: "50%",
            }}
          />
        </div>
      </div>
    );
  }
};

export default DocumentThumbnail;
