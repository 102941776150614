import "./nodata.scss";
function CannedResponseNoDataPage(props: any) {
  return (
    <div className="n_container">
      <div className="nodata_container">
        <div className="d-flex justify-content-between align-items-center">
          <div className="nodata_container_main">
            <h6 className="nodata_container_header">
              Create your first canned response
            </h6>
            <p className="nodata_container_desc mt-2">
              Create canned responses to help your agents respond faster.
            </p>
          </div>
          <div className="w-10"></div>
          <img
            src={"/nodata/calls.svg"}
            alt="SVG"
            className="nodata_container_image"
          />
        </div>
      </div>
    </div>
  );
}

export default CannedResponseNoDataPage;
