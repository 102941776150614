import { EditOutlined } from "@ant-design/icons";
import { getValue } from "@utils/lodash";
import { Button } from "antd";
import React, { useState } from "react";

const FormDescription = ({
  request,
  input,
  textClassname,
  inputClassname,
}: any) => {
  const [showDescription, setShowDescription] = useState(true);

  return showDescription ? (
    <div className={`${textClassname ? textClassname : "mt-3"} d-flex gap-3`}>
      {getValue(request, `description`, "") ? (
        getValue(request, `description`, "")
      ) : (
        <span
          className="header_blue_text__14 cursor-pointer"
          onClick={() => setShowDescription(false)}
        >
          Add description
        </span>
      )}
      <EditOutlined onClick={() => setShowDescription(false)} />
    </div>
  ) : (
    <div className={`${inputClassname ? inputClassname : "my-2"}`}>
      {input}

      <Button onClick={() => setShowDescription(true)}>Close</Button>
    </div>
  );
};

export default FormDescription;
