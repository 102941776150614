import { getValue } from "@utils/lodash";
import React from "react";

function DrawerInfoPage(props: any) {
  const { index, item } = props;
  const renderData = (module: string) => {
    switch (module) {
      case "leads":
      case "contacts":
        return (
          <div>
            <div
              key={index}
              className="d-flex align-items-center p-2 cursor-pointer"
            >
              <p className="small_text__16">{index + 1}.</p>
              <p className="small_text__16">
                {getValue(item, `first_name`, "") ||
                getValue(item, `last_name`, "")
                  ? getValue(item, `first_name`, "") +
                    " " +
                    getValue(item, `last_name`, "")
                  : "Unknown"}
              </p>
            </div>
            <p className="small_text__12 ms-3">{getValue(item, `email`, "")}</p>
          </div>
        );

      case "companies":
      case "deals":
        return (
          <div
            key={index}
            className="d-flex align-items-center p-2 cursor-pointer"
          >
            <p className="small_text__16">{index + 1}.</p>
            <p className="small_text__16">
              {getValue(item, `name`, "")
                ? getValue(item, `name`, "")
                : "Unknown"}
            </p>
          </div>
        );

      default:
        return (
          <div
            key={index}
            className="d-flex align-items-center p-2 cursor-pointer"
          >
            <p className="small_text__16">{index + 1}.</p>
            <p className="small_text__16">
              {getValue(item, `first_name`, "") ||
              getValue(item, `last_name`, "")
                ? getValue(item, `first_name`, "") +
                  " " +
                  getValue(item, `last_name`, "")
                : getValue(item, `name`, "")
                ? getValue(item, `name`, "")
                : "Unknown"}
            </p>
          </div>
        );
    }
  };
  return renderData(getValue(props, `module`, ""));
}

export default DrawerInfoPage;
