import AdsPage from "@pages/Private/Settings/Ads";
import AdLeadInfoDetailPage from "@pages/Private/Settings/Ads/AdLeadInfoDetail";
import AdsInfo from "@pages/Private/Settings/Ads/AdsInfo";
import AdsLeadInfo from "@pages/Private/Settings/Ads/AdsLeadInfo";
import MessagePages from "@pages/Private/Settings/Messages";

export const SettingsAdsRoutes = [
  {
    path: "/:orgId/settings/chat/messages",
    name: "settings_channels_whatsapp",
    component: MessagePages,
  },
  {
    path: "/:orgId/settings/crm/ads",
    name: "settings_lead_capture_ad_leads",
    component: AdsPage,
  },
  {
    path: "/:orgId/settings/crm/ads/:id",
    name: "settings_lead_capture_ad_leads",
    component: AdsInfo,
  },
  {
    path: "/:orgId/settings/crm/ads/lead-forms/:id",
    name: "settings_lead_capture_ad_leads",
    component: AdsLeadInfo,
  },
  {
    path: "/:orgId/settings/crm/ads/lead-forms/:id/add",
    name: "settings_lead_capture_ad_leads",
    component: AdLeadInfoDetailPage,
  },
  {
    path: "/:orgId/settings/crm/ads/lead-forms/:id/edit/:formId",
    name: "settings_lead_capture_ad_leads",
    component: AdLeadInfoDetailPage,
  },
];
