import { Link, useLocation, useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import { useStateContext } from "context/profileProvider";
import SignInDropdownOrg from "./SignInDropdownOrg";
import OrganizationListPopup from "./OrgListPopup";
import ArrowRightSvgComponent from "assets/svg/arrow-right";
import { useState } from "react";
import NotificationCenter from "@common/notification-center";
import { concatString } from "@common/text-helpers";
import TooltipOnHover from "@components/common/Tooltip/tooltip-on-hover";
import "./SignInDropdown.scss";
import { getFirstLetterOfWord } from "@common/text-helpers";
import { Modal } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import InfoSvgComponent from "@assets/svg/info";
import { usePermissionContext } from "@context/permissionContext";
import CompanyNavigationList from "@layouts/SettingsLayout/left-menu-tabs";

export default function HeaderSignin(props: any) {
  const { setOpenTour } = props;
  const params = useParams();
  const location = useLocation();
  const { orgInfo, userId, profileInfo } = useStateContext();
  const [clicked, setClicked] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const togglePopupVisible = () => {
    setIsPopupVisible(!isPopupVisible);
  };
  const [openModal, setOpenModal] = useState(false);
  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const { userInfo } = useStateContext();
  const { tabPermissionList } = usePermissionContext();
  let navigationItems = CompanyNavigationList();

  return (
    <div>
      <ul className="d-flex align-items-center">
        {/* <li>
          <Link to="#0">
            <img
              src="/images/header/image-3.svg"
              className="img-fluid cursor-pointer"
            />
          </Link>
        </li> */}
        {/* {getValue(params)} */}
        {location.pathname ===
          `/${getValue(params, "orgId", "")}/crm/contacts` && (
          <li>
            <div className="cursor-pointer" onClick={() => setOpenTour(true)}>
              <InfoSvgComponent color="#fff" />
            </div>
          </li>
        )}

        {tabPermissionList.includes("settings") && (
          <li className="px-2">
            <Link
              to={
                getValue(navigationItems, `[${0}].linkArray[${0}].linkUrl`, "")
                  ? getValue(
                      navigationItems,
                      `[${0}].linkArray[${0}].linkUrl`,
                      ""
                    )
                  : `/${getValue(
                      params,
                      `orgId`,
                      ""
                    )}/settings/general/company-profile`
              }
            >
              {/* <SettingsSvgComponents size={28} /> */}
              <SettingOutlined style={{ color: "white", fontSize: "22px" }} />
            </Link>
          </li>
        )}
        <li>
          <NotificationCenter userId={userId} />
        </li>
        <li
          className="vr"
          style={{ marginLeft: "5px", marginRight: "10px" }}
        ></li>
        <li>
          <OrganizationListPopup
            header={
              <TooltipOnHover
                header={
                  <div className="d-flex align-items-center">
                    <p className="org_name">
                      {concatString(getValue(orgInfo, `name`, ""), 18)}
                    </p>
                    <ArrowRightSvgComponent size={24} color={"white"} />
                  </div>
                }
                text={getValue(orgInfo, `name`, "")}
              />
              // <div className="d-flex align-items-center">
              //   <p className="org_name">{concatString(getValue(orgInfo, `name`, ""),18)}</p>
              //   <ArrowRightSvgComponent size={24} color={"white"} />
              // </div>
            }
            clicked={clicked}
            setClicked={setClicked}
            isPopupVisible={isPopupVisible}
          />
        </li>
        <li className="vr mx-1"></li>
        <li onClick={togglePopupVisible}>
          <div className={"signin"}>
            <span className={"signin-name"}>
              {getFirstLetterOfWord(getValue(userInfo, `first_name`, ""))}
            </span>
          </div>
        </li>
      </ul>
      <SignInDropdownOrg
        isPopupVisible={isPopupVisible}
        setIsPopupVisible={setIsPopupVisible}
        togglePopupVisible={togglePopupVisible}
        openModal={openModal}
        toggleModal={toggleModal}
        // navigateToProfile={navigateToProfile}
      />
      <Modal
        open={openModal}
        onCancel={toggleModal}
        onOk={toggleModal}
        footer={
          [
            // <Button key="cancel" onClick={toggleModal} className="cancel__button">
            //   Cancel
            // </Button>,
          ]
        }
        className="app-download-modal p-5"
      >
        <section className="">
          <div className="">
            <div className="m-3 ">
              <h5 className="header_text__18">
                Scan the QR Code to start working on mobile
              </h5>
              <p className="small_text__14">
                Empower field agents on the go to deliver better, faster
                service.
              </p>
            </div>
            {/* <ButtonComponent
              buttonType={"delete"}
              buttonText={"Close"}
              onClickHandler={toggleModal}
            /> */}
          </div>
        </section>
      </Modal>
    </div>
  );
}
