import LabelSvgComponent from "@assets/svg/label";
import { camelToSnake } from "@common/text-helpers";
import TextHeaderTooltip from "@components/custom/Dropdown/TextHeaderTooltip";
import { getValue } from "@utils/lodash";
import React from "react";

const SheetDropdownSelector = React.memo(
  ({
    row,
    col,
    findStageColor,
    stageList,
    handleDisplayItem,
    appendCustomData,
    handleChangeStage,
  }: any) => {
    return (
      <div
        style={{
          color: findStageColor(
            camelToSnake(handleDisplayItem(col, appendCustomData(row)))
          ),
        }}
        className="stage_color mx-1 d-flex align-items-center px-2"
        onClick={(e) => e.stopPropagation()}
      >
        <LabelSvgComponent
          color={findStageColor(
            camelToSnake(handleDisplayItem(col, appendCustomData(row)))
          )}
          size={18}
        />
        <TextHeaderTooltip
          data={stageList}
          label={`label`}
          onChange={(e: any) =>
            handleChangeStage(getValue(e, `id`, ""), getValue(row, `id`, ""))
          }
          color={findStageColor(
            camelToSnake(handleDisplayItem(col, appendCustomData(row)))
          )}
          value={handleDisplayItem(col, appendCustomData(row))}
          selectKey={"id"}
          editInline
          showCheveronDown
        />
      </div>
    );
  }
);

export default SheetDropdownSelector;
