import { QueryRequestHelper } from "@common/query-request-helper";
import "./CompanyProfile.scss";
import { getValue } from "@utils/lodash";
import { Button, Radio } from "antd";
import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { usePermissionContext } from "@context/permissionContext";
import {
  AINavigationList,
  CRMNavigationList,
  ChatNavigationList,
  GeneralNavigationList,
  HelpdestNavigationList,
  filterNavigationListNew,
} from "../../helpers/settings-permissions";

export default function CompanyProfileSubHeader(props: any) {
  const {
    isLoading,
    handleSubmit,
    submitText,
    submitIcon,
    showCancel,
    cancelText,
    handleCancel,
    addChildren,
    disabled,
  } = props;

  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { tabPermissionList, subTabPermissionList } = usePermissionContext();

  const determineDefaultPath = () => {
    if (location.pathname.includes("crm")) return "crm";
    if (location.pathname.includes("chat")) return "chat";
    return "general";
  };

  const [defaultPath, setDefaultPath] = useState<string>(
    determineDefaultPath()
  );

  useEffect(() => {
    setDefaultPath(determineDefaultPath());
  }, [location.pathname]);

  const onChange = (e: any) => {
    setDefaultPath(e.target.value);
    const routeList = getFun(e.target.value);
    navigate(`${getValue(routeList, `[${0}].linkArray[${0}].linkUrl`, "")}`);
  };

  const getFun = (route: string) => {
    switch (route) {
      case "general":
        return filterNavigationListNew(
          tabPermissionList,
          subTabPermissionList,
          GeneralNavigationList(params)
        );
      case "crm":
        return filterNavigationListNew(
          tabPermissionList,
          subTabPermissionList,
          CRMNavigationList(params)
        );
      case "helpdesk":
        return filterNavigationListNew(
          tabPermissionList,
          subTabPermissionList,
          HelpdestNavigationList(params)
        );
      case "chat":
        return filterNavigationListNew(
          tabPermissionList,
          subTabPermissionList,
          ChatNavigationList(params)
        );
      case "ai":
        return filterNavigationListNew(
          tabPermissionList,
          subTabPermissionList,
          AINavigationList(params)
        );
      default:
        return filterNavigationListNew(
          tabPermissionList,
          subTabPermissionList,
          GeneralNavigationList(params)
        );
    }
  };

  return (
    <div className="company-profile-subheader-wrapper">
      <div className="d-flex align-items-center justify-content-between w-100">
        <Radio.Group
          block
          buttonStyle="solid"
          value={defaultPath}
          onChange={onChange}
        >
          {getValue(getFun("general"), `length`, 0) > 0 && (
            <Radio.Button value="general">General</Radio.Button>
          )}
          {getValue(getFun("crm"), `length`, 0) > 0 && (
            <Radio.Button value="crm">CRM</Radio.Button>
          )}
          {getValue(getFun("chat"), `length`, 0) > 0 && (
            <Radio.Button value="chat">Chat</Radio.Button>
          )}
        </Radio.Group>
        <div className="d-flex align-items-center gap-2">
          {showCancel && (
            <Button onClick={handleCancel} size="large">
              {cancelText || "Cancel"}
            </Button>
          )}
          {props.showSubmit && (
            <Button
              size="large"
              type="primary"
              icon={submitIcon}
              onClick={handleSubmit}
              loading={isLoading}
              disabled={disabled}
            >
              {submitText || "Save"}
            </Button>
          )}
        </div>
        {props.addWorkflow && (
          <Button size="large" type="primary" onClick={() => props.toggle()}>
            Create Workflow
          </Button>
        )}
        {props.addForm && (
          <Button size="large" type="primary" onClick={() => props.toggle()}>
            Create Form
          </Button>
        )}
        {props.addRole && (
          <Button size="large" type="primary" onClick={() => props.toggle()}>
            Create Role
          </Button>
        )}
        {addChildren}
      </div>
    </div>
  );
}
