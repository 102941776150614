import * as React from "react";
import { Button, Modal } from "antd";
import { getValue } from "@utils/lodash";
import { useNavigate } from "react-router-dom";

const SharedDataPopup = (props: any) => {
  const navigate = useNavigate();
  return (
    <Modal
      open={getValue(props, `isOpen`, "")}
      // toggle={props.handleModal}
      onOk={props.handleSubmit}
      onCancel={props.handleModal}
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            props.handleModal();
          }}
          className="cancel__button"
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          // onClick={props.handleSubmit}
          onClick={() => {
            props.handleModal();
          }}
          className="submit__button"
          disabled={getValue(props, `isLoading`, false)}
        >
          {getValue(props, `isLoading`, false) ? "Please wait..." : "Yes"}
        </Button>,
      ]}
    >
      <section className="m-2">
        <div className="">
          <div className="">
            {/* <img src="/images/info-delete.png"  className="delete-icon"/> */}
            <p className=" small_text__22 delete_text mb-4">
              Confirm Duplication{" "}
            </p>
            <p className="small_text__15">
              <span>
                Need to bulk import contacts and integrate them into your leads?
              </span>
            </p>
          </div>
        </div>
      </section>
    </Modal>
  );
};

export default SharedDataPopup;
