import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllSlaPolicy = (queryRequest: string) =>
  get(`${config.API_URL}/sla-policy?${queryRequest}`);

export const getSpecificSlaPolicy = (id: string) =>
  get(`${config.API_URL}/sla-policy/${id}`);

export const getSlaPolicyConditionFields = (queryRequest: string) =>
  get(`${config.API_URL}/sla-policy/condition-fields?${queryRequest}`);

export const getListOfModules = (url: string) =>
  get(`${config.API_URL}/${url}`);

export const createSlaPolicy = (payload: object) =>
  post(`${config.API_URL}/sla-policy`, payload);

export const updateSlaPolicy = (id: string, payload: object) =>
  patch(`${config.API_URL}/sla-policy/${id}`, payload);

export const updateSlaPolicySeqNum = (payload: object) =>
  patch(`${config.API_URL}/sla-policy/seq-num/swap`, payload);

export const deleteSlaPolicy = (id: string) =>
  Delete(`${config.API_URL}/sla-policy/${id}`);
