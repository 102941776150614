import { getValue } from "@utils/lodash";
import React from "react";

const SheetTextAreaEditor = React.memo(
  ({ col, row, fieldCol, handleDisplayItem, handleInputChange }: any) => {
    return (
      <textarea
        value={handleDisplayItem(col, row)}
        onChange={(e) =>
          handleInputChange(
            getValue(row, "id", ""),
            col,
            e.target.value,
            fieldCol
          )
        }
        className="sheet-view-textarea p-1"
      />
    );
  }
);

export default SheetTextAreaEditor;
