import {
  removeEmptyValuesInName,
  sortJSONObjectArray,
} from "@common/text-helpers";
import { getListOfAllCompanies } from "@services/companies.service";
import { getListOfAllContacts } from "@services/contacts.service";
import { getListAllTemplates } from "@services/email-config.service";
import { getWhatsappTemplate } from "@services/fb.service";
import { getPipelineStages } from "@services/pipeline.service";
import { getAllTags, getAllTagsList } from "@services/tags.service";
import { getUsers, getUsersEmail } from "@services/users.service";
import { getValue } from "@utils/lodash";
import { QueryRequestHelper } from "common/query-request-helper";

export const usersSearchableDropdown = async (queryRequest: string) => {
  try {
    let resp = await getUsers(queryRequest);
    if (resp) {
      let data =
        getValue(resp, `data.length`, 0) > 0
          ? getValue(resp, `data`, []).map((item: object) => ({
              id: getValue(item, `id`, ""),
              name: removeEmptyValuesInName(
                getValue(item, `first_name`, ""),
                getValue(item, `last_name`, "")
              ),
              value: removeEmptyValuesInName(
                getValue(item, `first_name`, ""),
                getValue(item, `last_name`, "")
              ),
              label: removeEmptyValuesInName(
                getValue(item, `first_name`, ""),
                getValue(item, `last_name`, "")
              ),
            }))
          : [];
      return data;
    }
  } catch (error) {}
};

export const usersEmailSearchableDropdown = async (queryRequest: string) => {
  try {
    let resp = await getUsersEmail(queryRequest);
    if (resp) {
      let data =
        getValue(resp, `data.length`, 0) > 0
          ? getValue(resp, `data`, []).map((item: object) => ({
              id: getValue(item, `email`, ""),
              main_id: getValue(item, `id`, ""),
              name: removeEmptyValuesInName(
                getValue(item, `first_name`, ""),
                getValue(item, `last_name`, "")
              ),
              value: removeEmptyValuesInName(
                getValue(item, `first_name`, ""),
                getValue(item, `last_name`, "")
              ),
              label:
                removeEmptyValuesInName(
                  getValue(item, `first_name`, ""),
                  getValue(item, `last_name`, "")
                ) + ` ( ${getValue(item, `email`, "")} )`,
            }))
          : [];
      return data;
    }
  } catch (error) {}
};

export const contactsSearchableDropdown = async (search_text: string) => {
  let paylod = {
    search_text: search_text,
  };
  let queryRequest = QueryRequestHelper(paylod);
  try {
    let resp = await getListOfAllContacts(queryRequest);
    if (resp) {
      let data =
        getValue(resp, `data.length`, 0) > 0
          ? getValue(resp, `data`, []).map((item: object) => ({
              id: getValue(item, `id`, ""),
              name: removeEmptyValuesInName(
                getValue(item, `first_name`, ""),
                getValue(item, `last_name`, "")
              ),
              value: removeEmptyValuesInName(
                getValue(item, `first_name`, ""),
                getValue(item, `last_name`, "")
              ),
              label: removeEmptyValuesInName(
                getValue(item, `first_name`, ""),
                getValue(item, `last_name`, "")
              ),
            }))
          : [];
      return data;
    }
  } catch (error) {}
};

export const companiesSearchableDropdown = async (search_text: string) => {
  let paylod = {
    search_text: search_text,
  };
  let queryRequest = QueryRequestHelper(paylod);
  try {
    let resp = await getListOfAllCompanies(queryRequest);
    if (resp) {
      let data =
        getValue(resp, `data.length`, 0) > 0
          ? getValue(resp, `data`, []).map((item: object) => ({
              id: getValue(item, `id`, ""),
              name: getValue(item, `name`, ""),
              value: getValue(item, `name`, ""),
              label: getValue(item, `name`, ""),
            }))
          : [];
      return data;
    }
  } catch (error) {}
};

export const emailTemplatesSearchableDropdown = async (
  queryRequest: string
) => {
  // let paylod = {
  //   search_text: search_text,
  // };
  // let queryRequest = QueryRequestHelper(paylod);
  try {
    let resp = await getListAllTemplates(queryRequest);
    const combinedTemplates = getValue(resp, `data`, []).flatMap(
      ({ forModule, templates }: any) => {
        return templates.map((template: any) => ({
          forModule,
          ...template,
          value: getValue(template, `id`, ""),
          label: `${getValue(template, `name`, "")}`,
        }));
      }
    );
    if (resp) {
      // let data =
      //   getValue(resp, `data.length`, 0) > 0
      //     ? getValue(resp, `data`, []).map((item: object) => ({
      //         id: getValue(item, `id`, ""),
      //         name: getValue(item, `name`, ""),
      //         value: getValue(item, `name`, ""),
      //         label: getValue(item, `name`, ""),
      //       }))
      //     : [];
      return combinedTemplates;
    }
  } catch (error) {}
};

export const stageSearchableDropdown = async (
  value: string,
  search_text: string
) => {
  let paylod = {
    search_text: search_text,
  };
  let queryRequest = QueryRequestHelper(paylod);
  let req = value.split("_");
  try {
    let resp = await getPipelineStages(req[1], req[2]);
    if (resp) {
      let data =
        getValue(resp, `data.length`, 0) > 0
          ? sortJSONObjectArray(getValue(resp, `data`, []), "seq_num").map(
              (item: object) => ({
                id: getValue(item, `id`, ""),
                name: getValue(item, `api_name`, ""),
                value: getValue(item, `id`, ""),
                label: getValue(item, `label`, ""),
              })
            )
          : [];
      return data;
    }
  } catch (error) {}
};

export const tagsSearchableDropdown = async (queryRequest: string) => {
  // let paylod = {
  //   search_text: search_text,
  // };
  // let queryRequest = QueryRequestHelper(paylod);
  let option = queryRequest.split("&");
  let id = getValue(option, `[${0}]`, "")
    ? getValue(option, `[${0}]`, "").split("module_id=")[1]
    : "";
  try {
    let resp = await getAllTagsList(id, "");
    if (resp) {
      let data =
        getValue(resp, `data.length`, 0) > 0
          ? getValue(resp, `data`, []).map((item: object) => ({
              id: getValue(item, `value`, ""),
              name: getValue(item, `value`, ""),
              value: getValue(item, `id`, ""),
              label: getValue(item, `label`, ""),
            }))
          : [];
      return data;
    }
  } catch (error) {}
};

export const whatsappTemplatesSearchableDropdown = async (
  queryRequest1: string
) => {
  try {
    let payload = {
      status: "APPROVED",
    };
    let queryRequest = QueryRequestHelper(payload);
    let resp = await getWhatsappTemplate(`${queryRequest1}&${queryRequest}`);
    const combinedTemplates = getValue(resp, `data.templates`, []).map(
      (template: any) => {
        return {
          ...template,
          value: getValue(template, `id`, ""),
          label: `${getValue(template, `name`, "")}`,
        };
      }
    );
    if (resp) {
      return combinedTemplates;
    }
  } catch (error) {}
};

// export const productsSearchableDropdown = async (search_text: string) => {
//   let paylod = {
//     search_text: search_text,
//   };
//   let queryRequest = QueryRequestHelper(paylod);
//   try {
//     let resp = await getListOfAllContacts(queryRequest);
//     if (resp) {
//       let data =
//         getValue(resp, `data.length`, 0) > 0
//           ? getValue(resp, `data`, []).map((item: object) => ({
//               id: getValue(item, `id`, ""),
//               name: removeEmptyValuesInName(
//                 getValue(item, `first_name`, ""),
//                 getValue(item, `last_name`, "")
//               ),
//               value: removeEmptyValuesInName(
//                 getValue(item, `first_name`, ""),
//                 getValue(item, `last_name`, "")
//               ),
//               label: removeEmptyValuesInName(
//                 getValue(item, `first_name`, ""),
//                 getValue(item, `last_name`, "")
//               ),
//             }))
//           : [];
//       return data;
//     }
//   } catch (error) {}
// };
