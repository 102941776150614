import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllBusinessHours = (queryRequest: string) =>
  get(`${config.API_URL}/business-hours?${queryRequest}`);

export const getSpecificBusinessHour = (id: string) =>
  get(`${config.API_URL}/business-hours/${id}`);

export const createBusinessHour = (payload: object) =>
  post(`${config.API_URL}/business-hours`, payload);

export const updateBusinessHour = (id: string, payload: object) =>
  patch(`${config.API_URL}/business-hours/${id}`, payload);

export const deleteBusinessHour = (id: string) =>
  Delete(`${config.API_URL}/business-hours/${id}`);
