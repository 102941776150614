import BusinessHours from "@pages/Private/Settings/BusinessHours";
import CreateBusinessHour from "@pages/Private/Settings/BusinessHours/CreateBusinessHour";

export const SettingsBusinessHoursRoutes = [
  {
    path: "/:orgId/settings/business-hours",
    name: "settings_templates_business_hours",
    component: BusinessHours,
  },
  {
    path: "/:orgId/settings/business-hours/create",
    name: "settings_templates_business_hours",
    component: CreateBusinessHour,
  },
  {
    path: "/:orgId/settings/business-hours/edit/:id",
    name: "settings_templates_business_hours",
    component: CreateBusinessHour,
  },
];
