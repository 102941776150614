import { convertCurrentDateWithTime } from "@common/date-helpers";
import { capitalizeEverything } from "@common/text-helpers";
import BroadCastNoDataPage from "@components/common/NoData/broadcast-nodata";
import { getValue } from "@utils/lodash";
import { Table, Tag, Dropdown, Button, Menu } from "antd";
import { Link, useParams } from "react-router-dom";
import { MoreOutlined } from "@ant-design/icons";
import { allParams } from "@common/url-params-helper";

function BroadcastHistoryTable(props: any) {
  const {
    list,
    handleChangePagination,
    page_no,
    page_size,
    totalCount,
    getSpecificBrodcast,
    stopBroadcast,
    hideMore,
    handleSubmitEdit,
  } = props;
  const params = useParams();

  // const handleMenuClick = (action: string, item: any) => {
  //   if (action === "stop") {
  //     // Handle stop action
  //     console.log("Stopping broadcast for", item);
  //   } else if (action === "edit") {
  //     // Handle edit action
  //     console.log("Editing broadcast for", item);
  //   }
  // };

  const moreOptionsMenu = (item: any) => {
    return (
      <Menu>
        <Menu.Item
          key="stop"
          onClick={() => stopBroadcast(getValue(item, `id`, ""))}
        >
          Delete Scheduled Broadcast
        </Menu.Item>
        <Menu.Item
          key="edit"
          onClick={() => handleSubmitEdit(getValue(item, `id`, ""))}
        >
          Edit Scheduled Time
        </Menu.Item>
      </Menu>
    );
  };

  const broadcastColumn: any = [
    {
      title: "BROADCAST NAME",
      dataIndex: "name",
      key: "name",
      width: "200px",
      render: (text: any, item: any) => (
        <Link
          to={`/${getValue(params, "orgId", "")}/broadcast/${
            item.id
          }?${allParams()}`}
        >
          {text}
        </Link>
      ),
    },
    {
      title: "SUCCESSFUL",
      dataIndex: "overview.overview.delivered.count",
      key: "overview.overview.delivered.count",
      width: "100px",
      render: (record: any, item: any) => {
        return getValue(item, "overview.overview.delivered.count", 0);
      },
    },
    {
      title: "SENT",
      dataIndex: "overview.overview.sent.count",
      key: "overview.overview.sent.count",
      width: "100px",
      render: (record: any, item: any) => {
        return getValue(item, "overview.overview.sent.count", 0);
      },
    },
    {
      title: "READ",
      dataIndex: "overview.overview.read.count",
      key: "overview.overview.read.count",
      width: "100px",
      render: (record: any, item: any) => {
        return getValue(item, "overview.overview.read.count", 0);
      },
    },
    {
      title: "REPLIED",
      dataIndex: "overview.overview.replied.count",
      key: "overview.overview.replied.count",
      width: "100px",
      render: (record: any, item: any) => {
        return getValue(item, "overview.overview.replied.count", 0);
      },
    },
    {
      title: "FAILED",
      dataIndex: "overview.overview.failed.count",
      key: "overview.overview.failed.count",
      width: "100px",
      render: (record: any, item: any) => {
        return getValue(item, "overview.overview.failed.count", 0);
      },
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      width: "200px",
      render: (status: any) => {
        let color;
        switch (status) {
          case "processed":
            color = "green";
            break;
          case "finished":
            color = "green";
            break;
          case "failed":
            color = "red";
            break;
          case "processing":
            color = "blue";
            break;
          case "queued":
            color = "orange";
            break;
          case "pending":
            color = "blue";
            break;
          case "contacts_processing":
            color = "blue";
            break;
          case "contacts_processed":
            color = "blue";
            break;
          default:
            color = "default";
        }

        return (
          <Tag color={color} key={status}>
            {capitalizeEverything(
              status === "processed" ? "completed" : status
            )}
          </Tag>
        );
      },
    },
    {
      title: "TRIGGERED ON",
      dataIndex: "trigger_at",
      key: "trigger_at",
      width: "200px",
      render: (trigger_at: any) => convertCurrentDateWithTime(trigger_at),
    },
    {
      title: "CREATED ON",
      dataIndex: "created_at",
      key: "created_at",
      width: "200px",
      render: (created_at: any) => convertCurrentDateWithTime(created_at),
    },
  ];

  const broadcastColumnWithMore: any = [
    {
      title: "BROADCAST NAME",
      dataIndex: "name",
      key: "name",
      width: "200px",
      render: (text: any, item: any) => (
        <Link to={`/${getValue(params, "orgId", "")}/broadcast/${item.id}`}>
          {text}
        </Link>
      ),
    },
    {
      title: "SUCCESSFUL",
      dataIndex: "overview.overview.delivered.count",
      key: "overview.overview.delivered.count",
      width: "100px",
      render: (record: any, item: any) => {
        return getValue(item, "overview.overview.delivered.count", 0);
      },
    },
    {
      title: "SENT",
      dataIndex: "overview.overview.sent.count",
      key: "overview.overview.sent.count",
      width: "100px",
      render: (record: any, item: any) => {
        return getValue(item, "overview.overview.sent.count", 0);
      },
    },
    {
      title: "READ",
      dataIndex: "overview.overview.read.count",
      key: "overview.overview.read.count",
      width: "100px",
      render: (record: any, item: any) => {
        return getValue(item, "overview.overview.read.count", 0);
      },
    },
    {
      title: "REPLIED",
      dataIndex: "overview.overview.replied.count",
      key: "overview.overview.replied.count",
      width: "100px",
      render: (record: any, item: any) => {
        return getValue(item, "overview.overview.replied.count", 0);
      },
    },
    {
      title: "FAILED",
      dataIndex: "overview.overview.failed.count",
      key: "overview.overview.failed.count",
      width: "100px",
      render: (record: any, item: any) => {
        return getValue(item, "overview.overview.failed.count", 0);
      },
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      width: "200px",
      render: (status: any) => {
        let color;
        switch (status) {
          case "processed":
            color = "green";
            break;
          case "finished":
            color = "green";
            break;
          case "failed":
            color = "red";
            break;
          case "processing":
            color = "blue";
            break;
          case "queued":
            color = "orange";
            break;
          case "pending":
            color = "blue";
            break;
          case "contacts_processing":
            color = "blue";
            break;
          case "contacts_processed":
            color = "blue";
            break;
          default:
            color = "default";
        }

        return (
          <Tag color={color} key={status}>
            {capitalizeEverything(status == "processed" ? "completed" : status)}
          </Tag>
        );
      },
    },
    {
      title: "TRIGGERED ON",
      dataIndex: "trigger_at",
      key: "trigger_at",
      width: "200px",
      render: (trigger_at: any) => convertCurrentDateWithTime(trigger_at),
    },
    {
      title: "CREATED ON",
      dataIndex: "created_at",
      key: "created_at",
      width: "200px",
      render: (created_at: any) => convertCurrentDateWithTime(created_at),
    },
    {
      title: "More",
      key: "actions",
      width: "50px",
      render: (text: any, item: any) => {
        if (shouldShowButton(getValue(item, `trigger_at`, "")))
          return (
            <Dropdown overlay={moreOptionsMenu(item)} trigger={["click"]}>
              <Button icon={<MoreOutlined />} shape="circle" />
            </Dropdown>
          );
      },
    },
  ];

  const shouldShowButton = (dateString: any) => {
    const inputDate: any = new Date(dateString);
    const currentDate: any = new Date();
    const differenceInMs = inputDate - currentDate;

    // Check if the date is more than 3 minutes (180000 ms) in the future
    return differenceInMs > 180000;
  };

  return (
    <>
      {list.length > 0 ? (
        <Table
          size="middle"
          columns={hideMore ? broadcastColumn : broadcastColumnWithMore}
          dataSource={list}
          rowKey="id"
          pagination={{
            current: page_no,
            pageSize: page_size,
            total: totalCount,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "50"],
            onChange: (page_no, pageSize) => {
              handleChangePagination(page_no, pageSize);
            },
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
          scroll={{
            y: 506,
            x: 1400,
          }}
          bordered
        />
      ) : (
        <BroadCastNoDataPage />
      )}
    </>
  );
}

export default BroadcastHistoryTable;
