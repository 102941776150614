import { getValue } from "@utils/lodash";
import KPIStandardComonent from "./KPI/Standard";
import KPIBasicComponent from "./KPI/Basic";
import KPIGrowthIndexComponent from "./KPI/GrowthIndex";
import KPIScoreCardComponent from "./KPI/ScoreCard";
import KPIRankingsComponents from "./KPI/Rankings";
import ColumnChartComponent from "./Chart/ColumnChart";
import DonutChartComponent from "./Chart/DonutChart";
import PieChartComponent from "./Chart/PieChart";
import BarChartComponent from "./Chart/BarChart";
import LineChartComponent from "./Chart/LineChart";
import TableChartComponent from "./Chart/TableChart";
import FunnelChartComponent from "./Chart/FunnelChart";
import AreaChartComponent from "./Chart/AreaChart";
import HeatMapChartComponent from "./Chart/HeatMapChart";

function DashboardComponentGraphs(props: any) {
  const renderComponent = (componentProps: any) => {
    const mergedProps = {
      ...componentProps,
      toggleChartDetails: props.toggleChartDetails,
      confirmDeleteAnalyticsComponent: props.confirmDeleteAnalyticsComponent,
      isMaximized: props.isMaximized,
      setMaximized: props.setMaximized,
      setSelectedForMaximize: props.setSelectedForMaximize,
      handleEditChart:props.handleEditChart
    };

    switch (getValue(componentProps, `graph_type`, "")) {
      // -------- KPI  ---------//
      case "standard":
        return <KPIStandardComonent {...mergedProps} />;
      case "growth_index":
        return <KPIGrowthIndexComponent {...mergedProps} />;
      case "basic":
        return <KPIBasicComponent {...mergedProps} />;
      case "scorecard":
        return <KPIScoreCardComponent {...mergedProps} />;
      case "rankings":
        return <KPIRankingsComponents {...mergedProps} />;
      // -------- Chart  ---------//
      case "column_chart":
        return <ColumnChartComponent {...mergedProps} />;
      case "donut_chart":
        return <DonutChartComponent {...mergedProps} />;
      case "pie_chart":
        return <PieChartComponent {...mergedProps} />;
      case "bar_chart":
        return <BarChartComponent {...mergedProps} />;
      case "line_chart":
        return <LineChartComponent {...mergedProps} />;
      case "table_chart":
        return <TableChartComponent {...mergedProps} />;
      case "funnel_chart":
        return <FunnelChartComponent {...mergedProps} />;
      case "area_chart":
        return <AreaChartComponent {...mergedProps} />;
      case "heat_map":
        return <HeatMapChartComponent {...mergedProps} />;
      default:
        return null;
    }
  };
  return renderComponent(getValue(props, `props`, {}));
}

export default DashboardComponentGraphs;
