import { getValue } from "@utils/lodash";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
} from "chart.js";
import DashboardMoreOptions from "../../components/dashboard-more-options";
import { findLabelFromKeyItem } from "../../helpers/enum";

// Register the necessary components
ChartJS.register(
  LineElement,
  PointElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale
);

function DynamicLineChartComponent(props: any) {
  const [data, setData] = useState<any>({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    const mainData = getValue(props, "graph", []);
    if (mainData && mainData.length > 0) {
      // Determine if we should create a stacked chart
      const hasChildData = mainData.some(
        (item: any) => getValue(item, `child.length`, 0) > 0 && item?.child
      );

      if (hasChildData) {
        // Stacked Line Chart Logic
        const labels = mainData.map((item: any) => findLabelFromKeyItem(item));
        const datasets: any[] = [];

        // Collect unique child keys
        const childKeys = new Set();
        mainData.forEach((item: any) => {
          if (getValue(item, `child`, [])) {
            getValue(item, `child`, []).forEach((child: any) => {
              const childKey = findLabelFromKeyItem(child);
              childKeys.add(childKey);
            });
          }
        });

        // Create a dataset for each unique child key
        childKeys.forEach((childKey: any, index: any) => {
          const data = mainData.map((item: any) => {
            const matchingChild = getValue(item, `child`, []).find(
              (child: any) => findLabelFromKeyItem(child) === childKey
            );
            return matchingChild ? matchingChild.count : 0;
          });

          datasets.push({
            label: childKey ? childKey.toString() : "Unknown",
            data: data,
            borderColor: `rgba(${75 + index * 30}, 192, 192, 1)`,
            backgroundColor: `rgba(${75 + index * 30}, 192, 192, 0.5)`,
            fill: false,
            // tension: 0.4,
            borderWidth: 2,
          });
        });

        setData({
          labels: labels,
          datasets: datasets,
        });
      } else {
        // Non-Stacked Line Chart Logic
        const datasets = [
          {
            data: mainData.map((child: any) => getValue(child, "count", 0)),
            labels: mainData.map(
              (child: any, index: number) =>
                getValue(child, "key", "") || `Category ${index + 1}`
            ),
            label: "",
            borderWidth: 2,
            fill: false,
            // tension: 0.4,
          },
        ];

        setData({
          labels: mainData.map(
            (item: any) => getValue(item, "key", "") || `Category`
          ),
          datasets: datasets,
        });
      }
    }
  }, [props.graph, getValue(props, `module_field.label`, "")]);

  const options: any = {
    responsive: true,
    plugins: {
      datalabels: {
        color: "#000",
        anchor: 'end',
        align: 'center',
        offset: 5,
        font: {
          size: 10,
          weight: 'bold'
        },
        formatter: (value: number) => value > 0 ? value.toString() : '',
        display: (context:any) => context.dataset.data[context.dataIndex] > 0,
        textStrokeColor: '#fff',
        textStrokeWidth: 2
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          color: "rgba(200, 200, 200, 0.2)",
        },
        beginAtZero: true,
      },
    },
  };

  return (
    <div
      className={`${
        getValue(props, "isMaximized", "") ? "" : "dashboard_border"
      }`}
    >
      <p
        className="header_text__16 pb-3 cursor-pointer text-decoration-underline"
        onClick={() => props.toggleChartDetails(props)}
      >
        {getValue(props, "name", "")}
      </p>
      <div
        className={`${
          getValue(props, "isMaximized", "")
            ? "maximized-chart-container"
            : "chart-holder"
        }`}
      >
        <Line data={data} options={options} />
      </div>
      {!props.hideOptions && (
        <DashboardMoreOptions
          props={props}
          id={getValue(props, "id", "")}
          confirmDeleteAnalyticsComponent={
            props.confirmDeleteAnalyticsComponent
          }
          handleEditChart={props.handleEditChart}
        />
      )}
    </div>
  );
}

export default DynamicLineChartComponent;
