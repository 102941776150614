import {
  convertCurrentDate,
  convertCurrentDateWithFormat,
} from "@common/date-helpers";
import { getValue } from "@utils/lodash";
import { Tag } from "antd";
import React from "react";
import DashboardMoreOptions from "../../components/dashboard-more-options";

function KPIScoreCardComponent(props: any) {
  return (
    <div
      className={`${
        getValue(props, "isMaximized", "") ? "" : "dashboard_border"
      }`}
    >
      <p
        className="header_text__16 pb-3 cursor-pointer text-decoration-underline"
        onClick={() => props.toggleChartDetails(props)}
      >
        {getValue(props, `name`, "")}
      </p>

      <table className="w-100">
        {/* <thead>
          <th></th>
          <th></th>
          <th></th>
        </thead> */}
        {getValue(props, `graph`, []).map((item: object, i: number) => {
          return (
            <tr
              className={`w-100 pb-3 ${
                i !== getValue(props, `graph`, []).length - 1
                  ? "border-bottom"
                  : ""
              }`}
              key={i}
            >
              <td className="60">
                {typeof getValue(item, `key`, "") === "string"
                  ? convertCurrentDateWithFormat(
                      getValue(item, `key`, ""),
                      "DD-MM-YYYY"
                    )
                  : convertCurrentDateWithFormat(
                      getValue(item, `key.created_at`, ""),
                      "DD-MM-YYYY"
                    )}
              </td>
              <td className="w-20 small_text__16">
                {getValue(item, `count`, "")}
              </td>
              <td className="w-20 text-right small_text__16">
                <Tag
                  color={`${
                    getValue(item, `percentageToCurrent`, "") !== "positive" //dummy color
                      ? "#4cc792"
                      : "#f88"
                  }`}
                  className="me-0"
                >
                  {getValue(item, `percentageToCurrent`, "")}
                </Tag>
              </td>
            </tr>
          );
        })}
      </table>
      <DashboardMoreOptions
        props={props}
        id={getValue(props, "id", "")}
        confirmDeleteAnalyticsComponent={props.confirmDeleteAnalyticsComponent}
        handleEditChart={props.handleEditChart}
      />
    </div>
  );
}

export default KPIScoreCardComponent;
