import { getColorCode, getTextColor } from "@common/colors";
import {
  camelToSnake,
  capitalizeFirstLetter,
  formatString,
  getOnlyFirstLetterOfWord,
} from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import { Badge, Checkbox, Tag } from "antd";
import "./card.scss";
import { daysSinceDate } from "@common/date-helpers";
import TextHeaderTooltip from "../Dropdown/TextHeaderTooltip";
import LabelSvgComponent from "@assets/svg/label";
import { commonBulkUpdate } from "@services/common.service";
import { toast } from "sonner";
import { Link } from "react-router-dom";
import ListLoader from "@components/common/Loader";
import FiltersNoDataPage from "@components/common/NoData/filter-nodata";
import TicketsNoDataPage from "@components/common/NoData/tickets-nodata";

const CustomCardView = ({
  params,
  data,
  module,
  selectedIds,
  setSelectedIds,
  getListData,
  selectedPipeline,
  stageList,
  isLoading,
}: any) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());

  const handleDisplayItem = (col: any, row: any) => {
    switch (col) {
      //   case "closing_date":
      //     return convertCurrentDate(row[col]);
      //   case "created_at":
      //   case "updated_at":
      //   case "due_datetime":
      //   case "reminder_datetime":
      //     return convertCurrentDateWithTime(row[col]);
      case "pipeline_stage":
        // return getValue(row["pipeline_stage"], `label`, "");
        return getValue(row["pipeline_stage"], `id`, "");
      case "owner":
      case "owner_id":
        return getValue(row["owner"], `first_name`, "")
          ? getValue(row["owner"], `first_name`, "") +
              " " +
              getValue(row["owner"], `last_name`, "")
          : "-";
      case "updated_by":
        return getValue(row["updated_by"], `first_name`, "")
          ? getValue(row["updated_by"], `first_name`, "") +
              " " +
              getValue(row["updated_by"], `last_name`, "")
          : "-";
      case "created_by":
        return getValue(row["created_by"], `first_name`, "")
          ? getValue(row["created_by"], `first_name`, "") +
              " " +
              getValue(row["created_by"], `last_name`, "")
          : "-";
      //   case "amount":
      //     return getValue(row, `[${col}]`, "")
      //       ? getValue(orgInfo, `currency`, "")
      //         ? getValue(orgInfo, `currency`, "") +
      //           " " +
      //           getValue(row, `[${col}]`, "")
      //         : "INR" + " " + getValue(row, `[${col}]`, "")
      //       : "-";
      //   default:
      //     return getValue(row, `[${col}]`, "")
      //       ? Array.isArray(getValue(row, `[${col}]`, ""))
      //         ? convertSnakeToCamelCaseArray(getValue(row, `[${col}]`, ""))
      //         : convertDateWithString(
      //             formatCamelString(getValue(row, `[${col}]`, ""))
      //           )
      //       : typeof getValue(row, `[${col}]`, "") === "boolean"
      //       ? convertDateWithString(
      //           formatCamelString(getValue(row, `[${col}]`, ""))
      //         )
      //       : "-";
    }
  };

  const handleChangeCheckbox = (id: any) => {
    let checkIfExist =
      getValue(selectedIds, `length`, 0) > 0
        ? selectedIds.filter((item: any) => item === id)
        : [];
    if (getValue(checkIfExist, `length`, 0) > 0) {
      let filtered =
        getValue(selectedIds, `length`, 0) > 0
          ? selectedIds.filter((item: any) => item !== id)
          : [];
      setSelectedIds(filtered);
    } else {
      selectedIds.push(id);
      setSelectedIds([...selectedIds]);
    }
  };

  const findStageColor = (stage: any) => {
    let color =
      getValue(data, `stageList.length`, 0) > 0
        ? getValue(data, `stageList`, []).find(
            // (item) => getValue(item, `api_name`, "") === stage
            (item: any) => getValue(item, `id`, "") === stage
          )
        : {};
    return getValue(color, `color_code`, "");
  };

  const handleChangeStage = async (id: any, main_id: any) => {
    try {
      let resp = await commonBulkUpdate(module, {
        ids: [main_id],
        data: {
          pipeline_stage_id: id,
        },
      });
      if (resp) {
        toast.success("Updated Succssfully");
        getListData(selectedPipeline.id, false);
      } else {
      }
    } catch (error) {}
  };

  function appendCustomData(jsonData: any) {
    if (jsonData.custom_data) {
      const customData = jsonData.custom_data;
      const updatedData = {
        ...jsonData,
        ...customData,
      };
      delete updatedData.custom_data;
      return updatedData;
    } else {
      return jsonData;
    }
  }

  return isLoading && !getValue(UrlParams, `search`, "") ? (
    <ListLoader />
  ) : (getValue(Object.keys(UrlParams), `length`, 0) && data.length === 0) ||
    getValue(localStorage, `sf:tickets`, "") ? (
    <FiltersNoDataPage module={module} />
  ) : data.length > 0 ? (
    <>
      {/* {isLoading ? (
        <ListLoader />
      ) : ( */}
      <div className="d-grid gap-2 mb-3 mt-1 ticket-card-wrapper">
        {data.map((item: any, index: any) => (
          <div key={index} className="ticket-card-container row mx-auto">
            <div className="d-flex align-items-center gap-2 col-8">
              <Checkbox
                className="pe-3"
                checked={
                  selectedIds.includes(getValue(item, `id`, "")) ? true : false
                }
                onChange={() => handleChangeCheckbox(getValue(item, `id`, ""))}
              />
              <div
                className="image-container"
                style={{
                  background: getColorCode(
                    getOnlyFirstLetterOfWord(getValue(item, `ticket_name`, ""))
                  ),
                  color: getTextColor(
                    getColorCode(
                      getOnlyFirstLetterOfWord(
                        getValue(item, `ticket_name`, "")
                      )
                    )
                  ),
                }}
              >
                <p className="header-text">
                  {getOnlyFirstLetterOfWord(getValue(item, `ticket_name`, ""))}
                </p>
              </div>
              <div className="d-grid">
                {/* {getValue(item, "resolution", "") && ( */}
                <Tag color="magenta" className="w-fit-content">
                  {formatString(getValue(item, "resolution", ""))}
                </Tag>
                {/* )} */}
                <Link
                  to={`/${getValue(
                    params,
                    "orgId",
                    ""
                  )}/tickets/detail/${getValue(item, "id", "")}`}
                  className="py-2"
                >
                  {capitalizeFirstLetter(getValue(item, "ticket_name", ""))}{" "}
                  <span className="small_text__12">
                    {getValue(item, "number_of_times_contacted", "") && (
                      <># {getValue(item, "record_num", "")}</>
                    )}
                  </span>
                </Link>
                <div className="d-flex align-items-center gap-3">
                  <p className="small_text__13">
                    Ticket ID: {getValue(item, "ticket_id", "")}
                  </p>
                  <Badge status="default" />
                  <p className="small_text__13">
                    Created: {daysSinceDate(getValue(item, "created_at", ""))}
                  </p>
                  {/* <Badge status="default" />
                <p className="small_text__13">
                  Overdue by
                  {daysSinceDate(getValue(item, "created_at", "")) === 0
                    ? "today"
                    : daysSinceDate(getValue(item, "created_at", "")) === 1
                    ? "yesterday"
                    : `${daysSinceDate(
                        getValue(item, "created_at", "")
                      )} days ago`}
                </p> */}
                </div>
              </div>
            </div>
            <div className="col-3">
              <div
                style={{
                  color: findStageColor(
                    camelToSnake(
                      handleDisplayItem(
                        "pipeline_stage",
                        appendCustomData(item)
                      )
                    )
                  ),
                }}
                className="stage_color mx-1 d-flex align-items-center px-2"
                onClick={(e) => e.stopPropagation()}
              >
                <LabelSvgComponent
                  color={findStageColor(
                    camelToSnake(
                      handleDisplayItem(
                        "pipeline_stage",
                        appendCustomData(item)
                      )
                    )
                  )}
                  size={18}
                />
                <TextHeaderTooltip
                  // title={`Status`}
                  data={stageList}
                  label={`label`}
                  onChange={(e: any) =>
                    handleChangeStage(
                      getValue(e, `id`, ""),
                      getValue(item, `id`, "")
                    )
                  }
                  color={findStageColor(
                    camelToSnake(
                      handleDisplayItem(
                        "pipeline_stage",
                        appendCustomData(item)
                      )
                    )
                  )}
                  value={handleDisplayItem(
                    "pipeline_stage",
                    appendCustomData(item)
                  )}
                  selectKey={"id"}
                  // width={"270px"}
                  editInline
                  showCheveronDown
                />
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* )} */}
    </>
  ) : (
    <TicketsNoDataPage />
  );
};

export default CustomCardView;
