import { getValue } from "@utils/lodash";
import { DatePicker, DatePickerProps } from "antd";
import dayjs from "dayjs";
import React from "react";

const SheetDateEditor = React.memo(
  ({ col, row, field, handleDisplayItem, handleInputChange }: any) => {
    const initialDate = handleDisplayItem(col, row);

    const defaultValue = initialDate ? dayjs(initialDate) : null;

    const onChange: DatePickerProps["onChange"] = (date, dateString) => {
      if (date) {
        const isoDateString = date.toISOString();
        handleInputChange(getValue(row, "id", ""), col, isoDateString, field);
      } else {
        handleInputChange(getValue(row, "id", ""), col, null, field);
      }
    };

    return (
      <DatePicker
        defaultValue={defaultValue}
        onChange={onChange}
        format="DD MMM YYYY"
        style={{ minWidth: "160px" }}
      />
    );
  }
);

export default SheetDateEditor;
