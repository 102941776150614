import React, { useEffect, useState } from "react";
import CompanyProfileSubHeader from "../../CompanyProfile/components/CompanyProfileSubHeader";
import CompanyProfileNavigation from "@layouts/SettingsLayout/CompanyProfileNavigation";
import ListLoader from "@components/common/Loader";
import MainLayout from "@layouts/HomeLayout/NewLayout";
import { useNavigate, useParams } from "react-router-dom";
import { getOrganisationUserLoginHistory } from "@services/organisations.service";
import { getValue } from "@utils/lodash";
import { Table } from "antd";
import { QueryRequestHelper } from "@common/query-request-helper";
import BackButton from "@components/custom/BrowserBack";
import Navbar from "../../common/navbar";
import { convertCurrentDateWithTime } from "@common/date-helpers";

const LoginHistory = () => {
  const params = useParams();
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());

  const [loading, setLoading] = useState(false);
  const [loginHistory, setLoginHistory] = useState([]);
  const [page_no, setPageNo] = useState(1);
  const [limit, setLimit] = useState(25);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    getHistory();
  }, [params, page_no, limit]);

  const getHistory = async () => {
    try {
      setLoading(true);
      const payload = {
        user_id: getValue(params, "id", ""),
        page_no: page_no,
        page_size: limit,
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getOrganisationUserLoginHistory(queryRequest);
      if (resp && resp.data) {
        setLoginHistory(getValue(resp, "data.logs", ""));
        setTotalCount(getValue(resp, "data.pagination.total", ""));
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleChangePagination = (page_no: number, limit: number) => {
    let payload = {
      ...UrlParams,
      page_no: page_no,
      page_size: limit,
    };
    let queryRequest = QueryRequestHelper(payload);
    setPageNo(page_no); // Update state
    setLimit(limit); // Update limit
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  const columns = [
    {
      title: "BROWSER",
      dataIndex: "browser_info",
      key: "browser_info",
    },
    {
      title: "IP ADDRESS",
      dataIndex: "ip_address",
      key: "ip_address",
    },
    {
      title: "LOGIN DATE",
      dataIndex: "login_date",
      key: "login_date",
      render: (login_date: any) => convertCurrentDateWithTime(login_date),
      sorter: (a: any, b: any) =>
        new Date(a.login_date).getTime() - new Date(b.login_date).getTime(),
      defaultSortOrder: "descend" as const,
    },
    {
      title: "MAC ADDRESS",
      dataIndex: "mac_address",
      key: "mac_address",
    },
  ];

  const views = [
    {
      id: "1",
      name: "Login History",
    },
  ];

  return (
    <MainLayout isLoading={loading}>
      <CompanyProfileSubHeader />
      <div className="users_container w-100">
        <CompanyProfileNavigation />
        {loading ? (
          <ListLoader />
        ) : (
          <div className={`${"manageFieldRight"} w-100 `}>
            <div className="d-flex gap-1 align-items-center mt-3">
              <BackButton
                className="ms-4 "
                route={`/${getValue(
                  params,
                  `orgId`,
                  ""
                )}/settings/general/users`}
              />
              <Navbar
                views={views}
                route={`/${getValue(
                  params,
                  `orgId`,
                  ""
                )}/settings/general/users/login/${getValue(params, `id`, "")}`}
              />
            </div>
            <div className="login-history-table">
              <Table
                bordered
                className="w-100 px-5"
                columns={columns}
                dataSource={loginHistory}
                rowKey="id"
                pagination={{
                  current: page_no,
                  pageSize: limit,
                  total: totalCount,
                  showSizeChanger: true,
                  pageSizeOptions: ["25", "50", "100", "250", "500", "1000"],
                  onChange: (page_no, limit) => {
                    handleChangePagination(page_no, limit);
                  },
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                }}
                scroll={{
                  y: `calc(100vh - 300px)`,
                  // x: 1400,
                }}
              />
            </div>
          </div>
        )}
      </div>
    </MainLayout>
  );
};

export default LoginHistory;
