import MainLayout from "@layouts/HomeLayout/NewLayout";
import React, { useEffect, useState } from "react";
import CompanyProfileSubHeader from "../CompanyProfile/components/CompanyProfileSubHeader";
import CompanyProfileDetailsLayout from "@layouts/SettingsLayout/CompanyProfile";
import ListLoader from "@components/common/Loader";
import { useNavigate, useParams } from "react-router-dom";
import { QueryRequestHelper } from "@common/query-request-helper";
import {
  deleteBusinessHour,
  getAllBusinessHours,
} from "@services/business-hours.service";
import { getValue } from "@utils/lodash";
import { toast } from "sonner";
import {
  Badge,
  Button,
  Dropdown,
  MenuProps,
  Modal,
  Pagination,
  Tooltip,
} from "antd";
import { LockKeyhole, MoreVertical, Plus } from "lucide-react";
import { capitalizeFirstLetter } from "@common/text-helpers";
import "./business-hours.scss";
import SettingsPageDetails from "@components/Pages/PageDescription/SettingsPageDetails";
import { businessHoursDetails } from "@common/Data/page-details-data";

const BusinessHours = ({ props }: any) => {
  const navigate = useNavigate();
  const params = useParams();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);

  /* -------------------------------------------------------------------------- */
  /*                              UseEffect Section                             */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (Object.keys(UrlParams).length === 0) {
      getBusinessHoursList(true);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(UrlParams).length > 0) {
      if (UrlParams.page_no) {
        setPage_no(parseInt(UrlParams.page_no));
      }
      if (UrlParams.limit) {
        setLimit(parseInt(UrlParams.limit));
      }
      getBusinessHoursList(true);
    }
  }, [window.location.href]);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const getBusinessHoursList = async (status: boolean) => {
    try {
      setIsLoading(status);
      const payload = {
        page_size: getValue(UrlParams, `page_size`, "")
          ? getValue(UrlParams, `page_size`, "")
          : limit,
        page_no: getValue(UrlParams, `page_no`, "")
          ? getValue(UrlParams, `page_no`, "")
          : page_no,
      };
      const queryRequest = QueryRequestHelper(payload);
      const resp = await getAllBusinessHours(queryRequest);
      if (resp) {
        setList(getValue(resp, "data.business_hours", []));
        // setLimit(getValue(resp, "data.pagination.page_size", 0));
        setPage_no(getValue(resp, "data.pagination.page", 0));
        setTotalCount(getValue(resp, "data.pagination.total", 0));
      }
    } catch (error) {
      toast.error("Failed to load canned responses.");
    } finally {
      setIsLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);

  const handleChangePagination = (page_no: any, limit: any) => {
    setPage_no(page_no);
    setLimit(limit);

    let payload = {
      ...UrlParams,
      page_no: page_no,
      page_size: limit,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
    setPage_no(page_no);
  };

  const handleDeleteModal = (id: string, name: string) => {
    Modal.confirm({
      title: `Are you sure you want to delete business hour - ${name}?`,
      content: "Once deleted, this business hour cannot be recovered.",
      okText: "Delete",
      cancelText: "Cancel",
      onOk: async () => {
        try {
          await deleteBusinessHour(id);
          toast.success("Business Hour deleted successfully");
          getBusinessHoursList(true);
        } catch (error) {
          toast.error("Error deleting folder");
        }
      },
    });
  };

  return (
    <MainLayout {...props}>
      <CompanyProfileSubHeader
        addChildren={
          <Button
            type="primary"
            size="large"
            icon={<Plus size={20} />}
            onClick={() =>
              navigate(
                `/${getValue(
                  params,
                  "orgId",
                  ""
                )}/settings/business-hours/create`
              )
            }
          >
            New Business Hour
          </Button>
        }
      />
      <CompanyProfileDetailsLayout classname="my-0">
        {isLoading ? (
          <ListLoader />
        ) : (
          <div className="business-hours-wrapper">
            <div className="p-4 w-70 business-hours-card-container">
              <h6 className="header_text__18">Business Hours</h6>
              <div className="d-grid gap-2 my-3">
                {list.map((listItem, index) => {
                  const items: MenuProps["items"] = [
                    {
                      key: "1",
                      label: "Delete",
                      onClick: () =>
                        handleDeleteModal(
                          getValue(listItem, "id", ""),
                          getValue(listItem, "name", "")
                        ),
                    },
                  ];

                  return (
                    <div
                      key={index}
                      className="border p-4 rounded-3 business-hours-card"
                    >
                      <div
                        className="cursor-pointer w-100"
                        onClick={() =>
                          navigate(
                            `/${getValue(
                              params,
                              "orgId",
                              ""
                            )}/settings/business-hours/edit/${getValue(
                              listItem,
                              "id",
                              ""
                            )}`
                          )
                        }
                      >
                        <h6 className="header_text__16 d-flex align-items-center gap-2 mb-2 ">
                          {capitalizeFirstLetter(
                            getValue(listItem, "name", "")
                          )}
                          {getValue(listItem, "is_default", "") && (
                            <Tooltip title="Default Business Hour">
                              <LockKeyhole size={14} />
                            </Tooltip>
                          )}
                        </h6>
                        {getValue(listItem, "description", "") && (
                          <p className="small_text__14 pb-1">
                            {getValue(listItem, "description", "")}
                          </p>
                        )}
                        <div className="d-flex align-items-center gap-3">
                          <p className="small_text__13">
                            {getValue(listItem, "time_zone", "")}
                          </p>
                          <Badge status="default" />
                          <p className="small_text__13">
                            {getValue(listItem, "groups.length", "")} groups
                            associated
                          </p>
                        </div>
                      </div>
                      {!getValue(list, "is_default", "") && (
                        <Dropdown menu={{ items }}>
                          <MoreVertical size={18} />
                        </Dropdown>
                      )}
                    </div>
                  );
                })}
              </div>
              {totalCount > 10 && (
                <Pagination
                  align="center"
                  className="mt-4"
                  total={totalCount}
                  showTotal={(total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`
                  }
                  defaultPageSize={limit}
                  defaultCurrent={page_no}
                  onChange={handleChangePagination}
                />
              )}
            </div>
            <div className="w-30 p-4 business-hours-right-details">
              <SettingsPageDetails data={businessHoursDetails} />
            </div>
          </div>
        )}
      </CompanyProfileDetailsLayout>
    </MainLayout>
  );
};

export default BusinessHours;
