import { Badge, Select, Switch, Table, TableProps, Tooltip } from "antd";
import { getValue } from "@utils/lodash";
import CustomTimeInputToggle from "./CustomTimeInputToggle";
import { Info } from "lucide-react";

const SLATargetTable = (props: any) => {
  const handleChangeOperationalHours = (priority: string, value: boolean) => {
    props.setRequest((prev: any) => ({
      ...prev,
      [priority]: {
        ...prev[priority],
        business_hours: value,
      },
    }));
  };

  const handleChangeEscalation = (priority: string, checked: boolean) => {
    props.setRequest((prev: any) => ({
      ...prev,
      [priority]: {
        ...prev[priority],
        escalation_enabled: checked,
      },
    }));
  };

  const handleTimeChange = (
    priority: string,
    time: string,
    timeKey: string
  ) => {
    props.setRequest((prev: any) => ({
      ...prev,
      [priority]: {
        ...prev[priority],
        [timeKey]: time,
      },
    }));
  };

  const columns: TableProps["columns"] = [
    {
      title: "PRIORITY",
      dataIndex: "priority",
      key: "priority",
      render: (text: string, record: any) => (
        <div
          className="d-flex align-items-center gap-2 small_text__16"
          style={{ height: "60px" }}
        >
          <Badge status={record.color} />
          {text}
        </div>
      ),
    },
    {
      title: (
        <div className="d-flex align-items-center gap-2">
          FIRST RESPONSE TIME
          <Tooltip title="Set how quickly you respond to a customer’s request">
            <Info size={16} />
          </Tooltip>
        </div>
      ),
      dataIndex: "first_response_time",
      key: "first_response_time",
      render: (_, record) => (
        <CustomTimeInputToggle
          onTimeChange={(time: string) =>
            handleTimeChange(record.key, time, "first_response_time")
          }
          request={props.request}
          current={record.key}
          timeKey="first_response_time"
        />
      ),
    },
    {
      title: (
        <div className="d-flex align-items-center gap-2">
          EVERY RESPONSE TIME
          <Tooltip title="Set how frequently you’ll keep customers updated">
            <Info size={16} />
          </Tooltip>
        </div>
      ),
      dataIndex: "every_response_time",
      key: "every_response_time",
      render: (_, record) => (
        <CustomTimeInputToggle
          onTimeChange={(time: string) =>
            handleTimeChange(record.key, time, "every_response_time")
          }
          request={props.request}
          current={record.key}
          timeKey="every_response_time"
        />
      ),
    },
    {
      title: (
        <div className="d-flex align-items-center gap-2">
          RESOLUTION TIME
          <Tooltip title="Set how long you’ll take to solve a request">
            <Info size={16} />
          </Tooltip>
        </div>
      ),
      key: "resolution_due_time",
      dataIndex: "resolution_due_time",
      render: (_, record) => (
        <CustomTimeInputToggle
          onTimeChange={(time: string) =>
            handleTimeChange(record.key, time, "resolution_due_time")
          }
          request={props.request}
          current={record.key}
          timeKey="resolution_due_time"
        />
      ),
    },
    {
      title: "OPERATIONAL HOURS",
      key: "hours",
      render: (_, record) => (
        <Select
          onChange={(value) => handleChangeOperationalHours(record.key, value)}
          value={getValue(props, `request.${record.key}.business_hours`, true)}
          options={[
            { value: true, label: "Business Hours" },
            { value: false, label: "Calendar Hours" },
          ]}
        />
      ),
    },
    {
      title: "ESCALATION",
      key: "escalation",
      render: (_, record) => (
        <Switch
          size="small"
          checked={getValue(
            props,
            `request.${record.key}.escalation_enabled`,
            true
          )}
          onChange={(checked) => handleChangeEscalation(record.key, checked)}
        />
      ),
    },
  ];

  const tableData = [
    { key: "urgent", priority: "Urgent", color: "error" },
    { key: "high", priority: "High", color: "warning" },
    { key: "medium", priority: "Medium", color: "processing" },
    { key: "low", priority: "Low", color: "success" },
  ];

  return (
    <div className="mt-5">
      <h6 className="header_text__18">Set SLA target as:</h6>
      <Table
        columns={columns}
        dataSource={tableData}
        className="mt-2"
        pagination={false}
      />
    </div>
  );
};

export default SLATargetTable;
