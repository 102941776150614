import { getValue } from "@utils/lodash";
import { Drawer } from "antd";
import GraphHeaderPage from "../components/graph-header";
import DrawerInfoPage from "../components/drawer-info";
import Pagination from "@components/Pages/Pipeline/common/pagination";

const ChartDetailsDrawer = (props: any) => {
  const {
    open,
    onClose,
    drawerGraphName,
    chartInfo,
    graphInfo,
    total_count,
    page_size,
    page_no,
    handleChangePagination,
    handleChangeLimit,
  } = props;
  return (
    <Drawer
      title={drawerGraphName}
      onClose={() => {
        onClose();
      }}
      open={open}
      width={720}
      className="dashboard-slider-container bg-white"
    >
      <GraphHeaderPage graphInfo={graphInfo} />
      <div>
        {getValue(chartInfo, `length`, 0) > 0 &&
          chartInfo.map((item: object, index: number) => {
            return (
              <div key={index}>
                <DrawerInfoPage
                  index={index}
                  item={item}
                  module={getValue(props, `graphInfo.module.api_name`, "")}
                />
              </div>
            );
          })}
        {total_count > 10 && (
          <Pagination
            totalCount={total_count}
            limit={page_size}
            page_no={page_no}
            handleChangePagination={handleChangePagination}
            handleChangeLimit={handleChangeLimit}
          />
        )}
      </div>
    </Drawer>
  );
};

export default ChartDetailsDrawer;
