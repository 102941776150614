import { CopyOutlined } from "@ant-design/icons";
import { capitalizeFirstLetter } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import { Checkbox, Radio, Space, TimePicker } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { toast } from "sonner";

const BusinessHoursTab = ({ request, setRequest, days, props }: any) => {
  const handleChanegBusinessHoursType = (e: any) => {
    const value = e.target.value;

    if (value === "custom") {
      const defaultHours = days
        .filter((day: any) =>
          ["monday", "tuesday", "wednesday", "thursday", "friday"].includes(
            day.value
          )
        )
        .map((day: any) => ({
          day: day.value,
          start_time: "08:00",
          end_time: "17:00",
        }));

      setRequest({
        ...request,
        business_hours_type: value,
        hours: defaultHours,
      });
    } else {
      setRequest({
        ...request,
        business_hours_type: value,
        hours: [],
      });
    }
  };

  const handleWorkingDaysChange = (checkedValues: string[]) => {
    const existingHoursMap = new Map(
      request.hours.map((hour: any) => [hour.day, hour])
    );

    const updatedHours = days
      .filter((day: any) => checkedValues.includes(day.value))
      .map((day: any) => {
        return (
          existingHoursMap.get(day.value) || {
            day: day.value,
            start_time: "08:00",
            end_time: "17:00",
          }
        );
      });

    setRequest({
      ...request,
      hours: updatedHours,
    });
  };

  const handleTimeChange = (index: number, time: Dayjs | null, key: string) => {
    if (time) {
      const formattedTime = time.format("HH:mm");
      const updatedHours = [...request.hours];
      updatedHours[index] = {
        ...updatedHours[index],
        [key]: formattedTime,
      };

      setRequest({
        ...request,
        hours: updatedHours,
      });
    } else {
      toast.error("Invalid time selected.");
    }
  };

  const handleCopyToAll = () => {
    const firstStartTime = getValue(request, "hours[0].start_time", "08:00");
    const firstEndTime = getValue(request, "hours[0].end_time", "17:00");

    const updatedHours = request.hours.map((item: any) => ({
      ...item,
      start_time: firstStartTime,
      end_time: firstEndTime,
    }));

    setRequest({
      ...request,
      hours: updatedHours,
    });
  };

  return (
    <div>
      <h6 className="header_text__16">Set business hours</h6>
      <Radio.Group
        onChange={(e) => handleChanegBusinessHoursType(e)}
        value={getValue(request, "business_hours_type", "")}
        className="mt-2"
      >
        <Space direction="vertical">
          <Radio value="24_7_availability">24 hrs x 7 days</Radio>
          <Radio value="custom">Custom business hours</Radio>
        </Space>
      </Radio.Group>
      {getValue(request, "business_hours_type", "") === "custom" && (
        <>
          <div className="my-3 ps-4">
            <label className="form-label">Select the working days</label>
            <Checkbox.Group
              className="d-flex gap-3"
              onChange={handleWorkingDaysChange}
              value={getValue(request, "hours", []).map((hour: any) =>
                getValue(hour, "day", "")
              )}
            >
              {days.map((item: any, index: any) => (
                <Checkbox
                  value={getValue(item, "value", "")}
                  key={index}
                  disabled={
                    getValue(request, "hours.length", 0) === 1 &&
                    getValue(request, "hours", []).some(
                      (hour: any) =>
                        getValue(hour, "day", "") ===
                        getValue(item, "value", "")
                    )
                  }
                >
                  {getValue(item, "shortened", "")}
                </Checkbox>
              ))}
              <p>
                ({getValue(request, "hours.length", "")}{" "}
                {getValue(request, "hours.length", "") === 1 ? "day" : "day's"}{" "}
                selected)
              </p>
            </Checkbox.Group>
          </div>
          <div className="my-3 ps-4">
            <label className="form-label">Enter the working hours</label>
            {getValue(request, "hours.length", "") > 0 &&
              getValue(request, "hours", []).map((item: any, index: any) => (
                <div className="row my-2" key={index}>
                  <p className="col-2 small_text__14">
                    {capitalizeFirstLetter(getValue(item, "day", ""))}
                  </p>
                  <div className="col-8 d-flex align-items-center gap-3">
                    <TimePicker
                      minuteStep={30}
                      format="HH:mm"
                      placeholder="Select Time"
                      value={dayjs(getValue(item, "start_time", ""), "HH:mm")}
                      onChange={(time) =>
                        handleTimeChange(index, time, "start_time")
                      }
                    />

                    <p className="">to</p>

                    <TimePicker
                      minuteStep={30}
                      format="HH:mm"
                      placeholder="Select Time"
                      value={dayjs(getValue(item, "end_time", ""), "HH:mm")}
                      onChange={(time) =>
                        handleTimeChange(index, time, "end_time")
                      }
                    />
                    {index === 0 &&
                      getValue(request, "hours.length", "") > 1 && (
                        <div
                          className="header_blue_text__14 d-flex align-items-center gap-1 cursor-pointer"
                          onClick={handleCopyToAll}
                        >
                          <CopyOutlined />
                          Copy to all
                        </div>
                      )}
                  </div>
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  );
};

export default BusinessHoursTab;
