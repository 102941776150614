import { getValue } from "@utils/lodash";
import { Checkbox } from "antd";
import React from "react";

const SheetCheckboxEditor = React.memo(
  ({ col, row, fieldCol, handleDisplayItem, handleInputChange }: any) => {
    return (
      <Checkbox
        checked={handleDisplayItem(col, row)}
        onChange={(e) =>
          handleInputChange(
            getValue(row, "id", ""),
            col,
            e.target.checked,
            fieldCol
          )
        }
      />
    );
  }
);
export default SheetCheckboxEditor;
