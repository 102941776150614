import { convertCurrentDate } from "@common/date-helpers";
import { capitalizeFirstLetter, formatString } from "@common/text-helpers";
import ListLoader from "@components/common/Loader";
import ActivityLoader from "@components/common/Loader/activity-loader";
import AdNoDataPage from "@components/common/NoData/ad-nodata";
import { getValue } from "@utils/lodash";
import { Badge, Table } from "antd";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

function FacebookList(props: any) {
  const { isLoading, list } = props;
  const navigate = useNavigate();
  const params = useParams();

  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      render: (name: any, record: any) => (
        <div
          className="cursor-pointer header_blue_text__14"
          onClick={() =>
            navigate(
              `/${getValue(params, `orgId`, "")}/settings/crm/ads/${getValue(
                record,
                `id`,
                ""
              )}`
            )
          }
        >
          {capitalizeFirstLetter(name)}
        </div>
      ),
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "PROVIDER",
      dataIndex: "provider",
      key: "provider",
      render: (provider: any) => formatString(provider),
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      render: (status: any, text: any) => (
        <div className="d-flex gap-2">
          <Badge status={status === "ACTIVE" ? "success" : "error"} />
          {formatString(status)}
        </div>
      ),
    },
    {
      title: "ADDED ON",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at: any, record: any) => convertCurrentDate(created_at),
    },
  ];
  return (
    <>
      {isLoading ? (
        <ListLoader />
      ) : (
        <>
          {list.length > 0 ? (
            <div className="px-4 pt-2">
              {/* <table className="common_table w-100">
                <thead>
                  <tr>
                    <td>Name</td>
                    <td>Email</td>
                    <td>Provider</td>
                    <td>Status</td>
                    <td>Added on</td>
                  </tr>
                </thead>
                <tbody>
                  {list.map((item: object) => {
                    return (
                      <tr
                        onClick={() =>
                          navigate(
                            `/${getValue(
                              params,
                              `orgId`,
                              ""
                            )}/settings/ads/${getValue(item, `id`, "")}`
                          )
                        }
                      >
                        <td className="header_blue_text__14 touchable-text">
                          {getValue(item, `name`, "")}
                        </td>
                        <td>{getValue(item, `email`, "")}</td>
                        <td>
                          {getValue(item, `provider`, "") === "FACEBOOK"
                            ? "Facebook"
                            : ""}
                        </td>
                        <td className="d-flex gap-2 align-items-center">
                          <div
                            className="active-icon"
                            style={{
                              background:
                                getValue(item, `status`, "") === "ACTIVE"
                                  ? "#21C9AF"
                                  : "#F05555",
                            }}
                          ></div>
                          <div>
                            {getValue(item, `status`, "") === "ACTIVE"
                              ? "Active"
                              : "Not active"}
                          </div>
                        </td>
                        <td>
                          {convertCurrentDate(getValue(item, `created_at`, ""))}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table> */}
              <Table
                size="middle"
                bordered
                columns={columns}
                dataSource={list}
                rowKey="id"
                pagination={{
                  showTotal: (total, range) =>
                    `${total} ${total >= 2 ? "items" : "item"}`,
                }}
              />
            </div>
          ) : (
            <div className="pt-4">
              <AdNoDataPage />
            </div>
          )}
        </>
      )}
    </>
  );
}

export default FacebookList;
