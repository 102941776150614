import CountrySearchToolTip from "@components/custom/Dropdown/CountrySearchTooltip";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import InputRuleForm from "@components/InputRuleForm/form";
import { getValue } from "@utils/lodash";
import { Radio, Select, Space, Tooltip } from "antd";
import React from "react";
import countries from "@json/dialcodes.json";
import EditTemplatePreview from "./map-variables/edit-template-preview";
import { Info } from "lucide-react";

const ScheduleBroadcast = ({
  request,
  setRequest,
  timestamp,
  setTimestamp,
  scheduleTimestamp,
  setScheduleTimestamp,
  allConnectionList,
}: any) => {
  return (
    <div className="d-flex justify-content-between gap-4">
      <div style={{ maxWidth: "700px" }} className="w-100 p-4 mx-auto">
        <div className="mb-3">
          <h6 className="header_text__20">Add Details </h6>
          <p className="small_text__14">
            Select account and add a broadcast name
          </p>
        </div>
        <div>
          <div className="mt-3 mb-2">
            <SearchToolTip
              label="label"
              data={allConnectionList}
              selectKey={"value"}
              value={getValue(request, `waAccountId`, "")}
              onChange={(e: any) =>
                setRequest((prevState: any) => ({
                  ...prevState,
                  waAccountId: getValue(e, `id`, ""),
                }))
              }
              name="Select Whatsapp Account"
              placeholder="Choose Whatsapp Account"
              showWhatsappIcon
              required
            />
          </div>
          <div className=" my-2">
            <InputRuleForm
              label="Broadcast Name (For Own Reference)"
              inputType="TEXT"
              name="name"
              placeholder="Enter Broadcast Name"
              required
              className="mb-2"
              onChange={(e: any) =>
                setRequest((prevState: any) => ({
                  ...prevState,
                  name: e.target.value,
                }))
              }
              value={getValue(request, `name`, "")}
            />
          </div>
          <CountrySearchToolTip
            label={"label"}
            data={countries.map((item: object) => ({
              ...item,
              label: getValue(item, "dial_code", ""),
              value: getValue(item, "dial_code", ""),
            }))}
            name="Country Code"
            selectKey="dial_code"
            value={getValue(request, `countryCode`, "")}
            onChange={(e: any) =>
              setRequest((prevState: any) => ({
                ...prevState,
                countryCode: getValue(e, `value`, ""),
              }))
            }
            width={"260px"}
          />
        </div>

        <div className="mb-3 mt-5  align-items-center gap-3 w-auto">
          <h6 className="header_text__20 mb-2">
            Send broadcast{" "}
            <Tooltip title="Broadcast immedietly or you can schedule it in the later date and time.">
              <Info size={16} />
            </Tooltip>
          </h6>

          {/* <Select
            defaultValue="now"
            value={timestamp}
            onChange={(value) => {
              setTimestamp(value);
              if (value === "now") {
                setScheduleTimestamp(new Date());
              }
            }}
            options={[
              { value: "now", label: "Send now" },
              { value: "later", label: "Schedule for a specific time" },
            ]}
            style={{
              minWidth: "260px",
            }}
          /> */}
        </div>
        <div
          className="mt-2 mx-auto"
          style={{
            maxWidth: "900px",
          }}
        >
          <Radio.Group
            onChange={(e) => {
              setTimestamp(e.target.value);
              if (e.target.value === "now") {
                setScheduleTimestamp(new Date());
              }
            }}
            value={timestamp}
          >
            <Space direction="vertical">
              <Radio value="now">Send now</Radio>
              <Radio value="later">Schedule for a specific time</Radio>
            </Space>
          </Radio.Group>
          <div className="w-50">
            {timestamp === "later" && (
              <InputRuleForm
                inputType="DATETIME"
                placeholder="Select Time"
                value={scheduleTimestamp}
                onChange={(e: any) => setScheduleTimestamp(e)}
                minDate={new Date()}
                filterTime={(time: any) => {
                  const currentDate = new Date();
                  const selectedDate = new Date(time);

                  // If it's today, only allow future times
                  if (
                    selectedDate.getDate() === currentDate.getDate() &&
                    selectedDate.getMonth() === currentDate.getMonth() &&
                    selectedDate.getFullYear() === currentDate.getFullYear()
                  ) {
                    return selectedDate.getTime() > currentDate.getTime();
                  }

                  // Allow all times for future dates
                  return true;
                }}
              />
            )}
          </div>
        </div>
      </div>
      <EditTemplatePreview request={request} />
    </div>
  );
};

export default ScheduleBroadcast;
