import { formatString } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import { Select } from "antd";
import React from "react";

const SheetSelectMultiple = React.memo(
  ({
    col,
    row,
    field,
    fieldCol,
    handleDisplayItem,
    handleInputChange,
  }: any) => {
    const options = fieldCol.dropdown_options.map(
      (option: { label: string; value: string }) => ({
        value: option.value,
        label: option.label,
      })
    );

    return (
      <Select
        showSearch
        value={handleDisplayItem(col, row) || undefined}
        placeholder={`Select ${formatString(col)}`}
        options={options}
        onChange={(selectedValue) =>
          handleInputChange(getValue(row, "id", ""), col, selectedValue, field)
        }
        style={{
          minWidth: "160px",
        }}
      />
    );
  }
);

export default SheetSelectMultiple;
