import { getValue } from "@utils/lodash";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { usePermissionContext } from "@context/permissionContext";
import {
  AINavigationList,
  CRMNavigationList,
  ChatNavigationList,
  GeneralNavigationList,
  HelpdestNavigationList,
  filterNavigationListNew,
} from "@pages/Private/Settings/helpers/settings-permissions";

const CompanyNavigationList = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { tabPermissionList, subTabPermissionList } = usePermissionContext();

  const getFun = () => {
    const { pathname } = location;

    if (pathname.includes("/general")) {
      return filterNavigationListNew(
        tabPermissionList,
        subTabPermissionList,
        GeneralNavigationList(params)
      );
    }
    if (pathname.includes("/crm")) {
      return filterNavigationListNew(
        tabPermissionList,
        subTabPermissionList,
        CRMNavigationList(params)
      );
    }
    if (pathname.includes("/helpdesk")) {
      return filterNavigationListNew(
        tabPermissionList,
        subTabPermissionList,
        HelpdestNavigationList(params)
      );
    }
    if (pathname.includes("/chat")) {
      return filterNavigationListNew(
        tabPermissionList,
        subTabPermissionList,
        ChatNavigationList(params)
      );
    }
    if (pathname.includes("/ai")) {
      return filterNavigationListNew(
        tabPermissionList,
        subTabPermissionList,
        AINavigationList(params)
      );
    }
    // Default case
    return filterNavigationListNew(
      tabPermissionList,
      subTabPermissionList,
      GeneralNavigationList(params)
    );
  };

  return getFun();
};

export default CompanyNavigationList;
