import convertMarkdownToHtml from "@common/markdown/useMarkdown";
import parse from "html-to-md";
import convertSingleLineMarkdownToHtml from "./useMarkdownSingleLine";

export const replacePlaceholders = (text: string): string | undefined => {
  if (text) {
    // Optionally parse Markdown if you want to render it
    let parsedMarkdown = parse(text);
    return transformText(parsedMarkdown);
  }
  return undefined; // Return undefined for empty or invalid input
};

function transformText(input: any) {
  if (input) {
    // Split the text into lines
    const lines = input.split("\n");
    // Process each line and apply the transformations
    return lines
      .map((line: string) => {
        let transformedLine = line.trim();

        // Handle lines with <del> and </del> (strikethrough)
        transformedLine = transformedLine.replace(/<del>(.*?)<\/del>/g, "~$1~");

        // Handle lines that contain '~~' (strikethrough)
        transformedLine = transformedLine.replace(/~~(.*?)~~/g, "~$1~");

        // First pass: Convert '**' (bold) to a temporary placeholder
        transformedLine = transformedLine.replace(
          /\*\*(.*?)\*\*/g,
          (match, p1) => `__BOLD__${p1}__BOLD__`
        );

        // Second pass: Convert '*' (italic) to '_'
        transformedLine = transformedLine.replace(
          /(?<!\*)\*(.*?)\*(?!\*)/g,
          "_$1_"
        );

        // Third pass: Restore '**' (bold) from the placeholder
        transformedLine = transformedLine.replace(
          /__BOLD__(.*?)__BOLD__/g,
          "*$1*"
        );

        return transformedLine; // Return transformed line
      })
      .join("\n");
  }
}

function transformTextToHtml(input: any) {
  if (input) {
    // Split the text into lines
    const lines = input.split("\n");

    // Process each line and reverse the transformations
    return lines
      .map((line: string) => {
        let revertedLine = line.trim();

        // Reverse strikethrough: ~text~ -> <del>text</del>
        // revertedLine = revertedLine.replace(/~(.*?)~/g, "<del>$1</del>");

        // Reverse bold: *text* -> **text**
        revertedLine = revertedLine.replace(/\*(.*?)\*/g, "**$1**");

        // Reverse italic: _text_ -> *text*
        revertedLine = revertedLine.replace(/_(.*?)_/g, "*$1*");

        return revertedLine; // Return reverted line
      })
      .join("\n");
  }
}

export const replaceDefaultPlaceholders = (text: any) => {
  let vari = transformTextToHtml(text);
  let replacedText = convertMarkdownToHtml(vari);
  return replacedText;
};

export const replaceDefaultPlaceholdersBySingleLine = (text: any) => {
  let vari = transformTextToHtml(text);
  let replacedText = convertSingleLineMarkdownToHtml(vari);
  return replacedText;
};

/* -------------------------------------------------------------------------- */
/*                                   For view                                 */
/* -------------------------------------------------------------------------- */

function transformTextToHtmlForView(input: any) {
  if (input) {
    // Split the text into lines
    const lines = input.split("\n");

    // Process each line and reverse the transformations
    return lines
      .map((line: any) => {
        let revertedLine = line.trim();

        // Exclude content within [[ ]] from transformations
        revertedLine = revertedLine.replace(
          /(\[\[.*?\]\])|(\*(.*?)\*|_(.*?)_)/g,
          (match: any, bracketed: any, formatted: any) => {
            if (bracketed) return bracketed; // Return bracketed content as-is
            if (formatted) {
              // Reverse bold: *text* -> **text**
              if (/^\*(.*?)\*$/.test(formatted))
                return `**${formatted.slice(1, -1)}**`;

              // Reverse italic: _text_ -> *text*
              if (/^_(.*?)_$/.test(formatted))
                return `*${formatted.slice(1, -1)}*`;
            }
            return match; // Return match unchanged if no rules apply
          }
        );

        return revertedLine; // Return reverted line
      })
      .join("\n");
  }
}

export const replaceDefaultPlaceholdersForView = (text: any) => {
  const safeText = typeof text === "string" ? text : "";

  let vari = transformTextToHtmlForView(safeText) || "";
  let replacedText = convertMarkdownToHtml(vari) || "";

  return replacedText;
};
