import AudioSvgComponent from "@assets/svg/audio";
import CloseSvgComponent from "@assets/svg/close";
import { convertCurrentDateWithTime } from "@common/date-helpers";
import { formatString } from "@common/text-helpers";
import ButtonComponent from "@components/Form/Button/Button";
import MP3PlayerComponent from "@components/common/Tooltip/mp3-player";
import { getValue } from "@utils/lodash";
import React from "react";
import { Button, Modal } from "antd";

function CallRecordingPopup(props: any) {
  const { isOpen, handleModal, callsRecordings } = props;
  return (
    <Modal
      open={getValue(props, `isOpen`, "")}
      // toggle={handleModal}
      width={800}
      onCancel={handleModal}
      onOk={handleModal}
      footer={[
        <Button key="cancel" onClick={handleModal} className="cancel__button">
          Cancel
        </Button>,
      ]}
      title="Call Recordings"
    >
      {/* <div className="border-bottom d-flex justify-content-between align-items-center px-5 py-3">
        <h6>Call Recordings</h6>
        <div onClick={handleModal}>
          <CloseSvgComponent size={14} />
        </div>
      </div> */}
      <div className="">
        <div className="">
          <table className={`common_detail_table w-100`}>
            <thead>
              <tr>
                <td>Type</td>
                <td>Start Time</td>
                <td>End Time</td>
                <td>Outcome</td>
                <td>Recordings</td>
              </tr>
            </thead>
            <tbody className={`card_table_body`}>
              {getValue(props, `callsRecordings`, []).map((item: object) => {
                return (
                  <tr
                  //   onClick={() =>
                  //     navigate(
                  //       `/${getValue(
                  //         params,
                  //         `orgId`,
                  //         ""
                  //       )}/calls/detail/${getValue(item, `id`, "")}`
                  //     )
                  //   }
                  >
                    <td>
                      <div className="d-flex align-items-center">
                        <p className="ms-2">{getValue(item, `type`, "")}</p>
                      </div>
                    </td>

                    <td>
                      <p className={`text`}>
                        {convertCurrentDateWithTime(
                          getValue(item, `start_datetime`, "")
                        )}
                      </p>
                    </td>
                    <td>
                      <p className={`text`}>
                        {convertCurrentDateWithTime(
                          getValue(item, `end_datetime`, "")
                        )}
                      </p>
                    </td>
                    <td>
                      <p className={`text`}>
                        {formatString(getValue(item, `outcome`, ""))}
                      </p>
                    </td>
                    {getValue(item, `recording_url`, "") && (
                      <td>
                        <div className="d-flex justify-content-center">
                          <MP3PlayerComponent
                            autoPlay
                            url={getValue(item, `recording_url`, "")}
                            onPlay={(e: any) => console.log("onPlay")}
                            header={<AudioSvgComponent color={"#f14b62"} />}
                          />
                        </div>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </Modal>
  );
}

export default CallRecordingPopup;
