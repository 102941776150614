import React, { useEffect, useState } from "react";
import TaskDetailLeftSection from "./LeftSection";
import TaskDetailMiddleSection from "./MiddleSection";
// import "./task-detail.scss";
import { QueryRequestHelper } from "@common/query-request-helper";
import { getValue } from "@utils/lodash";
import { toast } from "sonner";
import MainLayout from "@layouts/HomeLayout/NewLayout";
import { useParams } from "react-router-dom";
import {
  FormRequestHelperWithModule,
  FormRequestHelperWithModuleWithNullFields,
  appendObjectValuesToArray,
} from "@components/helpers/request-helper";
import ButtonComponent from "@components/Form/Button/Button";
import _ from "lodash";
import { motion } from "framer-motion";
import { listAllModules } from "@services/modules.service";
import { sortTaskFieldList } from "@components/Pages/Pipeline/helpers/create-pipeline-helper";
import { useStateContext } from "@context/profileProvider";
import { getModuleFieldsById } from "@services/modules.service";
import {
  getAllMeetings,
  getSpecificMeeting,
  updateMeeting,
} from "@services/meetings.service";
function MeetingsDetailSection(props: any) {
  const params = useParams();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  /* -------------------------------------------------------------------------- */
  /*                                 UseEffect Section                          */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getListData();
    getData(true);
    // getAllPipelines();
    //
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPipeline, setSelectedPipeline] = useState("");
  const [listLoading, setListLoading] = useState(true);
  const [list, setList] = useState([]);
  const getListData = async () => {
    try {
      let payload = {
        page_size: 100,
        page_no: 1,
        filter_owner_id: getValue(UrlParams, `filter_owner_id`, ""),
        filter_pipeline_stage_id: getValue(
          UrlParams,
          `filter_pipeline_stage_id`,
          ""
        ),

        filter_closing_date: getValue(UrlParams, `filter_closing_date`, ""),
        filter_created_at: getValue(UrlParams, `filter_created_at`, ""),
      };
      let queryRequest = QueryRequestHelper(payload);
      setListLoading(true);
      let resp = await getAllMeetings(queryRequest);
      if (resp) {
        setList(
          getValue(resp, `data.meetings`, []).map((item: object) => ({
            ...item,
            stage: getValue(item, `stage.name`, ""),
          }))
        );
        //   setTotalCount(getValue(resp, `data.pagination.total`, 0));
        setListLoading(false);
      } else {
        setList([]);
        setListLoading(false);
      }
    } catch (error) {
      setList([]);
      setListLoading(false);
    }
  };

  const [taskLoading, setTaskLoading] = useState(false);
  const handleTaskUpdate = async (id: string, status: boolean) => {
    try {
      setTaskLoading(true);
      let resp = await updateMeeting(id, {
        is_completed: status,
      });
      if (resp) {
        toast.success("Updated successfully");
        setTaskLoading(false);
        getListData();
        getData(false);
      } else {
        setTaskLoading(false);
      }
    } catch (error) {
      setTaskLoading(false);
    }
  };

  const [info, setInfo] = useState({});
  const [isTaskLoading, setIsTaskLoading] = useState(true);
  const getData = async (status: boolean) => {
    try {
      setIsTaskLoading(status);
      let resp = await getSpecificMeeting(getValue(params, `id`, ""));
      if (resp) {
        setInfo(getValue(resp, `data`, {}));
        getForm(getValue(resp, `data`, {}));
        setIsTaskLoading(false);
      } else {
        setIsTaskLoading(false);
      }
    } catch (error) {
      setIsTaskLoading(false);
    }
  };

  const [form, setForm] = useState([]);
  const [dirtyForm, setDirtyForm] = useState([]);

  const getForm = async (obj: object) => {
    try {
      let resp = await listAllModules("");
      if (resp) {
        let task_modules = getValue(resp, `data`, []).find(
          (item: object) => getValue(item, `api_name`, "") === "meetings"
        );
        let fields = await getModuleFieldsById(
          getValue(task_modules, `id`, "")
        );
        if (fields) {
          let list = sortTaskFieldList(getValue(fields, `data`, []));
          // for (const item of list) {
          //   if (item.api_name === "owner_id") {
          //     item.value = userId;
          //   }
          // }
          const aDict = list.reduce((dict: any, item: any) => {
            dict[item.name] = item;
            return dict;
          }, {});
          const ReorderedList: any = [];
          // Add elements from b in the order they appear in a
          for (const name of getValue(fields, `data`, [])) {
            if (aDict[name]) {
              ReorderedList.push(aDict[name]);
            }
          }
          // Add elements from a that are not in b to the end
          for (const item of list) {
            if (!getValue(fields, `data`, []).includes(item.name)) {
              ReorderedList.push(item);
            }
          }
          let array: any = appendObjectValuesToArray(ReorderedList, obj);
          setForm(array);
          setDirtyForm(array);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  // const getForm = async (obj: object) => {
  //   let payload = {
  //     module_name: "tasks",
  //   };
  //   let queryRequest = QueryRequestHelper(payload);
  //   let pipelines = await allPipelines(queryRequest);
  //   try {
  //     let response = await getSpecificPipeline(
  //       getValue(pipelines, `data[${0}].id`, "")
  //     );
  //     if (pipelines) {
  //       let fields = getValue(response, `data.fields`, []).map(
  //         (item: object) => ({
  //           ...getValue(item, `module_field`, {}),
  //           name: getValue(item, `name`, ""),
  //           required: getValue(item, `required`, ""),
  //           seq_num: getValue(item, `seq_num`, ""),
  //         })
  //       );
  //       let array: any = appendObjectValuesToArray(fields, obj);
  //       setForm(array);
  //       setDirtyForm(array);
  //     }
  //   } catch (error) {}
  // };
  /* -------------------------------------------------------------------------- */
  /*                         Form Submit Section                                */
  /* -------------------------------------------------------------------------- */
  let checkfieldRequestChanges = _.isEqual(form, dirtyForm);
  const handleReset = async () => {
    setForm(dirtyForm);
  };
  const handleCancel = () => {
    handleReset();
  };

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    try {
      let fieldReq = JSON.parse(JSON.stringify(form));
      let payloadNew = FormRequestHelperWithModuleWithNullFields(
        fieldReq,
        "meetings",
        {}
      );
      let requestPayload: any = { ...payloadNew };
      Object.keys(requestPayload).forEach((key) => {
        if (!requestPayload[key] || requestPayload[key] === undefined) {
          delete requestPayload[key];
        }
      });
      setSubmitLoading(true);
      let resp = await updateMeeting(getValue(params, `id`, ""), {
        ...requestPayload.meeting,
      });
      if (resp) {
        toast.success("Updated successfully");
        setSubmitLoading(false);
        getData(false);
        getListData();
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };
  return (
    <MainLayout>
      <div className={"task-detail_container"}>
        <TaskDetailLeftSection
          getListData={getListData}
          listLoading={listLoading}
          list={list}
          isLoading={isLoading}
          selectedPipeline={selectedPipeline}
          handleTaskUpdate={handleTaskUpdate}
          isChanged={checkfieldRequestChanges}
          permissions={getValue(props, `permissions`, [])}
        />
        <TaskDetailMiddleSection
          getListData={getListData}
          listLoading={listLoading}
          selectedPipeline={selectedPipeline}
          handleTaskUpdate={handleTaskUpdate}
          taskLoading={taskLoading}
          getData={getData}
          info={info}
          isLoading={isTaskLoading}
          form={form}
          setForm={setForm}
          isChanged={checkfieldRequestChanges}
          permissions={getValue(props, `permissions`, [])}
        />
        {!checkfieldRequestChanges && (
          <motion.div
            initial={{ y: 0, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.2, ease: "easeInOut" }}
            exit={{
              y: 50,
              opacity: 0,
              transition: { duration: 0.5, ease: "easeInOut" },
            }}
          >
            <div className={"footer_container"}>
              <div className={"button_container"}>
                <div className="d-flex align-items-center">
                  <ButtonComponent
                    buttonType={"scecondary"}
                    buttonText={"Cancel"}
                    onClickHandler={() => handleCancel()}
                    // squre
                  />
                  {getValue(props, `permissions`, []).includes("update") && (
                    <ButtonComponent
                      buttonType={"primary"}
                      buttonText={submitLoading ? "Please wait..." : "Update"}
                      onClickHandler={() => handleSubmit()}
                      disabled={submitLoading}
                      // squre
                    />
                  )}
                </div>
              </div>
              <p className={"text"}>You have unsaved changes</p>
            </div>
          </motion.div>
        )}
      </div>
    </MainLayout>
  );
}

export default MeetingsDetailSection;
