import { getValue } from "@utils/lodash";
import { Select } from "antd";
import React from "react";

const SheetBooleanSelectEditor = React.memo(
  ({ col, row, field, handleDisplayItem, handleInputChange }: any) => {
    const options = [
      { value: true, label: "Yes" },
      { value: false, label: "No" },
    ];

    return (
      <Select
        showSearch
        value={handleDisplayItem(col, row) || false}
        placeholder={`Select ${col}`}
        options={options}
        onChange={(selectedValue) =>
          handleInputChange(getValue(row, "id", ""), col, selectedValue, field)
        }
        style={{
          minWidth: "160px",
        }}
      />
    );
  }
);

export default SheetBooleanSelectEditor;
