import TicketDetailsPage from "@components/Pages/Tickets/Detail/tickets";
import Tickets from "@pages/Private/Tickets";

export const TicketRoutes = [
  {
    path: "/:orgId/tickets",
    name: "chatbot",
    component: Tickets,
  },
  {
    path: "/:orgId/tickets/detail/:id",
    name: "chatbot",
    component: TicketDetailsPage,
  },
];
