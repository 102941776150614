import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllCannedResponseFolders = (queryRequest: string) =>
  get(`${config.API_URL}/canned-response/folders?${queryRequest}`);

export const getAllCannedFolderTableResponse = (queryRequest: string) =>
  get(`${config.API_URL}/canned-response?${queryRequest}`);

export const getAllCannedResponse = (queryRequest: object) =>
  get(`${config.API_URL}/canned-response?${queryRequest}`);

export const getSpecificCannedResponse = (id: object) =>
  get(`${config.API_URL}/canned-response/${id}`);

export const getOrgLevelCannedResponse = (queryRequest: string) =>
  get(`${config.API_URL}/canned-response/content?${queryRequest}`);

export const createCannedResponseFolder = (payload: object) =>
  post(`${config.API_URL}/canned-response/folders`, payload);

export const createCannedResponse = (payload: object) =>
  post(`${config.API_URL}/canned-response`, payload);

export const updateCannedResponseFolder = (id: string, payload: object) =>
  patch(`${config.API_URL}/canned-response/folders/${id}`, payload);

export const updateCannedResponse = (id: string, payload: object) =>
  patch(`${config.API_URL}/canned-response/${id}`, payload);

export const deleteCannedResponseFolder = (id: string) =>
  Delete(`${config.API_URL}/canned-response/folders/${id}`);

export const deleteCannedResponse = (id: string) =>
  Delete(`${config.API_URL}/canned-response/${id}`);
