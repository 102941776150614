import { getValue } from "@utils/lodash";
import { Dropdown, MenuProps } from "antd";
import {
  EllipsisVertical,
  Maximize2,
  Minimize2,
  Pencil,
  Trash2,
} from "lucide-react";

const DashboardMoreOptions = ({
  id,
  confirmDeleteAnalyticsComponent,
  props,
  handleEditChart,
}: any) => {
  const items: MenuProps["items"] = [
    {
      key: "1",
      label: "Edit",
      icon: <Pencil size={16} />,
      disabled: false,
      onClick: () => handleEditChart(props),
    },
    {
      key: "2",
      label: "Delete",
      icon: <Trash2 size={16} />,
      onClick: () => confirmDeleteAnalyticsComponent(id),
    },
  ];

  return (
    <div
      className={`${
        getValue(props, "isMaximized", "")
          ? "maximized-dashboard-more-option"
          : "dashboard-more-option"
      }`}
    >
      {props?.isMaximized ? (
        <Minimize2
          size={14}
          onClick={() => {
            props.setSelectedForMaximize([]);
            props.setMaximized(false);
          }}
        />
      ) : (
        <Maximize2
          size={14}
          onClick={() => {
            props.setSelectedForMaximize(props);
            props.setMaximized(true);
          }}
        />
      )}
      <Dropdown menu={{ items }}>
        <EllipsisVertical size={16} />
      </Dropdown>
    </div>
  );
};

export default DashboardMoreOptions;
