import { useEffect, useState, useCallback } from "react";
import { toast } from "sonner";
import { getValue } from "@utils/lodash";
import { getListOfModules } from "@services/sla-policy.services";
import { handleSelectArrayChange } from "@common/handleChange";
import { Select, Alert } from "antd";
import { PlusCircle, Trash2 } from "lucide-react";

const SLATicketCondition = (props: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<any[]>([]);

  const getLookupAPIs = (value: string) => {
    switch (value) {
      case "companies":
        return "records/companies/list";
      case "group_id":
        return "groups/list";
      case "product_id":
        return "products/list";
      default:
        return "";
    }
  };

  const getOptionsForFieldType = useCallback(
    async (fieldName: string) => {
      const fieldObject = props.conditionList.find(
        (item: any) => item.fieldName === fieldName
      );

      if (!fieldObject) {
        console.error(
          `Field with name ${fieldName} not found in conditionList.`
        );
        return [];
      }

      const fieldType = getValue(fieldObject, "fieldType", "");

      if (fieldType === "lookup") {
        return await fetchLookupOptions(fieldName);
      }

      if (fieldType === "select") {
        return getValue(fieldObject, "dropDownOpts", []);
      }

      return [];
    },
    [props.conditionList]
  );

  const fetchLookupOptions = async (fieldName: string) => {
    setIsLoading(true);
    try {
      const resp = await getListOfModules(getLookupAPIs(fieldName));

      if (resp) {
        const modifiedResp = getValue(resp, "data", []).map((item: any) => ({
          value: getValue(item, "id", ""),
          label: getValue(item, "name", ""),
        }));
        return modifiedResp;
      }
    } catch (error) {
      toast.error("Failed to load options");
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const fetchOptions = async () => {
    const fieldName = getValue(
      props.request.sla_conditions[0],
      `field_name`,
      ""
    );
    const fieldOptions = await getOptionsForFieldType(fieldName);
    setOptions(fieldOptions);
  };

  useEffect(() => {
    if (props.editId) {
      props.request.sla_conditions.forEach((condition: any, index: number) => {
        if (condition.field_name) {
          fetchAndSetOptionsForCondition(condition.field_name, index);
        }
      });
    } else {
      fetchOptions();
    }
  }, [props.editId, props.conditionList]);

  const fetchAndSetOptionsForCondition = async (
    fieldName: string,
    index: number
  ) => {
    const fieldOptions = await getOptionsForFieldType(fieldName);
    setOptions((prevOptions) => {
      const newOptions = [...prevOptions];
      newOptions[index] = fieldOptions;
      return newOptions;
    });
  };

  const handleSelectCondition = (index: number, newValues: string[]) => {
    props.setRequest((prevRequest: any) => ({
      ...prevRequest,
      sla_conditions: prevRequest.sla_conditions.map(
        (condition: any, idx: number) => {
          if (idx !== index) return condition;
          return {
            ...condition,
            value: newValues,
          };
        }
      ),
    }));
  };

  const handleIfCondition = (index: number, value: any) => {
    handleSelectArrayChange(
      index,
      { value },
      "field_name",
      "sla_conditions",
      props.setRequest
    );
    props.setRequest((prevRequest: any) => {
      const updatedConditions = [...prevRequest.sla_conditions];
      updatedConditions[index] = {
        ...updatedConditions[index],
        value: [],
      };
      return {
        ...prevRequest,
        sla_conditions: updatedConditions,
      };
    });
    fetchAndSetOptionsForCondition(value, index);
  };

  const deleteConditionArray = (index: number) => {
    props.setRequest((request: any) => ({
      ...request,
      sla_conditions: request.sla_conditions.filter(
        (_: any, i: number) => i !== index
      ),
    }));
  };

  const addNewCondition = () => {
    props.setRequest((request: any) => {
      const newCondition = { field_name: "", value: [] };
      const updatedConditions = [...request.sla_conditions, newCondition];
      fetchAndSetOptionsForCondition("", updatedConditions.length - 1);
      return { ...request, sla_conditions: updatedConditions };
    });
  };

  return (
    <div className="mt-5">
      <h6 className="header_text__18">
        When a ticket satisfies these conditions:
      </h6>
      <div className="mt-2 sla-condition-card-wrapper">
        {getValue(props, "request.sla_conditions", []).map(
          (condition: any, index: number) => (
            <div className={`sla-condition-card p-4 border-bottom`} key={index}>
              <div className="d-grid gap-2 w-90">
                <Select
                  prefix="If"
                  onChange={(value) => handleIfCondition(index, value)}
                  value={getValue(condition, "field_name", "")}
                  placeholder="Choose Condition"
                  options={props.conditionList}
                  className="w-25"
                  loading={getValue(props, "isLoading1", false)}
                />
                {getValue(condition, "field_name", "") && (
                  <Select
                    mode="multiple"
                    size="large"
                    showSearch
                    value={getValue(condition, "value", [])}
                    allowClear
                    onChange={(newValues) =>
                      handleSelectCondition(index, newValues)
                    }
                    placeholder="Select one or more values"
                    options={options[index] || []}
                    loading={isLoading}
                  />
                )}
              </div>
              {props.request.sla_conditions.length > 1 && (
                <Trash2
                  size={18}
                  className="delete-sla-condition"
                  onClick={() => deleteConditionArray(index)}
                />
              )}
              {props.request.sla_conditions.length > 1 &&
                index !== props.request.sla_conditions.length - 1 && (
                  <div className="and-condition-button border">AND</div>
                )}
            </div>
          )
        )}
        <div
          className="header_blue_text__15 d-flex align-items-center gap-1 cursor-pointer px-4 py-3"
          onClick={addNewCondition}
        >
          <PlusCircle size={18} /> Add Condition
        </div>
      </div>
      <Alert
        showIcon
        type="info"
        message="Please choose at least one condition."
        className="w-fit-content mt-2"
      />
    </div>
  );
};

export default SLATicketCondition;
