import { QueryRequestHelper } from "@common/query-request-helper";
import { convertElementaryArrayToJSONArray } from "@common/text-helpers";
import ListLoader from "@components/common/Loader";
import {
  getWhatsappTemplateFromBackend,
  getWhatsappTemplateIndustry,
} from "@services/fb.service";
import { getValue } from "@utils/lodash";
import { Button, Pagination, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TemplateTable from "../components/template-messages/template-list";
import TemplateNoDataPage from "@components/common/NoData/template-nodata";
import StaticTemplateList from "./components/StaticTemplateList";

const StaticTemplateMessages = (props: any) => {
  const params = useParams();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const navigate = useNavigate();

  useEffect(() => {
    if (Object.keys(UrlParams).length === 0) {
      getData();
      getIndustryData();
    }
  }, []);

  useEffect(() => {
    if (Object.keys(UrlParams).length > 0) {
      if (UrlParams.page_no) {
        setPage_no(parseInt(UrlParams.page_no));
      }
      if (UrlParams.limit) {
        setLimit(parseInt(UrlParams.limit));
      }
      if (UrlParams.industry_id) {
        setIndustryId(parseInt(UrlParams.industry_id));
      }
      getData();
      getIndustryData();
    }
  }, [window.location.href]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(false);
  const [templateList, setTemplateList] = useState<any>([]);
  const getData = async () => {
    try {
      setIsLoading(true);
      let payload = {
        page_size: getValue(UrlParams, `limit`, "")
          ? getValue(UrlParams, `limit`, "")
          : limit,
        page_no: getValue(UrlParams, `page_no`, "")
          ? getValue(UrlParams, `page_no`, "")
          : page_no,
        industry_id: getValue(UrlParams, `industry_id`, "")
          ? getValue(UrlParams, `industry_id`, "")
          : industryId,
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getWhatsappTemplateFromBackend(queryRequest);
      if (resp) {
        setTemplateList(getValue(resp, `data.templates`, []));
        setTotalCount(getValue(resp, `data.total`, 0));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [isIndustryLoading, setIsIndustryLoading] = useState(false);
  const [industryList, setIndustryList] = useState<any>([]);
  const getIndustryData = async () => {
    try {
      setIsIndustryLoading(true);
      let resp = await getWhatsappTemplateIndustry();
      if (resp) {
        const industries = getValue(resp, `data.industries`, []).map(
          (item: any) => ({
            ...item,
            value: item.id,
          })
        );
        setIndustryList(industries);
        setIsIndustryLoading(false);
      } else {
        setIsIndustryLoading(false);
      }
    } catch (error) {
      setIsIndustryLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                 Filter section                             */
  /* -------------------------------------------------------------------------- */

  const handleFilter = (value: number) => {
    let payload = {
      ...UrlParams,
      industry_id: value,
      page_no: 1,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  const handleClearFilter = () => {
    const updatedParams = { ...UrlParams };
    delete updatedParams.industry_id;

    const queryRequest = QueryRequestHelper(updatedParams);
    navigate(`${window.location.pathname}?${queryRequest}`);
    setIndustryId(0);
  };

  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);
  const [industryId, setIndustryId] = useState(0);

  const handleChangePagination = (page_no: number, limit: number) => {
    setPage_no(page_no);
    setLimit(limit);

    let payload = {
      ...UrlParams,
      page_no: page_no,
      page_size: limit,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  return (
    <div>
      <div className="other-content subheader_filter_section open">
        <div className="filter-wrapper d-flex align-items-center justify-content-between my-1">
          <Select
            size="middle"
            showSearch
            placeholder="Select a industry"
            optionFilterProp="label"
            onClear={() => handleClearFilter()}
            onChange={(value: number) => handleFilter(value)}
            options={industryList}
            allowClear
            style={{
              width: "240px",
              height: "40px",
            }}
            loading={isIndustryLoading}
          />
        </div>
      </div>

      {isLoading ? (
        <ListLoader />
      ) : (
        <>
          {getValue(templateList, "length", "") > 0 ? (
            <>
              <StaticTemplateList
                templateData={templateList}
                params={params}
                totalCount={totalCount}
                page_no={page_no}
                page_size={limit}
                handleChangePagination={handleChangePagination}
              />

              <Pagination
                align="center"
                className="mt-4"
                total={totalCount}
                showTotal={(total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`
                }
                defaultPageSize={limit}
                defaultCurrent={page_no}
                onChange={handleChangePagination}
              />
            </>
          ) : (
            <TemplateNoDataPage />
          )}
        </>
      )}
    </div>
  );
};

export default StaticTemplateMessages;
