import DialcodePhoneInputCommon from "@components/custom/Forms/DialCodeInputCommon";
import { getValue } from "@utils/lodash";
import { Button, Popover } from "antd";
import React, { useEffect, useState } from "react";

const SheetPhoneEdit = React.memo(
  ({
    row,
    col,
    item,
    orgInfo,
    tempPhoneNumbers,
    handlePhoneInputChange,
    numberError,
    handleCancel,
    handleUpdate,
    handleDisplayItem,
  }: any) => {
    const [value, setValue] = useState(
      getValue(row, `[${col}]`, "") ? getValue(row, `[${col}]`, "") : null
    );

    // Update value if col.time changes
    useEffect(() => {
      setValue(
        getValue(row, `[${col}]`, "") ? getValue(row, `[${col}]`, "") : null
      );
    }, [getValue(row, `[${col}]`, "")]);
    return (
      <Popover
        content={
          <div>
            <DialcodePhoneInputCommon
              {...item}
              inputWidth="w-60"
              countryWidth="w-40"
              onChange={(newPhoneNumber: any) =>
                handlePhoneInputChange(newPhoneNumber, row.id, col)
              }
              type={"text"}
              // onBlur={(e: any) =>
              //   getValue(item, `unique`, false)
              //     ? handleBlur(e, getValue(item, `api_name`, ""))
              //     : handleNoBlur
              // }
              value={value}
              placeholder={`Enter ${getValue(item, `label`, "")}`}
              label={`${getValue(item, `label`, "")}`}
              // validator={!hideValidation && required && simpleValidator}
              // mandatory={!hideValidation && required}
              // index={index}
              // setFields={setFields}
              // module={props.module}
              // duplicateAPINames={duplicateAPINames}
              // setDuplicateAPINames={setDuplicateAPINames}
              // api_name={getValue(item, `api_name`, "")}
              // phoneInline={props.phoneInline}
              // codeSelectorWdith="w-30"
              // phoneInputWdith="w-70"
              // disabled={props.disabled}
              // pipeline_id={pipeline_id}
            />

            {/* <div
              className={`${
                numberError === "Validated"
                  ? "number-validated"
                  : "number-notvalidated"
              }`}
            >
              {numberError}
            </div> */}
            <div className="d-flex gap-2 justify-content-center align-items-center mt-2">
              <Button onClick={() => handleCancel(row.id, col)}>Cancel</Button>
              <Button type="primary" onClick={() => handleUpdate(row.id, col)}>
                Update
              </Button>
            </div>
          </div>
        }
        // title={getValue(item, "label", "")}
        trigger="click"
        placement="bottom"
      >
        <div className="w-100 text-center">
          {tempPhoneNumbers[row.id]?.[col] || handleDisplayItem(col, row)
            ? handleDisplayItem(col, row)
            : "-"}
        </div>
      </Popover>
    );
  }
);

export default SheetPhoneEdit;
