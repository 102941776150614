export const config = {
  API_URL: `https://backend.dev.supercrm.ai/v1`,
  // API_URL:'http://localhost:4000/v1',
  URL: `apps-dev-assets.blr1.cdn.digitaloceanspaces.com`,
  ASSETS_CDN_ENDPOINT: "apps-dev-assets.blr1.cdn.digitaloceanspaces.com",
  MAIN_URL: `https://dev.superteamonline.com/`,
  PUSHER_APP_KEY: `3d6b1dfa2df3bc9896f3`,
  PUSHER_APP_CLUSTER: `ap2`,
  REACT_APP_GOOGLE_MAPS_API_KEY: `AIzaSyCvzAcBeETi8eSSUzUuK6HjziyWAAE3-Mo`,
};
