import { config } from "../env";
import {
  Delete,
  DeleteWithPayload,
  get,
  patch,
  post,
} from "./helpers/http-handler";

export const getAllGroups = (queryRequest: string) =>
  get(`${config.API_URL}/groups?${queryRequest}`);

export const getSpecificGroups = (id: string) =>
  get(`${config.API_URL}/groups/${id}`);

export const getSpecificGroupsAgents = (id: string) =>
  get(`${config.API_URL}/groups/${id}/agents`);

export const getSpecificGroupsList = (queryRequest: string) =>
  get(`${config.API_URL}/groups/list?${queryRequest}`);

export const createGroups = (payload: object) =>
  post(`${config.API_URL}/groups`, payload);

export const updateSpecificGroups = (id: string, payload: object) =>
  patch(`${config.API_URL}/groups/${id}`, payload);

export const updateGroupsAgent = (id: string, payload: object) =>
  patch(`${config.API_URL}/groups/${id}/agents`, payload);

export const deleteGroups = (id: string) =>
  Delete(`${config.API_URL}/groups/${id}`);

export const deleteGroupsAgents = (id: string, payload: object) =>
  DeleteWithPayload(`${config.API_URL}/groups/${id}/agents`, payload);
