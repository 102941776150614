import React from "react";
import {
  convertCurrentDate,
  convertCurrentDateWithTime,
} from "@common/date-helpers";
import ActivityLoader from "@components/common/Loader/activity-loader";
import { getValue } from "@utils/lodash";
import { useNavigate, useParams } from "react-router-dom";
import FormNoDataPage from "@components/common/NoData/form-nodata";
import { Switch, Table } from "antd";
import { capitalizeFirstLetter } from "@common/text-helpers";
import ListLoader from "@components/common/Loader";

function FormCreatorTable(props: any) {
  const { isLoading, list } = props;
  const navigate = useNavigate();
  const params = useParams();

  const columns = [
    {
      title: "TITLE",
      dataIndex: "title",
      key: "title",
      render: (title: any, record: any) => (
        <div
          className="cursor-pointer header_blue_text__14"
          onClick={() =>
            navigate(
              `/${getValue(
                params,
                `orgId`,
                ""
              )}/settings/crm/forms/edit/${getValue(record, `id`, "")}`
            )
          }
        >
          {capitalizeFirstLetter(title)}
        </div>
      ),
    },
    {
      title: "MODULE",
      dataIndex: "module",
      key: "module",
      render: (text: any, record: any) =>
        capitalizeFirstLetter(getValue(record, `module.api_name`, "")),
    },
    {
      title: "CREATED BY",
      dataIndex: "created_by",
      key: "created_by",
      render: (record: any) => {
        const firstName = record.first_name;
        const lastName = record.last_name;
        return `${firstName} ${lastName}`;
      },
    },
    {
      title: "CREATED AT",
      dataIndex: "created_at",
      key: "created_at",
      render: (record: any, text: any) =>
        convertCurrentDateWithTime(getValue(text, `created_at`, "")),
    },
    {
      title: "ACTION",
      dataIndex: "is_active",
      key: "is_active",
      render: (is_active: any, record: any) => (
        <Switch
          size="small"
          checked={is_active}
          onChange={() =>
            props.handleChangeStatus(getValue(record, `id`, ""), !is_active)
          }
        />
      ),
    },
  ];
  return (
    <>
      {isLoading ? (
        <ListLoader />
      ) : (
        <>
          {list.length > 0 ? (
            <div className="px-4 pt-4 pb-4">
              {/* <table className="common_table w-100">
                <thead>
                  <tr>
                    <td>Title</td>
                    <td>Module</td>
                    <td>Created by</td>
                    <td>Action</td>
                  </tr>
                </thead>
                <tbody>
                  {list.map((item: object) => {
                    return (
                      <tr>
                        <td
                          className="header_blue_text__14 touchable-text"
                          onClick={() =>
                            navigate(
                              `/${getValue(
                                params,
                                `orgId`,
                                ""
                              )}/settings/forms/edit/${getValue(
                                item,
                                `id`,
                                ""
                              )}`
                            )
                          }
                        >
                          {getValue(item, `title`, "")}
                        </td>
                        <td
                          onClick={() =>
                            navigate(
                              `/${getValue(
                                params,
                                `orgId`,
                                ""
                              )}/settings/forms/edit/${getValue(
                                item,
                                `id`,
                                ""
                              )}`
                            )
                          }
                        >
                          {getValue(item, `module.label`, "")}
                        </td>
                        
                        <td
                          onClick={() =>
                            navigate(
                              `/${getValue(
                                params,
                                `orgId`,
                                ""
                              )}/settings/forms/edit/${getValue(
                                item,
                                `id`,
                                ""
                              )}`
                            )
                          }
                        >
                          {getValue(item, `created_by.first_name`, "")}{" "}
                          {getValue(item, `created_by.last_name`, "")}
                          <p className="small_text__12">
                            {convertCurrentDateWithTime(
                              getValue(item, `created_at`, "")
                            )}
                          </p>
                        </td>
                        <td>
                          <label className={"switch"}>
                            <input
                              type="checkbox"
                              checked={getValue(item, `is_active`, false)}
                              onChange={() =>
                                props.handleChangeStatus(
                                  getValue(item, `id`, ""),
                                  !getValue(item, `is_active`, false)
                                )
                              }
                            />
                            <span
                              className={`${"slider"} ${`round`} d-flex`}
                            ></span>
                          </label>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table> */}
              <Table
                size="middle"
                bordered
                columns={columns}
                dataSource={list}
                rowKey="id"
              />
            </div>
          ) : (
            <div className="pt-5">
              <FormNoDataPage />
            </div>
          )}
        </>
      )}
    </>
  );
}

export default FormCreatorTable;
