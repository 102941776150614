import Dashboard from "@pages/Private/Dashboard";
import NewDashboard from "@pages/Private/NewDashboard";
import ModuleImportLogs from "@pages/Private/Settings/ImportLogs";

export const DashboardRoutes = [
  // dashboard
  {
    path: "/:orgId/dashboard",
    name: "dashboard_crm_overview",
    component: Dashboard,
  },
  {
    path: "/:orgId/new-dashboard",
    name: "dashboard_crm_overview",
    component: NewDashboard,
  },
  {
    path: "/:orgId/crm/:module/imports",
    name: "dashboard_crm_overview",
    component: ModuleImportLogs,
  },
];
