import SettingsGroups from "@pages/Private/Settings/Groups";
import CreateGroup from "@pages/Private/Settings/Groups/EditGroupSettings";

export const SettingsGroupsRoutes = [
  {
    path: "/:orgId/settings/groups",
    name: "settings_templates_business_hours",
    component: SettingsGroups,
  },
  {
    path: "/:orgId/settings/groups/edit/:id",
    name: "settings_templates_business_hours",
    component: CreateGroup,
  },
];
