import { useRef } from "react";
import { Dropdown, Tooltip } from "antd";
import DocumentSvgComponent from "@assets/svg/document";
import { aiRephraseMethods } from "@components/AI/AiRephraseMethods";
import AIStars from "../Loader/ai-stars";

function WhatsappAddHover(props) {
  const { header, toggleAiModal, isRephraseLoading, contentLength } = props;

  const fileInputRef = useRef(null);

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    props.handleUploadDocument(e);
  };

  const dropdownItems = [
    {
      key: "1",
      icon: <DocumentSvgComponent size={20} color={"#7f67ff"} />,
      label: (
        <div className="d-flex align-items-center" onClick={handleUploadClick}>
          <p className="ms-2">Media</p>
          <input
            type="file"
            className="visually-hidden"
            onChange={handleFileChange}
            ref={fileInputRef}
          />
        </div>
      ),
    },
    // {
    //   key: "2",
    //   icon: <AIStars />,
    //   label: (
    //     <Tooltip
    //       title={contentLength < 12 ? "Write a message to let AI help you" : ""}
    //     >
    //       AI Rewrite
    //     </Tooltip>
    //   ),
    //   children: aiRephraseMethods.map((method, index) => ({
    //     key: `ai-method-${index}`,
    //     label: method,
    //     onClick: () => {
    //       toggleAiModal(method);
    //     },
    //     disabled: isRephraseLoading === true || contentLength < 12,
    //   })),
    // },
  ];

  return (
    <div className="wa-document-upload">
      <Dropdown menu={{ items: dropdownItems }} trigger={["hover"]}>
        <div onClick={(e) => e.preventDefault()}>{header}</div>
      </Dropdown>
    </div>
  );
}

export default WhatsappAddHover;

// import React, { useRef } from "react";
// import Popup from "reactjs-popup";
// import { getValue } from "@utils/lodash";
// import DocumentSvgComponent from "@assets/svg/document";
// import ImageSvgComponent from "@assets/svg/image";

// function WhatsappAddHover(props) {
//   const POSITION_TYPES = [
//     "bottom right",
//     "bottom center",
//     "top center",
//     "left top",
//     "left bottom",
//     "right top",
//     "top left",
//     "top center",
//     "right center",
//     "right bottom",
//     "bottom left",
//     "left center",
//     "center center",
//   ];

//   const popupRef = useRef();
//   const handleClose = (e) => {
//     e.stopPropagation();
//     if (popupRef.current) {
//       popupRef.current.close();
//     }
//   };
//   const handleOpen = () => {
//     if (popupRef.current) {
//       popupRef.current.open();
//     }
//   };

//   const CustomButtons = React.forwardRef(({ open, ...props }, ref) => (
//     <div
//       className="d-flex justify-content-between align-items-center cursor-pointer"
//       {...props}
//       ref={ref}
//     >
//       {props.header}
//     </div>
//   ));

//   const fileInputRef = useRef(null);

//   const handleUploadClick = () => {
//     // Trigger click on the hidden file input
//     fileInputRef.current.click();
//   };

//   const handleFileChange = (e) => {
//     // Handle file upload here
//     const file = e.target.files[0];
//     props.handleUploadDocument(e);
//     // Do whatever you need with the file
//   };

//   return (
//     <Popup
//       trigger={(open) => <CustomButtons open={open} header={props.header} />}
//       position={POSITION_TYPES}
//       closeOnDocumentClick
//       contentStyle={{
//         width: getValue(props, `width`, "")
//           ? getValue(props, `width`, "")
//           : "400px",
//         minHeight: getValue(props, `height`, "")
//           ? getValue(props, `height`, "")
//           : "70px",
//         zIndex: 9999,
//         background: "#fff",
//         boxShadow:
//           "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
//       }}
//       nested
//       ref={popupRef}
//       onOpen={handleOpen}
//       onClick={handleClose}
//       arrow={false}
//     >
//       <div className="position-relative">
//         <div
//           className="d-flex align-items-center whatsapp_document"
//           onClick={handleUploadClick}
//         >
//           <DocumentSvgComponent size={26} color={"#7f67ff"} />
//           <p className="small_text__16 ms-2">Media</p>
//           <input
//             type="file"
//             className="visually-hidden" // This class will hide the input visually
//             onChange={handleFileChange}
//             ref={fileInputRef}
//           />
//         </div>
//         {/* <div className="d-flex align-items-center whatsapp_document">
//           <ImageSvgComponent size={26} color={"#007bfd"} />
//           <p className="small_text__16 ms-2">Photos & Videos</p>
//         </div> */}
//       </div>
//     </Popup>
//   );
// }

// export default WhatsappAddHover;
