import "./Tickets.scss";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import { QueryRequestHelper } from "common/query-request-helper";
import _ from "lodash";
import { toast } from "sonner";
import { allPipelines, getSpecificPipeline } from "@services/pipeline.service";
import SimpleReactValidator from "simple-react-validator";
import {
  createTicket,
  getSpecificTicket,
  updateTicket,
} from "@services/tickets.service";
import MainLayout from "@layouts/HomeLayout/NewLayout";
import {
  associationSubPipeline,
  disAssociationSubPipeline,
} from "@services/common.service";
import { useStateContext } from "context/profileProvider";
import {
  FormRequestHelper,
  FormRequestHelperWithNullFields,
  appendObjectValuesToArray,
} from "@components/helpers/request-helper";
import ButtonComponent from "@components/Form/Button/Button";
import { motion } from "framer-motion";
import { getAllModuleFields } from "@services/module-fields.service";
import CommonModuleDetailPage from "./main/CommonTicketDetailPage";
import ListLoader from "@components/common/Loader";
import CommonTicketDetailPage from "./main/CommonTicketDetailPage";
import { commonCustomFilter } from "@services/smart-filter.service";
import { removeNullOrUndefinedProperties } from "@common/text-helpers";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IPipelineDetailsProps {}

const TicketDetailsPage: React.FunctionComponent<IPipelineDetailsProps> = (
  props
) => {
  // const params = useParams();
  // const navigate = useNavigate();
  // const urlSearchParams = new URLSearchParams(window.location.search);
  // const UrlParams = Object.fromEntries(urlSearchParams.entries());
  // const { selectedModuleId, allModules } = useStateContext();
  // const [pipelineId, setPipelineId] = React.useState("");
  // const [selectedTicket, setSelectedTicket] = React.useState("");
  // const [selectedOption, setSelectedOption] = React.useState({});

  // /* ---------------------------  Pipeline By ID  -------------------------------- */
  // const [staticFieldRequest, setStaticFieldRequest] = React.useState({
  //   pipeline_id: "",
  //   stage: "",
  // });
  // const [stageList, setStageList] = React.useState<any>([]);

  // /* -------------------------------------------------------------------------- */
  // /*                               UseEffect Section                            */
  // /* -------------------------------------------------------------------------- */
  // React.useEffect(() => {
  //   getData(true);
  //   setSelectedTicket(getValue(params, "id", ""));
  //   getAllPipelines();
  // }, [params]);

  // React.useEffect(() => {
  //   if (pipelineId) {
  //     getListData(pipelineId, true);
  //   }
  // }, [pipelineId]);

  // /* -------------------------------------------------------------------------- */
  // /*                                  API Section                               */
  // /* -------------------------------------------------------------------------- */
  // const [isPipelineLoading, setIsPipelineLoading] = React.useState(false);
  // const [options, setOptions] = React.useState([]);
  // const getAllPipelines = async () => {
  //   try {
  //     setIsPipelineLoading(true);
  //     let payload = {};
  //     let queryRequest = QueryRequestHelper(payload);
  //     let resp = await allPipelines(await selectedModuleId(), queryRequest);
  //     if (resp) {
  //       console.log("resp", resp);
  //       let list =
  //         getValue(resp, `data.length`, 0) > 0
  //           ? getValue(resp, `data`, []).map((item: object) => ({
  //               ...item,
  //               value: getValue(item, `api_name`, ""),
  //               label: getValue(item, `label`, ""),
  //             }))
  //           : [];
  //       //**----------------------- Pipeline Section ---------------- */
  //       setOptions(list);
  //       //**----------------------- Status & filterview section ---------------- */
  //       if (getValue(UrlParams, `pipeline`, "")) {
  //         setStaticFieldRequest((prevStaticFieldRequest) => ({
  //           ...prevStaticFieldRequest,
  //           pipeline_id: getValue(UrlParams, "pipeline", ""),
  //         }));
  //         getData(getValue(UrlParams, `pipeline`, ""));
  //         getListData(getValue(UrlParams, "pipeline", ""), true);
  //       } else {
  //         setStaticFieldRequest((prevStaticFieldRequest) => ({
  //           ...prevStaticFieldRequest,
  //           pipeline_id: getValue(list, `[${0}].id`, ""),
  //         }));
  //         getData(getValue(list, `[${0}].id`, {}));
  //         getListData(getValue(list, `[${0}].id`, {}), true);
  //       }
  //       setIsPipelineLoading(false);
  //     } else {
  //       setIsPipelineLoading(false);
  //       setListLoading(false);
  //     }
  //   } catch (error) {
  //     setIsPipelineLoading(false);
  //     setListLoading(false);
  //   }
  // };

  // const [data, setData] = React.useState<any>({});
  // const [isLoading, setIsLoading] = React.useState(true);
  // const [associatedContacts, setAssociatedContacts] = React.useState([]);
  // const getData = async (loading: boolean) => {
  //   try {
  //     setIsLoading(loading);
  //     let response = await getSpecificTicket(getValue(params, `id`, ""));
  //     if (response) {
  //       let resp = await getSpecificPipeline(
  //         selectedModuleId(),
  //         getValue(response, `data.pipeline_id`, "")
  //       );
  //       setSelectedOption(getValue(resp, `data`, {}));
  //       setPipelineId(getValue(response, `data.pipeline_id`, ""));
  //       setStageList(
  //         getValue(resp, `data.stages`, []).map((item: object) => ({
  //           ...item,
  //           label: getValue(item, `label`, ""),
  //           value: getValue(item, `api_name`, ""),
  //         }))
  //       );
  //       let fields =
  //         getValue(resp, `data.form_fields.length`, 0) > 0
  //           ? getValue(resp, `data.form_fields`, []).map((item: object) => ({
  //               ...getValue(item, `module_field`, {}),
  //               name: getValue(item, `api_name`, ""),
  //               quick_add: getValue(item, `quick_add`, ""),
  //               required: getValue(item, `required`, ""),
  //               seq_num: getValue(item, `seq_num`, ""),
  //             }))
  //           : [];
  //       // ---------------- Appending Fields Data ----------------- //
  //       let array: any = appendObjectValuesToArray(
  //         fields,
  //         getValue(response, `data`, {})
  //       );

  //       let fieldReq =
  //         getValue(fields, `length`, 0) > 0
  //           ? fields.filter(
  //               (item: object) =>
  //                 getValue(item, `api_name`, "") !== "name" &&
  //                 getValue(item, `api_name`, "") !== "email"
  //             )
  //           : [];
  //       let fieldPopReq =
  //         getValue(fields, `length`, 0) > 0
  //           ? fields.filter(
  //               (item: object) =>
  //                 getValue(item, `api_name`, "") === "name" ||
  //                 getValue(item, `api_name`, "") === "email"
  //             )
  //           : [];

  //       setFields(fieldReq);
  //       setDirtyFields(fieldReq);
  //       setPopupFields(fieldPopReq);
  //       setDirtyPopupFields(fieldPopReq);
  //       // ---------------- Appending State Data ----------------- //
  //       setData(getValue(response, `data`, {}));
  //       let leftReq = {
  //         name: getValue(response, `data.name`, ""),
  //         email: getValue(response, `data.email`, ""),
  //         stage: getValue(response, `data.pipeline_stage.id`, ""),
  //       };
  //       setLeftRequest({
  //         ...leftRequest,
  //         ...leftReq,
  //       });
  //       setDirtyLeftRequest({
  //         ...dirtyLeftRequest,
  //         ...leftReq,
  //       });
  //       setIsLoading(false);
  //     } else {
  //       setIsLoading(false);
  //     }
  //   } catch (error) {
  //     setIsLoading(false);
  //   }
  // };
  // const urlParamsFun = () => {
  //   const urlSearchParams = new URLSearchParams(window.location.search);
  //   const urlParams = Object.fromEntries(urlSearchParams.entries());
  //   let request = QueryRequestHelper({ ...urlParams });
  //   return request;
  // };

  // const mainQueryRequest = React.useMemo(() => urlParamsFun(), [
  //   window.location.href,
  // ]);

  /* -------------------------------------------------------------------------- */
  /*                               Left Section                                 */
  /* -------------------------------------------------------------------------- */

  // const [leftRequest, setLeftRequest] = React.useState({
  //   name: "",
  //   email: "",
  //   stage: "",
  // });
  // const [dirtyLeftRequest, setDirtyLeftRequest] = React.useState({
  //   name: "",
  //   email: "",
  //   stage: "",
  // });
  // const [fields, setFields] = React.useState([]);
  // const [dirtyFields, setDirtyFields] = React.useState([]);

  // const [popupFields, setPopupFields] = React.useState([]);
  // const [dirtyPopupFields, setDirtyPopupFields] = React.useState([]);

  // let checkLeftRequestChanges = _.isEqual(leftRequest, dirtyLeftRequest);
  // let checkfieldRequestChanges = _.isEqual(fields, dirtyFields);
  // const { userId } = useStateContext();
  /* -------------------------------------------------------------------------- */
  /*                               Middle Section                               */
  /* -------------------------------------------------------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                               Right Section                                */
  /* -------------------------------------------------------------------------- */

  // const simpleValidator = React.useRef(new SimpleReactValidator());
  // const [, forceUpdate] = React.useState(0);
  // const [selectedPipeline, setSelectedPipeline] = React.useState("");
  // const [form, setForm] = React.useState([]);
  // const [stage, setStage] = React.useState<any>([]);
  // const [formLoading, setFormLoading] = React.useState(false);
  // const [formSubmitLoading, setFormSubmitLoading] = React.useState(false);
  // const [formStageList, setFormStageList] = React.useState<any>([]);

  // const getForm = async (module: string) => {
  //   setAssociationForm([]);
  //   if (module !== "tasks" && module !== "meetings" && module !== "calls") {
  //     try {
  //       let payload = {
  //         module_name: module,
  //       };
  //       let queryRequest = QueryRequestHelper(payload);
  //       setFormLoading(true);
  //       let findId =
  //         getValue(allModules, `length`, 0) > 0
  //           ? allModules.find(
  //               (item: object) =>
  //                 getValue(item, `api_name`, "") ===
  //                 (module === "sales" ? "deals" : module)
  //             )
  //           : [];
  //       let resp = await allPipelines(getValue(findId, `id`, ""), queryRequest);
  //       if (resp) {
  //         getAssociationPipelineInfo(
  //           getValue(resp, `data[${0}].id`, ""),
  //           module
  //         );
  //         setFormStageList(
  //           getValue(resp, `data[${0}].stages`, []).map((item: object) => ({
  //             ...item,
  //             label: getValue(item, `label`, ""),
  //             value: getValue(item, `api_name`, ""),
  //           }))
  //         );
  //         setFormLoading(false);
  //       } else {
  //         setFormLoading(false);
  //       }
  //     } catch (error) {
  //       setFormLoading(false);
  //     }
  //   } else {
  //     try {
  //       let code =
  //         getValue(allModules, `length`, 0) > 0
  //           ? allModules.find(
  //               (item: object) => getValue(item, `api_name`, "") == module
  //             )
  //           : [];
  //       let resp = await getAllModuleFields(getValue(code, `id`, ""));
  //       if (resp) {
  //         let fields =
  //           getValue(resp, `data.length`, 0) > 0
  //             ? getValue(resp, `data`, []).map((item: object) => ({
  //                 ...getValue(item, `module_field`, {}),
  //                 name: getValue(item, `module_field.api_name`, ""),
  //                 quick_add: getValue(item, `quick_add`, ""),
  //                 required: getValue(item, `required`, ""),
  //                 seq_num: getValue(item, `seq_num`, ""),
  //               }))
  //             : [];
  //         for (const item of fields) {
  //           if (item.api_name === "owner_id") {
  //             item.value = userId;
  //           }
  //         }
  //         setAssociationForm(fields);
  //       }
  //     } catch (error) {}
  //   }
  // };
  // const [associationForm, setAssociationForm] = React.useState([]);
  // const getAssociationPipelineInfo = async (id: string, module: string) => {
  //   if (id) {
  //     try {
  //       let resp = await getSpecificPipeline(await selectedModuleId(), id);
  //       if (resp) {
  //         let fields =
  //           getValue(resp, `data.form_fields.length`, 0) > 0
  //             ? getValue(resp, `data.form_fields`, []).map((item: object) => ({
  //                 ...getValue(item, `module_field`, {}),
  //                 name: getValue(item, `name`, ""),
  //                 quick_add: getValue(item, `quick_add`, ""),
  //                 required: getValue(item, `required`, ""),
  //                 seq_num: getValue(item, `seq_num`, ""),
  //               }))
  //             : [];
  //         for (const item of fields) {
  //           if (item.api_name === "owner_id") {
  //             item.value = userId;
  //           }
  //         }
  //         setStaticFieldRequest((prevStaticFieldRequest) => ({
  //           ...prevStaticFieldRequest,
  //           stage: getValue(resp, `data.stages[${0}].id`, ""),
  //           pipeline_id: id,
  //         }));
  //         setAssociationForm(fields);
  //         setFormLoading(false);
  //       } else {
  //         setFormLoading(false);
  //       }
  //     } catch (error) {}
  //   } else {
  //     setForm([]);
  //   }
  // };
  // const getSpecificPipelineInfo = async (id: string, module: string) => {
  //   if (id) {
  //     try {
  //       let resp = await getSpecificPipeline(await selectedModuleId(), id);
  //       if (resp) {
  //         let fields =
  //           getValue(resp, `data.form_fields.length`, 0) > 0
  //             ? getValue(resp, `data.form_fields`, []).map((item: object) => ({
  //                 ...getValue(item, `module_field`, {}),
  //                 name: getValue(item, `api_name`, ""),
  //                 quick_add: getValue(item, `quick_add`, ""),
  //                 required: getValue(item, `required`, ""),
  //                 seq_num: getValue(item, `seq_num`, ""),
  //               }))
  //             : [];
  //         for (const item of fields) {
  //           if (item.api_name === "owner_id") {
  //             item.value = userId;
  //           }
  //         }
  //         setForm(fields);
  //         if (module !== "tasks") {
  //           setStageList(
  //             getValue(resp, `data.stages`, []).map((item: object) => ({
  //               ...item,
  //               label: getValue(item, `label`, ""),
  //               value: getValue(item, `api_name`, ""),
  //             }))
  //           );
  //           setStaticFieldRequest((prevStaticFieldRequest) => ({
  //             ...prevStaticFieldRequest,
  //             stage: getValue(resp, `data.stages[${0}].id`, ""),
  //             pipeline_id: id,
  //           }));
  //         }
  //         setFormLoading(false);
  //       } else {
  //         setFormLoading(false);
  //       }
  //     } catch (error) {}
  //   } else {
  //     setForm([]);
  //   }
  // };
  // const handleCreateForm = async (module: string) => {
  //   if (module) {
  //     const formValid = simpleValidator.current.allValid();
  //     if (!formValid) {
  //       simpleValidator.current.showMessages();
  //       forceUpdate(1);
  //     } else {
  //       let fields = JSON.parse(JSON.stringify(form));
  //       let payload = FormRequestHelper(fields);
  //       let requestPayload: any = payload;
  //       requestPayload["pipeline_id"] = selectedPipeline;
  //       requestPayload["stage"] = getValue(stage, `[${0}].value`, "");
  //       requestPayload["sale_id"] = getValue(params, `id`, "");
  //       try {
  //         setFormSubmitLoading(true);
  //         let resp;
  //         if (module === "tickets") {
  //           resp = await createTicket(requestPayload);
  //         }
  //         if (resp) {
  //           toast.success("Created & Associated Successfully");
  //           setFormSubmitLoading(false);
  //           getData(false);
  //           simpleValidator.current.hideMessages();
  //           forceUpdate(0);
  //         } else {
  //           setFormSubmitLoading(false);
  //         }
  //       } catch (error) {
  //         setFormSubmitLoading(false);
  //       }
  //     }
  //   }
  // };
  // /* ---------------------------  Association / DisAssociate Pipeline  --------------------- */

  // const handleAssociate = async (id: string, module: string) => {
  //   if (module) {
  //     let payload = {
  //       api_name: module,
  //       record_id: id,
  //     };
  //     let resp = await associationSubPipeline(
  //       "tickets",
  //       getValue(params, `id`, ""),
  //       payload
  //     );
  //     if (resp) {
  //       getData(false);
  //       toast.success("Associated successfully");
  //     }
  //   }
  // };
  // const handleDisAssociate = async (id: string, module: string) => {
  //   if (module) {
  //     let payload = {
  //       api_name: module,
  //       record_id: id,
  //     };
  //     let resp = await disAssociationSubPipeline(
  //       "tickets",
  //       getValue(params, `id`, ""),
  //       payload
  //     );
  //     if (resp) {
  //       getData(false);
  //       toast.success("Disassociated successfully");
  //     }
  //   }
  // };

  // /* -------------------------------------------------------------------------- */
  // /*                               All data Section                             */
  // /* -------------------------------------------------------------------------- */
  // const [listLoading, setListLoading] = React.useState(true);
  // const [ticketList, setTicketList] = React.useState([]);
  // const getListData = async (pipelineId: string, status: boolean) => {
  //   if (typeof pipelineId === "string") {
  //     try {
  //       let payload = {
  //         pipeline_id: pipelineId,
  //         module_id: await selectedModuleId(),
  //         filter_owner_id: getValue(UrlParams, `filter_owner_id`, ""),
  //         filter_pipeline_stage_id: getValue(
  //           UrlParams,
  //           `filter_pipeline_stage_id`,
  //           ""
  //         ),
  //         filter_closing_date: getValue(UrlParams, `filter_closing_date`, ""),
  //         filter_created_at: getValue(UrlParams, `filter_created_at`, ""),
  //         page_size: getValue(UrlParams, `limit`, "")
  //           ? getValue(UrlParams, `limit`, "")
  //           : limit,
  //         page_no: getValue(UrlParams, `page_no`, "")
  //           ? getValue(UrlParams, `page_no`, "")
  //           : page_no,
  //         search: getValue(UrlParams, `search`, ""),
  //       };
  //       let queryRequest = QueryRequestHelper(payload);
  //       setListLoading(status);
  //       let obj = {
  //         has_advance_filter: getValue(localStorage, `sf:tickets`, "")
  //           ? true
  //           : false,
  //         has_group_by: false,
  //         filter_groups: getValue(localStorage, `sf:tickets`, "")
  //           ? JSON.parse(getValue(localStorage, `sf:tickets`, ""))
  //           : null,
  //         sort_by: getValue(localStorage, `s:tickets`, "")
  //           ? {
  //               ...JSON.parse(getValue(localStorage, `s:tickets`, "")),
  //               custom: false,
  //             }
  //           : {
  //               custom: false,
  //               field: "created_at",
  //               value: "desc",
  //             },
  //       };
  //       let resp = await commonCustomFilter(
  //         "tickets",
  //         queryRequest,
  //         removeNullOrUndefinedProperties(obj)
  //       );
  //       if (resp) {
  //         setTicketList(
  //           getValue(resp, `data.data.tickets`, []).map((item: object) => ({
  //             ...item,
  //             stage: getValue(item, `stage`, ""),
  //           }))
  //         );
  //         setTotalCount(getValue(resp, `data.data.pagination.total`, 0));
  //         setListLoading(false);
  //       } else {
  //         setTicketList([]);
  //         setListLoading(false);
  //       }
  //     } catch (error) {
  //       setTicketList([]);
  //       setListLoading(false);
  //     }
  //   }
  // };

  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [totalCount, setTotalCount] = React.useState(0);
  const [limit, setLimit] = React.useState(10);
  const [page_no, setPage_no] = React.useState(1);

  /* -------------------------------------------------------------------------- */
  /*                               Footer Section                               */
  /* -------------------------------------------------------------------------- */
  // const handleReset = async () => {
  //   setLeftRequest(dirtyLeftRequest);
  //   setFields(dirtyFields);
  // };
  // const handleCancel = () => {
  //   handleReset();
  // };
  // const [submitLoading, setSubmitLoading] = React.useState(false);
  // const handleSubmit = async () => {
  //   try {
  //     let payload: any = {
  //       name: getValue(leftRequest, `name`, ""),
  //       email: getValue(leftRequest, `email`, ""),
  //       pipeline_stage_id: getValue(leftRequest, `stage`, ""),
  //     };
  //     let fieldReq = JSON.parse(JSON.stringify(fields));
  //     let payloadNew = FormRequestHelperWithNullFields(fieldReq);
  //     let requestPayload: any = { ...payload, ...payloadNew };
  //     setSubmitLoading(true);
  //     let resp = await updateTicket(getValue(params, `id`, ""), requestPayload);
  //     if (resp) {
  //       toast.success("Updated successfully");
  //       getData(false);
  //       setSubmitLoading(false);
  //     } else {
  //       setSubmitLoading(false);
  //     }
  //   } catch (error) {
  //     setSubmitLoading(false);
  //   }
  // };
  // const [duplicateAPINames, setDuplicateAPINames] = React.useState<any>([]);

  /* -------------------------------------------------------------------------- */
  /*                                 Module Info                                */
  /* -------------------------------------------------------------------------- */
  // const findModuleType = (id: string) => {
  //   let info =
  //     getValue(allModules, `length`, 0) > 0
  //       ? allModules.find((item: object) => getValue(item, `id`, "") == id)
  //       : [];
  //   return info;
  // };

  // const moduleInfo = React.useMemo(
  //   () => findModuleType(getValue(selectedOption, `module_id`, "")),
  //   [selectedOption, getValue(allModules, `length`, 0) > 0]
  // );

  /* -------------------------------------------------------------------------- */
  /*                     Helper Functions Section                               */
  /* -------------------------------------------------------------------------- */

  // const findSelectedPipeline = (id: string) => {
  //   let list =
  //     getValue(options, `length`, 0) > 0
  //       ? options.filter((item: object) => getValue(item, `id`, "") === id)
  //       : [];
  //   return getValue(list, `length`, 0) > 0 ? getValue(list, `[${0}]`, "") : {};
  // };
  // const getSelectedPipeline = React.useMemo(
  //   () => findSelectedPipeline(getValue(staticFieldRequest, `pipeline_id`, "")),
  //   [getValue(staticFieldRequest, `pipeline_id`, "")]
  // );

  // const handleChangePipeline = (e: any) => {
  //   delete UrlParams.pipeline;
  //   let payload = {
  //     ...UrlParams,
  //   };
  //   let queryRequest = QueryRequestHelper(payload);
  //   navigate(`${window.location.pathname}?${queryRequest}`);
  // };
  // const handleClickPipeline = (option: any) => {
  //   delete UrlParams.filter_pipeline_stage_id;
  //   let payload = {
  //     ...UrlParams,
  //     pipeline: getValue(option, `id`, ""),
  //   };
  //   let queryRequest = QueryRequestHelper(payload);
  //   navigate(`${window.location.pathname}?${queryRequest}`);
  // };

  /* -------------------------------------------------------------------------- */
  /*                     Other Functions Section                                */
  /* -------------------------------------------------------------------------- */

  const subMenuArray: any = [];
  return (
    <MainLayout>
      {/* {isLoading ? (
        <ListLoader />
      ) : ( */}
      {/* <CommonTicketDetailPage
        data={data}
        params={params}
        backUrl={`/${getValue(
          params,
          `orgId`,
          ""
        )}/tickets?${mainQueryRequest}`}
        isChanged={!checkLeftRequestChanges || !checkfieldRequestChanges}
        request={leftRequest}
        setRequest={setLeftRequest}
        fields={fields}
        setFields={setFields}
        popupFields={popupFields}
        setPopupFields={setPopupFields}
        getData={getData}
        ticketList={ticketList}
        selectedTicket={selectedTicket}
        // ------------ Right Section Props ----------- //
        form={form}
        setForm={setForm}
        stage={stage}
        setStage={setStage}
        formLoading={formLoading}
        formSubmitLoading={formSubmitLoading}
        handleCreateForm={handleCreateForm}
        handleAssociate={handleAssociate}
        handleDisAssociate={handleDisAssociate}
        simpleValidator={simpleValidator}
        getForm={getForm}
        module={"tickets"}
        //static dropddowns
        // pipelineList={pipelineList}
        stageList={stageList}
        staticFieldRequest={staticFieldRequest}
        setStaticFieldRequest={setStaticFieldRequest}
        getSpecificPipelineInfo={getSpecificPipelineInfo}
        formStageList={formStageList}
        associationForm={associationForm}
        setAssociationForm={setAssociationForm}
        duplicateAPINames={duplicateAPINames}
        setDuplicateAPINames={setDuplicateAPINames}
        permissions={getValue(props, `permissions`, [])}
        isLoading={isLoading}
        //Module info
        moduleInfo={moduleInfo}
        UrlParams={UrlParams}
        subMenuArray={subMenuArray}
        isPipelineLoading={isPipelineLoading}
        options={options}
        getSelectedPipeline={getSelectedPipeline}
        handleChangePipeline={handleChangePipeline}
        handleClickPipeline={handleClickPipeline}
        getAllPipelines={getAllPipelines}
      /> */}
      {/* )} */}
      {/* {(!checkLeftRequestChanges || !checkfieldRequestChanges) && (
        <motion.div
          initial={{ y: 0, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.2, ease: "easeInOut" }}
          exit={{
            y: 50,
            opacity: 0,
            transition: { duration: 0.5, ease: "easeInOut" },
          }}
        >
          <div className={"footer_container"}>
            <div className={"button_container"}>
              <div className="d-flex align-items-center">
                <ButtonComponent
                  buttonType={"scecondary"}
                  buttonText={"Cancel"}
                  onClickHandler={() => handleCancel()}
                  // squre
                />
                {getValue(props, `permissions`, []).includes("update") && (
                  <ButtonComponent
                    buttonType={"primary"}
                    buttonText={submitLoading ? "Please wait..." : "Update"}
                    onClickHandler={() => handleSubmit()}
                    disabled={submitLoading || duplicateAPINames.length > 0}
                    // squre
                  />
                )}
              </div>
            </div>
            <p className={"text"}>You have unsaved changes</p>
          </div>
        </motion.div>
      )} */}
    </MainLayout>
  );
};

export default TicketDetailsPage;
