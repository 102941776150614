import DeleteSvgComponent from "@assets/svg/delete";
import WarningSvgComponent from "@assets/svg/warning";
import { languageEnum } from "@common/enum";
import {
  handlePhoneNestedRequestArrayChange,
  handleSelectNestedRequestArrayChange,
  handleSelectRequestArrayChange,
  handleTextArrayChange,
  handleTextNestedRequestArrayChange,
  handleTextRequestArrayChange,
  handleValueArrayChange,
} from "@common/handleChange";
import InputRuleForm from "@components/InputRuleForm/form";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import { getValue } from "@utils/lodash";
import { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import "../broadcastTemplate.scss";
import CloseSvgComponent from "@assets/svg/close";
import WhatsappIconViewPage from "../common/whatsapp-icon";
import { formatString } from "@common/text-helpers";
import "react-phone-number-input/style.css";
import "md-editor-rt/lib/style.css";
import DraftJSRefEditor from "@components/custom/DraftJSEditor/draft-ref-editor";
import AddSvgComponent from "@assets/svg/add";
import PhoneInputComponent from "@components/custom/Forms/PhoneInput";
import CarouselNavViews from "./components/carousel-nav-views";
import { config } from "../../../../../env";
import RemoveCircleSvgComponent from "@assets/svg/remove-circle";
import { emojisList } from "@common/emojis";
function CreateWhatsappTemplatePopup(props: any) {
  const {
    setRequest,
    request,
    handleFileChange,
    handleAddTypeButton,
    handleRemoveTypeButton,
    handleSubmit,
    handleOpenVariable,
    errorMessage,
    handleCarouseFileChange,
  } = props;

  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [categoryEnum] = useState([
    {
      value: "MARKETING",
      label: "Marketing",
      icon: "/images/icons/marketing-speaker.svg",
      tooltip:
        "Send promotions or informations about your products, servcie or business.",
    },
    {
      value: "UTILITY",
      label: "Utility",
      icon: "/images/icons/bell.svg",
      tooltip: "Send messages about existing order or account.",
    },
    {
      value: "CAROUSEL",
      label: "Carousel",
      icon: "/images/icons/bell.svg",
      tooltip: "Send carousel messages about existing order or account.",
    },
  ]);
  const [headerEnum] = useState([
    { value: "TEXT", label: "Text" },
    { value: "IMAGE", label: "Image" },
    { value: "VIDEO", label: "Video" },
    { value: "DOCUMENT", label: "Document" },
  ]);
  const [carouselHeaderEnum] = useState([
    { value: "IMAGE", label: "Image" },
    { value: "VIDEO", label: "Video" },
  ]);
  const [buttonTypeEnum] = useState([
    { value: "QUICK_REPLY", label: "Quick Reply" },
    { value: "CALL_TO_ACTION", label: "Call to Action" },
    { value: "URL", label: "URL" },
  ]);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const [selectedType, setButtonType] = useState("QUICK_REPLY");

  const handlChangeType = (
    i: number,
    childIndex: number,
    e: any,
    key: string,
    name: string,
    subName: string,
    setObject: any,
    type: string
  ) => {
    setObject((object: any) => {
      return {
        ...object,
        [key]: object[key].map((x: any, index: number) => {
          if (index !== i) return x;
          return {
            ...x,
            [name]: x[name].map((y: any, p_index: number) => {
              if (childIndex !== p_index) return y;
              return {
                ...y,
                [subName]: type,
              };
            }),
          };
        }),
      };
    });
  };

  const [activeCarouselTab, setActiveCarouselTab] = useState(1);
  const handleCarouselTab = (tab: number) => {
    setActiveCarouselTab(tab);
  };
  return (
    <Modal
      open={getValue(props, `isOpen`, "")}
      // toggle={props.handleModal}
      className="add-template"
      // size="xl"
      onCancel={props.handleModal}
      footer={[
        <Button
          key="cancel"
          onClick={props.handleModal}
          className="cancel__button"
        >
          Cancel
        </Button>,
      ]}
    >
      <div className="px-3 py-3">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h6 className="header_text__18">
              {getValue(props, `editId`, "") ? "Edit" : "Create"} template
              message
            </h6>
          </div>
          <div
            className=""
            onClick={() => {
              props.resetState();
              props.handleModal();
              props.hideValidations();
            }}
          >
            <CloseSvgComponent size={16} />
          </div>
        </div>
      </div>
      <div className="border-bottom"></div>
      <div className="template-wrapper m-3">
        <div className="template-container">
          <div className="template-form-container">
            <div className="template-editor-wrapper">
              <div className="d-flex justify-content-center flex-column align-items-center">
                {errorMessage && (
                  <div className="warning-container">
                    <WarningSvgComponent size="16" />
                    <p>{errorMessage}</p>
                  </div>
                )}
              </div>
              <div className=" border-bottom">
                <div className="">
                  <InputRuleForm
                    label="Template Name (For Own Reference)"
                    inputType="TEXT"
                    name="name"
                    placeholder="Enter Template Name"
                    required
                    className="mb-2"
                    onChange={(e: any) =>
                      setRequest({
                        ...request,
                        name: e.target.value,
                      })
                    }
                    value={getValue(request, `name`, "")}
                    validator={props.validator}
                    disabled={getValue(props, `editId`, "") ? true : false}
                    validLeft
                  />
                </div>
                <div className="mb-2">
                  <SearchToolTip
                    label="label"
                    data={categoryEnum}
                    selectKey={"value"}
                    value={getValue(request, `category`, "")}
                    onChange={(e: any) =>
                      setRequest({
                        ...request,
                        category: getValue(e, `value`, ""),
                      })
                    }
                    name="Category"
                    placeholder="Choose Langauge"
                    disabled={getValue(props, `editId`, "") ? true : false}
                    validator={props.validator}
                    required
                  />
                </div>
                <div className="">
                  <SearchToolTip
                    label="label"
                    data={languageEnum}
                    selectKey={"value"}
                    value={getValue(request, `language`, "")}
                    onChange={(e: any) =>
                      setRequest({
                        ...request,
                        language: getValue(e, `value`, ""),
                      })
                    }
                    name="Language"
                    placeholder="Choose Langauge"
                    disabled={getValue(props, `editId`, "") ? true : false}
                    validator={props.validator}
                    required
                  />
                </div>
              </div>
              {getValue(request, `category`, "") !== "CAROUSEL" && (
                <div className="border-bottom">
                  <h6 className="mt-3">
                    Broadcast title{" "}
                    <span className="optional-text">(Optional)</span>
                  </h6>
                  <p className="my-2 greyed-description">
                    Highlight your brand here, use images or videos to stand
                    out.
                  </p>
                  <div className="mb-3">
                    <SearchToolTip
                      label="label"
                      data={headerEnum}
                      selectKey={"value"}
                      value={getValue(request, `header.type`, "")}
                      onChange={(e: any) =>
                        setRequest({
                          ...request,
                          header: {
                            ...request.header,
                            type: getValue(e, `value`, ""),
                          },
                        })
                      }
                      name="Type"
                      placeholder="Choose type"
                    />
                  </div>{" "}
                  {getValue(request, `header.type`, "") === "TEXT" && (
                    <div className=" input-with-length">
                      <InputRuleForm
                        inputType="TEXT"
                        id="text_input"
                        name="text_input"
                        value={getValue(request, `header.text`, "")}
                        onChange={(e: any) =>
                          setRequest({
                            ...request,
                            header: {
                              ...request.header,
                              text: e.target.value,
                            },
                          })
                        }
                        maxLength={60}
                        placeholder="Enter text"
                        className=""
                      />
                      <span className="input-length">
                        {getValue(request, `header.text.length`, "")}/60
                      </span>
                    </div>
                  )}
                  {getValue(request, `header.type`, "") === "IMAGE" && (
                    <div>
                      <p
                        // htmlFor="file-upload"
                        className="custom-file-upload mb-2 requirement-message"
                      >
                        Choose a{" "}
                        <span className="required-file-format">
                          .jpg or .png
                        </span>{" "}
                        file
                      </p>
                      <input
                        type="file"
                        onChange={handleFileChange}
                        className="custom-upload-filename"
                        accept={".jpg,.jpeg,.png"}
                      />
                    </div>
                  )}
                  {getValue(request, `header.type`, "") === "VIDEO" && (
                    <div>
                      <p
                        // htmlFor="file-upload"
                        className="custom-file-upload mb-2 requirement-message"
                      >
                        Choose a{" "}
                        <span className="required-file-format">.mp4</span> file
                      </p>
                      <input
                        type="file"
                        onChange={handleFileChange}
                        className="custom-upload-filename"
                        accept={".mp4"}
                      />
                    </div>
                  )}
                  {getValue(request, `header.type`, "") === "DOCUMENT" && (
                    <div>
                      <p
                        // htmlFor="file-upload"
                        className="custom-file-upload mb-2 requirement-message"
                      >
                        Choose a{" "}
                        <span className="required-file-format">.pdf</span> file
                      </p>
                      <input
                        type="file"
                        onChange={handleFileChange}
                        className="custom-upload-filename"
                        accept={".pdf"}
                      />
                    </div>
                  )}
                </div>
              )}
              <div className="border-bottom">
                <h6 className="mt-3">Body</h6>
                <p className="my-2 greyed-description">
                  Make your messages personal using variables like {`{{name}}`}{" "}
                  and get more replies.
                </p>
                <div className="w-100 mt-3 input-with-length">
                  <button
                    onClick={() => {
                      handleOpenVariable();
                    }}
                    className="dashboard-button--primary dashboard-button  hover-text touchable-text-white px-4 py-2"
                  >
                    Add variable
                  </button>

                  <div className="pt-3">
                    <DraftJSRefEditor
                      editorState={getValue(request, `body`, "")}
                      handleChangeEditor={props.handleChangeEditor}
                      name="content"
                      placeholder="Enter here"
                      editorStyle={{
                        background: "white",
                        paddingLeft: "20px",
                        paddingTop: "20px",
                        height: "30vh",
                      }}
                      focusColor={"#3fd1a9"}
                      toolbar={{
                        options: ["inline", "emoji"],
                        inline: {
                          options: [
                            "bold",
                            "italic",
                            "underline",
                            "strikethrough",
                          ],
                        },
                        emoji: {
                          emojis: emojisList,
                        },
                      }}
                      focus
                      autoFocus
                    />
                  </div>
                </div>
              </div>
              {getValue(request, `customTags.length`, 0) > 0 && (
                <div className="border-bottom">
                  <h6 className="mt-3">Sample column</h6>
                  <p className="my-2 greyed-description">
                    Your fields will appear here once an attribute is added
                  </p>
                  <div className="input-with-length">
                    {getValue(request, `customTags`, []).map(
                      (item: any, index: number) => {
                        return (
                          <InputRuleForm
                            inputType="TEXT"
                            placeholder={`Enter sample for ${getValue(
                              item,
                              `placeholder`,
                              ""
                            )}`}
                            name="sample"
                            onChange={(e: any) =>
                              handleTextArrayChange(
                                index,
                                e,
                                "customTags",
                                setRequest
                              )
                            }
                            value={getValue(item, `sample`, "")}
                          />
                        );
                      }
                    )}
                  </div>
                </div>
              )}
              {getValue(request, `category`, "") === "CAROUSEL" ? (
                <div className="mt-3">
                  <div className="d-flex justify-content-between">
                    <div></div>
                    <div className="d-flex align-items-center">
                      {getValue(request, `carousel.length`, 0) > 0 && (
                        <div
                          className="d-flex cursor-pointer "
                          onClick={() => {
                            props.handleAddCarousel();
                            setActiveCarouselTab(
                              getValue(request, `carousel.length`, 0)
                            );
                          }}
                        >
                          <AddSvgComponent color={"#1a73e8"} />
                          <h6 className="header_blue_text__16">Add more</h6>
                        </div>
                      )}
                      {getValue(request, `carousel.length`, 0) > 1 && (
                        <div
                          className="d-flex cursor-pointer  ms-4"
                          onClick={() => {
                            props.handleRemoveCarousel(activeCarouselTab - 1);
                            setActiveCarouselTab(
                              activeCarouselTab === 1
                                ? 1
                                : activeCarouselTab - 1
                            );
                          }}
                        >
                          <RemoveCircleSvgComponent color={"red"} />
                          <h6 className="header_blue_text__16 color_danger">
                            Remove
                          </h6>
                        </div>
                      )}
                    </div>
                  </div>
                  <CarouselNavViews
                    length={getValue(request, `carousel.length`, 0)}
                    activeTab={activeCarouselTab}
                    handleTab={handleCarouselTab}
                  />
                  {getValue(request, `carousel`, []).map(
                    (carousel: object, index: number) => {
                      if (activeCarouselTab === index + 1)
                        return (
                          <>
                            <div
                              key={index}
                              className=" gap-2 mt-2 carousal-buttons-wrapper"
                            >
                              <div className="carousal-button">
                                <SearchToolTip
                                  name="Header"
                                  data={carouselHeaderEnum}
                                  label="label"
                                  value={getValue(carousel, `header.type`, "")}
                                  selectKey={"value"}
                                  onChange={(e: any) =>
                                    handleSelectRequestArrayChange(
                                      index,
                                      e,
                                      "carousel",
                                      "header",
                                      "type",
                                      setRequest
                                    )
                                  }
                                  hideClose
                                />
                              </div>
                              <div className="carousal-button">
                                <SearchToolTip
                                  name="Button 1"
                                  data={buttonTypeEnum}
                                  label="label"
                                  value={getValue(
                                    carousel,
                                    `buttons[${0}].action_type`,
                                    ""
                                  )}
                                  selectKey={"value"}
                                  onChange={(e: any) => {
                                    handleSelectNestedRequestArrayChange(
                                      index,
                                      0,
                                      e,
                                      "carousel",
                                      "buttons",
                                      "action_type",
                                      setRequest
                                    );
                                    handlChangeType(
                                      index,
                                      0,
                                      getValue(e, `action_type`, ""),
                                      "carousel",
                                      "buttons",
                                      "type",
                                      setRequest,
                                      getValue(e, `value`, "") === "URL"
                                        ? "url"
                                        : getValue(e, `value`, "") ===
                                          "CALL_TO_ACTION"
                                        ? "call"
                                        : ""
                                    );
                                  }}
                                />
                              </div>
                              <div className="carousal-button">
                                <SearchToolTip
                                  name="Button 2"
                                  data={buttonTypeEnum}
                                  label="label"
                                  value={getValue(
                                    carousel,
                                    `buttons[${1}].action_type`,
                                    ""
                                  )}
                                  selectKey={"value"}
                                  onChange={(e: any) => {
                                    handleSelectNestedRequestArrayChange(
                                      index,
                                      1,
                                      e,
                                      "carousel",
                                      "buttons",
                                      "action_type",
                                      setRequest
                                    );
                                    handlChangeType(
                                      index,
                                      1,
                                      getValue(e, `action_type`, ""),
                                      "carousel",
                                      "buttons",
                                      "type",
                                      setRequest,
                                      getValue(e, `value`, "") === "URL"
                                        ? "url"
                                        : getValue(e, `value`, "") ===
                                          "CALL_TO_ACTION"
                                        ? "call"
                                        : ""
                                    );
                                  }}
                                />
                              </div>
                            </div>
                            <div className="d-flex align-items-start mt-4 gap-2">
                              <div className="w-30 mt-3">
                                <p className="small_text__14">
                                  {formatString(
                                    getValue(carousel, `header.type`, "")
                                  )}
                                </p>
                                <div className="mt-2">
                                  {getValue(carousel, `header.type`, "") ===
                                    "IMAGE" && (
                                    <div>
                                      <p className="custom-file-upload mb-2 requirement-message">
                                        Choose a{" "}
                                        <span className="required-file-format">
                                          .jpg or .png
                                        </span>{" "}
                                        file
                                      </p>
                                      <input
                                        type="file"
                                        onChange={(e) =>
                                          handleCarouseFileChange(e, index)
                                        }
                                        className="custom-upload-filename"
                                        accept={".jpg,.jpeg,.png"}
                                      />
                                    </div>
                                  )}
                                  {getValue(carousel, `header.type`, "") ===
                                    "VIDEO" && (
                                    <div>
                                      <p
                                        // htmlFor="file-upload"
                                        className="custom-file-upload mb-2 requirement-message"
                                      >
                                        Choose a{" "}
                                        <span className="required-file-format">
                                          .mp4
                                        </span>{" "}
                                        file
                                      </p>
                                      <input
                                        type="file"
                                        onChange={(e) =>
                                          handleCarouseFileChange(e, index)
                                        }
                                        className="custom-upload-filename"
                                        accept={".mp4"}
                                      />
                                    </div>
                                  )}
                                </div>
                                <div className="carousel_media_container mt-3">
                                  {(getValue(carousel, `header.type`, "") &&
                                  getValue(carousel, `header.link.key`, "")
                                    ? getValue(carousel, `header.link.key`, "")
                                    : getValue(
                                        carousel,
                                        `header.link`,
                                        ""
                                      )) && (
                                    <>
                                      {getValue(carousel, `header.type`, "") ===
                                      "VIDEO" ? (
                                        <video
                                          controls
                                          style={{
                                            // height: "100px",
                                            width: "100%",
                                          }}
                                        >
                                          <source
                                            src={`https://${config.URL}/${
                                              getValue(
                                                carousel,
                                                `header.link.key`,
                                                ""
                                              )
                                                ? getValue(
                                                    carousel,
                                                    `header.link.key`,
                                                    ""
                                                  )
                                                : getValue(
                                                    carousel,
                                                    `header.link`,
                                                    ""
                                                  )
                                            }`}
                                            type="video/mp4"
                                          />
                                        </video>
                                      ) : (
                                        <img
                                          src={
                                            getValue(
                                              carousel,
                                              `header.link.key`,
                                              ""
                                            )
                                              ? `https://${
                                                  config.URL
                                                }/${getValue(
                                                  carousel,
                                                  `header.link.key`,
                                                  ""
                                                )}`
                                              : `https://${
                                                  config.URL
                                                }/${getValue(
                                                  carousel,
                                                  `header.link`,
                                                  ""
                                                )}`
                                          }
                                          alt="Selected"
                                          style={{
                                            height: "100%",
                                            width: "100%",
                                            objectFit: "contain",
                                          }}
                                        />
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className="w-70">
                                <div className="px-2 mt-2">
                                  {getValue(carousel, `buttons`, []).map(
                                    (item: object, aIndex: number) => {
                                      if (getValue(item, `action_type`, ""))
                                        return (
                                          <div className="mt-2">
                                            <p className="small_text__14 mt-3">
                                              {formatString(
                                                getValue(
                                                  item,
                                                  `action_type`,
                                                  ""
                                                )
                                              )}
                                            </p>
                                            {getValue(
                                              item,
                                              `action_type`,
                                              ""
                                            ) === "QUICK_REPLY" ? (
                                              <InputRuleForm
                                                inputType="TEXT"
                                                onChange={(e: any) =>
                                                  handleTextNestedRequestArrayChange(
                                                    index,
                                                    aIndex,
                                                    e,
                                                    "carousel",
                                                    "buttons",
                                                    "text",
                                                    setRequest
                                                  )
                                                }
                                                name="text"
                                                value={getValue(
                                                  item,
                                                  "text",
                                                  ""
                                                )}
                                                placeholder="Enter here"
                                                maxLength={25}
                                                showCount
                                              />
                                            ) : (
                                              <div className="d-flex align-items-center justify-content-between">
                                                <div className="w-50 ">
                                                  <InputRuleForm
                                                    inputType="TEXT"
                                                    onChange={(e: any) =>
                                                      handleTextNestedRequestArrayChange(
                                                        index,
                                                        aIndex,
                                                        e,
                                                        "carousel",
                                                        "buttons",
                                                        "text",
                                                        setRequest
                                                      )
                                                    }
                                                    name={"text"}
                                                    value={getValue(
                                                      item,
                                                      "text",
                                                      ""
                                                    )}
                                                    placeholder={`Enter Title`}
                                                    label={"Title"}
                                                    maxLength={20}
                                                    // showCount
                                                  />
                                                </div>
                                                <div className="w-50 ps-2">
                                                  {getValue(
                                                    item,
                                                    "type",
                                                    ""
                                                  ) === "call" ? (
                                                    <PhoneInputComponent
                                                      placeholder="Enter phone number (+91...)"
                                                      value={getValue(
                                                        item,
                                                        "phone",
                                                        ""
                                                      )}
                                                      onChange={(e: any) => {
                                                        handlePhoneNestedRequestArrayChange(
                                                          index,
                                                          aIndex,
                                                          e,
                                                          "carousel",
                                                          "buttons",
                                                          "phone",
                                                          setRequest
                                                        );
                                                      }}
                                                      label={"Phone Number"}
                                                    />
                                                  ) : (
                                                    <InputRuleForm
                                                      inputType="TEXT"
                                                      onChange={(e: any) =>
                                                        handleTextNestedRequestArrayChange(
                                                          index,
                                                          aIndex,
                                                          e,
                                                          "carousel",
                                                          "buttons",
                                                          getValue(
                                                            item,
                                                            "type",
                                                            ""
                                                          ) === "url"
                                                            ? "url"
                                                            : "phone",
                                                          setRequest
                                                        )
                                                      }
                                                      name={"url"}
                                                      value={getValue(
                                                        item,
                                                        "url",
                                                        ""
                                                      )}
                                                      placeholder={`Enter ${
                                                        getValue(
                                                          item,
                                                          "type",
                                                          ""
                                                        ) === "url"
                                                          ? "url"
                                                          : "Phone Number"
                                                      }`}
                                                      label={
                                                        getValue(
                                                          item,
                                                          "type",
                                                          ""
                                                        ) === "url"
                                                          ? "URL"
                                                          : "Phone"
                                                      }
                                                      // maxLength={20}
                                                      // showCount
                                                    />
                                                  )}
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        );
                                    }
                                  )}
                                </div>
                                <div className="px-2 mt-2">
                                  <InputRuleForm
                                    inputType="TEXTAREA"
                                    label="Body"
                                    value={getValue(carousel, `body`, "")}
                                    name="body"
                                    onChange={(e: any) =>
                                      handleTextRequestArrayChange(
                                        index,
                                        e,
                                        "carousel",
                                        setRequest
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        );
                    }
                  )}
                </div>
              ) : (
                <>
                  <div className="border-bottom">
                    <h6 className="mt-3">
                      Footer <span className="optional-text">(Optional)</span>
                    </h6>
                    <p className="my-2 greyed-description">
                      Footers are great to add any disclaimers or to add a
                      thoughtful PS
                    </p>
                    <div className="input-with-length">
                      <InputRuleForm
                        inputType="TEXT"
                        id="footer"
                        name="footer"
                        value={getValue(request, `footer`, "")}
                        onChange={(e: any) =>
                          setRequest({
                            ...request,
                            footer: e.target.value,
                          })
                        }
                        maxLength={60}
                        placeholder="Enter Footer Text"
                      />
                      <span className="input-length">
                        {getValue(request, `footer.length`, "")}/60
                      </span>
                    </div>
                  </div>
                  <div className="border-bottom">
                    <h6 className="mt-3">
                      Buttons <span className="optional-text">(Optional)</span>
                    </h6>
                    <p className="my-2 greyed-description">
                      Create upto 2 buttons that let customers respons to your
                      messages or take action.
                    </p>

                    <div className="d-flex  align-items-center mb-2 mt-3 radio_button_container">
                      {buttonTypeEnum.map((item: object) => {
                        return (
                          <div
                            onClick={() =>
                              setButtonType(getValue(item, `value`, ""))
                            }
                            className="d-flex align-items-center mx-2 "
                          >
                            <input
                              type="radio"
                              className="input-radio"
                              checked={
                                selectedType === getValue(item, `value`, "")
                                  ? true
                                  : false
                              }
                            />
                            <div className=" ms-1 small_text__14">
                              <p>{getValue(item, `label`, "")}</p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {getValue(request, "buttons.length", 0) < 3 && (
                      <div className="d-flex align-items-center cursor-pointer">
                        <AddSvgComponent />
                        <p
                          className="add_text ms-2"
                          onClick={() =>
                            handleAddTypeButton(
                              selectedType,
                              selectedType === "URL" ? "url" : "call"
                            )
                          }
                        >
                          Add
                        </p>
                      </div>
                    )}
                    {getValue(request, "buttons.length", 0) > 0 && (
                      <div className="mt-4 template_main_button_container">
                        {getValue(request, "buttons", []).map(
                          (item: any, index: number) => {
                            const buttonType = getValue(
                              item,
                              `action_type`,
                              ""
                            );
                            return (
                              <div className="template_main_button_container_item position-relative">
                                <h6 className="header_text__14 mt-2">
                                  {getValue(item, `type`, "")
                                    ? `${formatString(
                                        getValue(item, `type`, "")
                                      )}`
                                    : formatString(
                                        getValue(item, `action_type`, "")
                                      )}
                                </h6>
                                <div
                                  key={index}
                                  className="d-flex align-items-center"
                                >
                                  {buttonType === "QUICK_REPLY" ? (
                                    <InputRuleForm
                                      inputType="TEXT"
                                      onChange={(e: any) =>
                                        handleTextArrayChange(
                                          index,
                                          e,
                                          "buttons",
                                          setRequest
                                        )
                                      }
                                      name="text"
                                      value={getValue(item, "text", "")}
                                      placeholder="Enter here"
                                      maxLength={20}
                                      showCount
                                    />
                                  ) : (
                                    <div className="flex-wrap d-flex">
                                      <div className="w-45 ms-1">
                                        <InputRuleForm
                                          inputType="TEXT"
                                          onChange={(e: any) =>
                                            handleTextArrayChange(
                                              index,
                                              e,
                                              "buttons",
                                              setRequest
                                            )
                                          }
                                          name={"text"}
                                          value={getValue(item, "text", "")}
                                          placeholder={`Enter Title`}
                                          label={"Title"}
                                          maxLength={20}
                                          // showCount
                                        />
                                      </div>
                                      <div className="w-45 ms-1">
                                        {getValue(item, "type", "") ===
                                        "call" ? (
                                          <PhoneInputComponent
                                            placeholder="Enter phone number (+91...)"
                                            value={getValue(
                                              item,
                                              getValue(item, "type", "") ===
                                                "url"
                                                ? "url"
                                                : "phone",
                                              ""
                                            )}
                                            onChange={(e: any) => {
                                              handleValueArrayChange(
                                                index,
                                                e,
                                                "phone",
                                                "buttons",
                                                setRequest
                                              );
                                            }}
                                            label={"Phone Number"}
                                          />
                                        ) : (
                                          <InputRuleForm
                                            inputType="TEXT"
                                            onChange={(e: any) =>
                                              handleTextArrayChange(
                                                index,
                                                e,
                                                "buttons",
                                                setRequest
                                              )
                                            }
                                            name={
                                              getValue(item, "type", "") ===
                                              "url"
                                                ? "url"
                                                : "phone"
                                            }
                                            value={getValue(
                                              item,
                                              getValue(item, "type", "") ===
                                                "url"
                                                ? "url"
                                                : "phone",
                                              ""
                                            )}
                                            placeholder={`Enter ${
                                              getValue(item, "type", "") ===
                                              "url"
                                                ? "url"
                                                : "Phone Number"
                                            }`}
                                            label={
                                              getValue(item, "type", "") ===
                                              "url"
                                                ? "URL"
                                                : "Phone"
                                            }
                                            // maxLength={20}
                                            // showCount
                                          />
                                        )}
                                      </div>
                                    </div>
                                  )}
                                  <div
                                    onClick={() =>
                                      handleRemoveTypeButton(index)
                                    }
                                    className="mt-3 mx-1 template_delete"
                                  >
                                    <DeleteSvgComponent size={26} />
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    )}
                  </div>
                </>
              )}

              {getValue(request, `status`, "") === "APPROVED" ||
              getValue(request, `status`, "") === "REJECTED" ||
              getValue(request, `status`, "") === "PAUSED" ? (
                <div className="d-flex justify-content-end">
                  <button
                    type="submit"
                    className="mb-5 mt-4 dashboard-button--primary dashboard-button  hover-text touchable-text-white px-4 py-2"
                    onClick={handleSubmit}
                    disabled={props.submitLoading}
                  >
                    {props.submitLoading ? "Please wait..." : "Submit"}
                  </button>
                </div>
              ) : !getValue(props, `editId`, "") ? (
                <div className="d-flex justify-content-end">
                  <button
                    type="submit"
                    className="mb-5 mt-4 dashboard-button--primary dashboard-button  hover-text touchable-text-white px-4 py-2"
                    onClick={handleSubmit}
                    disabled={props.submitLoading}
                  >
                    {props.submitLoading ? "Please wait..." : "Submit"}
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <WhatsappIconViewPage request={request} />
        </div>
      </div>
    </Modal>
  );
}

export default CreateWhatsappTemplatePopup;
