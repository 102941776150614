import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllTickets = (queryRequest: string) =>
  get(`${config.API_URL}/records/tickets?${queryRequest}`);

export const getListOfAllTickets = (queryRequest: string) =>
  get(`${config.API_URL}/records/tickets/list?${queryRequest}`);

export const getSpecificTicket = (id: string) =>
  get(`${config.API_URL}/records/tickets/${id}`);

export const createTicket = (payload: object) =>
  post(`${config.API_URL}/records/tickets`, payload);

export const updateTicket = (id: string, payload: object) =>
  patch(`${config.API_URL}/records/tickets/${id}`, payload);

export const deleteTicket = (id: string) =>
  Delete(`${config.API_URL}/records/tickets/${id}`);

export const TicketsBulkUpdate = (payload: object) =>
  patch(`${config.API_URL}/records/tickets/bulk-update`, payload);

export const TicketsSheetUpdate = (payload: object) =>
  patch(`${config.API_URL}/records/tickets/sheet-view`, payload);
