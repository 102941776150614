import MainLayout from "@layouts/HomeLayout/NewLayout";
import CompanyProfileDetailsLayout from "@layouts/SettingsLayout/CompanyProfile";
import { Button, Select } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import { Plus } from "lucide-react";
import CompanyProfileSubHeader from "../../CompanyProfile/components/CompanyProfileSubHeader";
import BackButton from "@components/custom/BrowserBack";
import { useEffect, useState } from "react";
import InputRuleForm from "@components/InputRuleForm/form";
import { handleTextChange } from "@common/handleChange";
import FormDescription from "@components/custom/FormDescrption/FormDescription";
import SLATargetTable from "./components/SLATargetTable";
import SLATicketCondition from "./components/SLATicketCondition";
import SLADueTime from "./components/SLADueTime";
import { toast } from "sonner";
import {
  createSlaPolicy,
  getSlaPolicyConditionFields,
  getSpecificSlaPolicy,
  updateSlaPolicy,
} from "@services/sla-policy.services";
import { listAllPipelines } from "@services/pipeline.service";
import { useStateContext } from "@context/profileProvider";
import { QueryRequestHelper } from "@common/query-request-helper";

const CreateSLAPolicy = ({ props }: any) => {
  const params = useParams();
  const navigate = useNavigate();
  const { selectedModuleId } = useStateContext();
  const [editId, seteditId] = useState("");
  const [isDefault, setIsDefault] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [request, setRequest] = useState({
    name: "",
    description: "",
    sla_conditions: [
      {
        field_name: "",
        value: [],
      },
    ],
    is_default: false,
  });

  const [slaTarget, setSlaTarget] = useState({
    urgent: {
      first_response_time: "PT0H15M",
      resolution_due_time: "PT0H15M",
      every_response_time: "PT0M",
      business_hours: true,
      escalation_enabled: true,
    },
    high: {
      first_response_time: "PT0H15M",
      resolution_due_time: "PT0H15M",
      every_response_time: "PT0M",
      business_hours: true,
      escalation_enabled: true,
    },
    medium: {
      first_response_time: "PT0H15M",
      resolution_due_time: "PT0H15M",
      every_response_time: "PT0M",
      business_hours: true,
      escalation_enabled: true,
    },
    low: {
      first_response_time: "PT0H15M",
      resolution_due_time: "PT0H15M",
      every_response_time: "PT0M",
      business_hours: true,
      escalation_enabled: true,
    },
  });

  const [slaEscalation, setSlaEscalation] = useState<any>({});

  const [ticketsPipelineId, setTicketsPipelineId] = useState("");
  const [conditionList, setConditionList] = useState([]);

  /* -------------------------------------------------------------------------- */
  /*                            useEffect Section                               */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    getAllPipelinesList();
  }, []);

  useEffect(() => {
    if (params) {
      seteditId(getValue(params, "id", ""));
    }
  }, [params]);

  useEffect(() => {
    if (ticketsPipelineId) {
      getConditionList(true);
    }
  }, [ticketsPipelineId]);

  useEffect(() => {
    if (editId) {
      getSpecificPolicy(true);
    }
  }, [editId]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const combinedData = {
        ...request,
        sla_target: slaTarget,
        escalation: { ...slaEscalation },
      };
      let payload = JSON.parse(JSON.stringify(combinedData));
      const resp = editId
        ? await updateSlaPolicy(editId, payload)
        : await createSlaPolicy(payload);

      if (resp) {
        toast.success(`${editId ? "Updated" : "Created"} Successfully`);

        setRequest({
          name: "",
          description: "",
          sla_conditions: [
            {
              field_name: "",
              value: [],
            },
          ],
          is_default: false,
        });
        setSlaEscalation({});
        navigate(`/${getValue(params, "orgId", "")}/settings/sla-policy`);
        setIsLoading(false);
      } else {
        toast.error(`Failed to ${editId ? "editing" : "creating"} SLA Policy`);
      }
    } catch (error) {
      toast.error(`Error ${editId ? "editing" : "creating"} SLA Policy`);
    } finally {
      setIsLoading(false);
    }
  };

  const getConditionList = async (status: boolean) => {
    try {
      setIsLoading1(status);
      const payload = {
        pipelineId: ticketsPipelineId,
      };
      const queryRequest = QueryRequestHelper(payload);
      const resp = await getSlaPolicyConditionFields(queryRequest);
      if (resp) {
        const conditions = getValue(resp, "data", []).map((item: any) => ({
          ...item,
          value: getValue(item, "fieldName", ""),
          label: getValue(item, "fieldLabel", ""),
        }));
        setConditionList(conditions);
      }
    } catch (error) {
      toast.error("Failed to load conditions.");
    } finally {
      setIsLoading1(false);
    }
  };

  const getSpecificPolicy = async (status: boolean) => {
    try {
      setIsLoading2(status);
      const resp = await getSpecificSlaPolicy(editId);

      if (resp) {
        setRequest({
          name: getValue(resp, "data.name", ""),
          description: getValue(resp, "data.description", ""),
          sla_conditions: getValue(resp, "data.sla_conditions", []),
          is_default: getValue(resp, "data.is_default", false),
        });
        const createPriorityData = (resp: any, priority: string) => ({
          first_response_time: getValue(
            resp,
            `data.${priority}.first_response_time`,
            "PT0H15M"
          ),
          resolution_due_time: getValue(
            resp,
            `data.${priority}.resolution_due_time`,
            "PT0H15M"
          ),
          every_response_time: getValue(
            resp,
            `data.${priority}.every_response_time`,
            "PT0M"
          ),
          business_hours: getValue(
            resp,
            `data.${priority}.business_hours`,
            true
          ),
          escalation_enabled: getValue(
            resp,
            `data.${priority}.escalation_enabled`,
            true
          ),
        });

        setSlaTarget({
          urgent: createPriorityData(resp, "urgent"),
          high: createPriorityData(resp, "high"),
          medium: createPriorityData(resp, "medium"),
          low: createPriorityData(resp, "low"),
        });
        setSlaEscalation({
          reminder_response: getValue(resp, "data.reminder_response", null),
          reminder_resolution: getValue(resp, "data.reminder_resolution", null),
          reminder_next_response: getValue(
            resp,
            "data.reminder_next_response",
            null
          ),
          response: getValue(resp, "data.response", null),
          resolution_level_1: getValue(resp, "data.resolution_level_1", null),
          next_response: getValue(resp, "data.next_response", null),
        });
        setIsDefault(getValue(resp, "data.is_default", false));
      }
    } catch (error) {
      toast.error("Failed to get Policy");
    } finally {
      setIsLoading2(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Pipeline Section                              */
  /* -------------------------------------------------------------------------- */
  const [allPipelines, setAllPipelines] = useState([]);
  const getAllPipelinesList = async () => {
    try {
      let allPipelines = await listAllPipelines(
        await selectedModuleId("tickets"),
        ""
      );
      let list = getValue(allPipelines, `data`, []).map((item: object) => ({
        ...item,
        value: getValue(item, `id`, ""),
        label: getValue(item, `label`, ""),
      }));
      setAllPipelines(list);
      setTicketsPipelineId(getValue(allPipelines, `data.[${0}].id`, ""));
      // getAllContact(
      //   page_no1,
      //   limit1,
      //   selectedAdvancedFiter,
      //   getValue(allPipelines, `data.[${0}].id`, "")
      // );
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                               Other Section                              */
  /* -------------------------------------------------------------------------- */

  return (
    <MainLayout {...props}>
      <CompanyProfileSubHeader
        addChildren={
          <Button
            type="primary"
            size="large"
            icon={!editId && <Plus size={20} />}
            onClick={() => handleSubmit()}
            loading={isLoading}
            disabled={!getValue(request, "name", "")}
          >
            {editId ? "Update" : "Create"} SLA Policy
          </Button>
        }
      />
      <CompanyProfileDetailsLayout classname="my-0 p-4">
        <h6 className="header_text__18 d-flex align-items-center gap-3 mb-4">
          <BackButton className="" />
          {editId ? "Edit" : "New"} SLA Policy
        </h6>
        <div className="w-50">
          <InputRuleForm
            inputType="TEXT"
            name="name"
            value={getValue(request, "name", "")}
            placeholder="Enter Name"
            required
            label="Name (For Own Reference)"
            onChange={(e: any) => handleTextChange(e, request, setRequest)}
          />
        </div>
        <FormDescription
          request={request}
          textClassname="mb-2 mt-3 w-50"
          inputClassname="w-50 my-2"
          input={
            <InputRuleForm
              inputType="TEXTAREA"
              name="description"
              value={getValue(request, `description`, "")}
              placeholder="Enter Description"
              label="Description"
              onChange={(e: any) => handleTextChange(e, request, setRequest)}
            />
          }
        />
        {/* <div className="w-50">
          <label className="form-label">Pipeline</label>
          <Select
            onChange={(value) => {
              setTicketsPipelineId(value);
            }}
            value={ticketsPipelineId}
            placeholder="Choose Condition"
            options={allPipelines}
            className="w-100"
            showSearch
            allowClear
            size="large"
          />
        </div> */}
        {!isDefault && (
          <SLATicketCondition
            request={request}
            setRequest={setRequest}
            editId={editId}
            isLoading1={isLoading1}
            conditionList={conditionList}
          />
        )}
        <SLATargetTable request={slaTarget} setRequest={setSlaTarget} />
        <SLADueTime
          mainRequest={request}
          priorityRequest={slaTarget}
          request={slaEscalation}
          setRequest={setSlaEscalation}
          editId={editId}
        />
      </CompanyProfileDetailsLayout>
    </MainLayout>
  );
};

export default CreateSLAPolicy;
