import { getValue } from "@utils/lodash";
import { Switch } from "antd";
import React from "react";

const SheetBooleanEditor = React.memo(
  ({ col, row, fieldCol, handleDisplayItem, handleInputChange }: any) => {
    return (
      <Switch
        size="small"
        checked={handleDisplayItem(col, row)}
        onChange={(checked) =>
          handleInputChange(getValue(row, "id", ""), col, checked)
        }
      />
    );
  }
);

export default SheetBooleanEditor;
