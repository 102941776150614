import { getValue } from "@utils/lodash";

const SettingsPageDetails = ({ data }: any) => {
  return (
    <div>
      {data.map((item: any, index: any) => (
        <div key={index} className="mb-3">
          <h6 className="header_text__16 mb-1">
            {getValue(item, "heading", "")}
          </h6>
          <h6 className="header_text__14 mb-1">
            {getValue(item, "subHeading", "")}
          </h6>
          {getValue(item, "description", "") && (
            <p className="small_text__14">
              {getValue(item, "description", "")}
            </p>
          )}
          {getValue(item, "points", []) &&
            getValue(item, "points", []).map((point: any, i: any) => (
              <p
                key={i}
                className="small_text__14 pb-2"
                dangerouslySetInnerHTML={{ __html: point }}
              />
            ))}
          {getValue(item, "numberedPoints", []) && (
            <ol className="mt-2">
              {getValue(item, "numberedPoints", []).map(
                (point: any, j: any) => (
                  <li
                    key={j}
                    style={{ listStyle: "auto" }}
                    className="small_text__14 pb-2"
                    dangerouslySetInnerHTML={{ __html: point }}
                  />
                )
              )}
            </ol>
          )}
        </div>
      ))}
    </div>
  );
};

export default SettingsPageDetails;
